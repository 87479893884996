<template>
    <div :class="'user_logo size_' + size_class" :style="'background-image:url(' + (isNull(user_data.logo_parsed) ? iconUrl : user_data.logo_parsed) + ');'">
        <router-link :to="'/company/' + company_id" v-if="clickable !== false"></router-link>
    </div>
    
</template>


<script>


export default {  
    
  props: ["user_data", "size", "clickable","company_id"],
    data(){
        return {
            open:true,
            
        };
    },

  computed: {
      size_class()
      {
          var ret = '';
          switch (this.size)
          {
              case 'sml': ret = 'sml'; break;
              case 'mid': ret = 'mid'; break;
              case 'big': ret = 'big'; break;
              case 'midi': ret = 'midi'; break;
          }

          return ret;
      },
      iconUrl () {
            return require('../assets/no-user.jpg')
        }
  },
  methods: {
  
  }
}
</script>

<style lang="scss" scoped>
.user_logo { display: block;; background: center center no-repeat;  background-size: cover;   box-shadow: 0 14px 22px 0 rgba(0, 0, 0, 0.15);

    &.size_sml { width:90px; height: 50px; }
    &.size_mid { width: 140px; height: 90px;  }
    &.size_midi { width: 200px; height: 105px;  border: 10px solid #fff;background-size: contain;}
    &.size_big { width: 265px; height: 105px; margin-left: 20px; border: 10px solid #fff;}
    a { display: block; width: 100%; height: 100%;}
}
</style>