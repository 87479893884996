import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    users_count: null,
    companies_count: null,
    experties: {},
    users_follow: null,
    users_home: null,
    chat: null
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})