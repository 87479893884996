<template>
  <div id="new_post">
        <div class="user_pic"></div>
       
               <Mentionable :keys="['@']" :items="items" @open="loadIssues()" @search="loadIssues($event)"  @apply="blat">
                   <md-field>
                          <md-textarea ref="textarea" placeholder="על מה אתה חושב?"  @keyup="text_check()" v-model="content"  @tagAdded="blat" md-autogrow></md-textarea>
                    </md-field>
                     <template #no-result>  {{ loading ? 'טוען...' : 'אין תוצאות' }}</template>
                </Mentionable>

        <div v-if="url_data.loaded" id="url_data_wrapper">
            <div class="pic" :style="'background-image:url('+ url_data.pic +')'"></div>
            <div class="url_content">
                <h3 v-text="url_data.title"></h3>
                <h4 v-text="url_data.description"></h4>
            </div>
        </div>
        <div class="post_attach_line">
            <ul>
                <li v-for="file in attachments" :key="file.name"><i :class="get_file_class(file.ext)"></i>
                    <span class="name" v-if="!file.pic_parsed" v-text="file.name"></span>
                   <div class="pic" :style="'background-image:url('+ file.pic_parsed +')'"></div>

                </li>
            </ul>
        </div>
                    <md-progress-bar md-mode="indeterminate" v-if="pic_loaded"></md-progress-bar>

        <div id="post_foot">
            <div class="attach_options" @click="chooseFiles()">
                
<input id="fileUpload" type="file" ref="file" hidden @change="uploadFile" >
<a ><i class="fas fa-paperclip"></i></a>


            </div>
          
            <md-checkbox v-model="is_anonymous">אני מעוניין לפרסם פוסט אנונימי</md-checkbox>

            <a class="post_btn" @click="save()">שתף</a>
        </div>
  </div>
</template>

<script>
import Mentionable from '@/components/mention/Mentionable.vue'

export default {
    components: {
    Mentionable,
  },
    props: ["feed_key"],
  data(){
    return {
        mentions: [],
        attachments: [],
        content: '',
        video: '',
        post_url: '',
        active_vid: false,
        pic_loaded: false,
        is_anonymous: false,
        items: [],
        loading: false,
        url_data: {
            loaded: false,
            title: '',
            description: '',
            image: ''
        }
    }
  },
    methods:{
        blat(val){
            this.mentions.push({ id: val.id, name:val.label });
        },
          loadIssues (searchText = null) {
            this.loading = true
            this.items = this.fetchIssues(searchText)
            this.loading = false
            },
            fetchIssues(searchText){
                let array = []
                this.net_api({ action: 'check_tag_user', data:{text:searchText} }, function(data){
                    
                    for(let i in  data.data)
                    {
                        array.push({label:  data.data[i].display_name,id: data.data[i].id,pic:data.data[i].pic_parsed,value:  data.data[i].display_name.replace(" ", "_")}) 
                        // array.push({label:  data.data[i].display_name,value:  data.data[i].display_name.replace(" ", "_")}) 
                    }
                });
                return  (array)
            
            },
        chooseFiles() {
            document.getElementById("fileUpload").click()
        },
        uploadFile(){
            
            var self = this;
            let formData = new FormData();
            formData.append('file', this.$refs.file.files[0]);

     var headers = { 'Content-Type': 'application/json; charset=utf-8', };
    
     if(!this.isNull(localStorage.getItem('user_token'))) 
     { 
       headers.Authorization = localStorage.getItem('user_token');
     }
    self.pic_loaded = true; 
    this.$http.post(window.api_url + 'upload', formData, {/*emulateJSON:true,*/ headers: headers})
    .then(response => {
    if (response.body == 'error')
        {
            localStorage.setItem('user_token', '');
            document.location.reload();
        }

        self.attachments.push(response.body.data);
        //alert(response.body.data)
         self.pic_loaded = false; 
        //console.log(response.body);
    }, response => { 
        
        console.error(response.body);
    });


        },
        text_check(){
            var textarea = this.$refs.textarea;
//            textarea.style.height = 0;
//            textarea.style.height = textarea.scrollHeight + 'px';

            //var urlRegex = /(https?:\/\/[^\s]+)/g;
            var urls = textarea.value.match(/(https?:[^\s]+)/);

//console.log(urls)

            if (!this.isNull(urls))
            {
                this.post_url = urls[0];
            }
            else
            {
                this.post_url = '';
            }
        //     var word=/@(\w+)/ig; //@abc Matc
        //     var name= this.$refs.textarea.value.match(word); //Content Matching @abc
        //    if(!this.isNull(name))
        //    {
        //         alert(name)
        //    }
        },

        save(){
        
            var self = this;
            var data = {};
            if (this.url_data.loaded) data.url = this.post_url;

            if (this.content == '') 
            {
                 self.alert('נא להזין תוכן');
                return;
            }
            self.alert('הפוסט שלך נשלח לאישור על ידי מנהל הקהילה, תכף הפוסט יעלה', 'אישור')

            data.content = this.content;
            data.video = this.video;
            data.is_anonymous = this.is_anonymous;
            data.feed_key = this.feed_key;
            data.users_tag = this.feed_key;
            data.attachments = this.attachments.map((val) => val.id);
            data.mentions = this.mentions;

            this.net_api({ action: 'post/add', data:data }, function(/*res*/){

                //self.$emit('add_post', { id: res.data.id });

            });

            self.content = '';
            self.attachments = [];
            self.mentions = [];
        }

    },
    watch: {
        post_url()
        {
            var self = this;

            if (this.post_url == '')
            {
                this.url_data.loaded = false;
            }
            else
            {
            
                this.net_api({ action: 'post/parse_url_meta', data: { url: this.post_url } }, function(data){

                    self.url_data = {
                        loaded: true,
                        title: data.data.title,
                        description: data.data.description,
                        image: data.data.image,
                    };
                    
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#new_post {
    & { background: #fff; box-shadow: 0px 10px 20px rgba(0,0,0,0.2); border-radius: 5px; margin-bottom: 30px; }

    .md-field { padding: 0px; margin: 0px;
    &::after,&::before { display: none;}
    }
.post_attach_line{padding: 10px;}
.post_attach_line .pic{height: 100px;width: 100px;background-size: contain;background-repeat: no-repeat;}
    textarea { width:100% !important; overflow: hidden; padding: 10px; border:none; outline: none; padding: 20px; font-size: 20px; line-height: 1.8; }
    #url_data_wrapper { border-radius: 5px; border: 1px solid #666; overflow: hidden; background: #eee;
        .pic { width: 100%; padding-bottom: 40%; background: center center no-repeat; background-size: cover;}
        .url_content { padding: 15px;}
        h3 { font-size: 20px; font-weight: bold; }
        h4 { font-size: 15px; font-weight: normal; }
    }

    #post_foot { 
        &{ background: #f5f5f5; height: 48px;}
        .post_btn { float: left; height: 48px; padding: 0 25px; background: #1e2732; color: #fff; line-height: 48px; font-weight: bold; font-size: 18px; border-radius: 5px;}
    }
    
    .attach_options { background: #30abb7; height: 100%; float: right; color:#fff; padding: 0 15px; cursor: pointer;
        a { color: #fff; line-height: 48px;}
    }

}

@media only screen and (max-width: 600px) {
    #new_post{
        &{padding: 10px 10px;}
            .md-checkbox .md-checkbox-label{font-size: 14px !important;}
        .md-checkbox .md-checkbox-label{font-size: 11px;}
        #post_foot{height: 35px;}
        .attach_options a{line-height: 40px !important;}
        .attach_options{padding: 0 10px;}
        .md-checkbox{margin: 9px 10px 0 0;}
        #post_foot .post_btn{height: 35px;line-height: 36px;}
    }
    
}
</style>