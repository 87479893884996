<template>
  <div id="login_page" class="signup">
    <md-dialog-alert
      :md-active.sync="show_error"
      md-content="הזנת פרטים שגויים, אנא נסה שוב או שחזר סיסמה."
      md-confirm-text="OK" />

        <div id="form_wrapper" md-theme="default-dark">
            <div class="top">
              <div class="close" @click="$emit('closePopup')">X</div>
          <h3> צור חשבון חדש </h3> 
          <h4>לקהילת מרכז הנדל"ן ללא עלות</h4>
          <span >יש לך חשבון?<a @click="$emit('goToLogin')">התחבר עכשיו</a></span>
          </div>
          <div class="content">
          <span class="title">הירשם  באמצעות</span>
            <a class="btn" :href="($store.inapp ? '/#loginFB' : 'https://admin.community.nadlancenter.co.il/cfacebook/login')"> <span class="fb_btn" id="fb_btn" @logged="fb_logged"><img src="https://img.icons8.com/color/48/000000/facebook-new.png" class="google_img"/></span></a>
           <a class="btn" href="https://admin.community.nadlancenter.co.il/cgoogle/login"> <span class="fb_btn" id="fb_btn" @logged="fb_logged"><img src="https://img.icons8.com/fluent/48/000000/google-logo.png" class="google_img"/></span></a>
           <span class="title">או באמצעות</span>
           <div v-if="is_loading">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-if="!is_loading">
            <div v-if="!show_step_b">
                <form @submit.prevent="signup()">
                    <md-field>
                        <label>אימייל</label>
                        <md-input v-model="username" required></md-input>
                    </md-field>
                      <md-field>
                        <label>שם מלא</label>
                        <md-input v-model="display_name" required></md-input>
                    </md-field>
                      <md-field>
                        <label>תפקיד</label>
                        <md-input v-model="job" required></md-input>
                    </md-field>
                    <md-field>
                        <label>סיסמא</label>
                        <md-input type="password" v-model="password" required></md-input>
                    </md-field>
                    <input type="checkbox" id="checkbox" v-model="remember_me"   required />
                    <div class="text_checkbox">אני מאשר את תנאי השימוש ואת מדיניות ופרטיות</div>


                <div style="margin-bottom:15px;"><input type="submit" value="הרשם" class="btn"></div>
                



                </form>
            </div>
            
            </div>
            </div>

        </div>
 


</div>
      

</template>


<script>


export default {
  data(){
      return {

      FB: {},
      model: {},
      scope: {},

        token: '',
        show_step_b: false,
        is_loading: false,
        username: '',
        job: '',
        display_name: '',
        password: '',
        remember_me: true,
        show_error: false
      }
  },
  created(){
      
    
  },
  methods:{
      handleSdkInit({ FB, scope }) {
        this.FB = FB
        this.scope = scope
console.log(FB)
console.log(scope)
console.log(this.model)

      },
    signup()
    {
      var self = this;
      self.is_loading = true;


    const formData = { 
    password: this.password,
    username: this.username,
    job: this.job,
    display_name: this.display_name 
    };

    this.net_api({ 'action': 'user/add', data: formData}, function(data){
       
        if (self.isNull(data) || self.isNull(data.data))
        {
            self.is_loading = false;
            self.show_error = true;
            //alert('Wrong details. Please try again.');
        }
        else
        {
            localStorage.setItem('user_token', data.data.token);
            document.location = '/profile/'+data.data.id + '?edit=true';

            //alert(data.jwt)
            //captainnemo
        }
        
    });

    },

    login_fb(){

      document.location = 'https://admin.community.nadlancenter.co.il/cfacebook/login';
      /*
      var nw=window.open('https://admin.community.nadlancenter.co.il/cfacebook/login', 'loginFB', 'left=100,top=100,width=1000,height=700,toolbar=no,menubar=no,status=no,scrollbars=no,resizable=no');

      nw.focus();

      window.loginEl = this;
      
      nw.onbeforeunload = function(event){
        var message = 'Important: Please click on \'Save\' button to leave this page.';
        if (typeof event == 'undefined') {
            event = nw.event;
        }
        if (event) {
            event.returnValue = message;
        }
        return message;
      }
      */
    },
    
    login_g(){

      var nw=window.open('https://admin.community.nadlancenter.co.il/cgoogle/login', 'loginFB', 'left=100,top=100,width=1000,height=700,toolbar=no,menubar=no,status=no,scrollbars=no,resizable=no');

      nw.focus();

      window.loginEl = this;
      
      nw.onbeforeunload = function(event){
        var message = 'Important: Please click on \'Save\' button to leave this page.';
        if (typeof event == 'undefined') {
            event = nw.event;
        }
        if (event) {
            event.returnValue = message;
        }
        return message;
      }
    },
    fb_logged(token){
      localStorage.setItem('user_token', token);
      document.location = document.location.href;
    }

  }
}
</script>

<style scoped>
#checkbox{display: inline-block;margin-top: 20px;}
.text_checkbox{display: inline-block;}
.close{    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #fff !important;
    font-family: cursive;}
    
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

/*.fb_btn {
    text-align: center;
    width: 100%;
    height: 48px;
    line-height: 48px;

    display: inline-block;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
    padding-left: 41px;
    margin-bottom: 15px;
}*/

</style>