<template>
<div id="madad_widget">
     <!-- <div class="tabs_wrapper" >
         <h3>דירוגי {{profile_data.name}} על פי מדד ההתחדשות העירונית</h3>
                      <div class="year_wrapper"> 
                          <div class="name" v-for="(year, index) in years" :key="index" v-text="index" @click="active_year = index" :class="(active_year == index ? 'active' : '')">
                              
                          </div>
                      </div>

                      <div id="cities" v-if="active_year">
                          <ul>
                          <li v-for="city in years[active_year]" :key="city"  class="col-md-4"><a target="_blank" :href="city.link" v-text="city.name"></a></li>
                          <div class="clr"></div>
                          </ul>
                      </div>
                  
                </div> -->
            <div class="activities" v-if="profile_data.pinoi_and_tama == 0"> 
                <h3 v-if="profile_data.pinui != 1">פעילות בתמ"א 38</h3>
                <h3 v-if="profile_data.pinui == 1">פעילות בפינוי בינוי</h3>
               <div class="col-md-3">
                    <div class="icon" ></div>
                    <span v-if="profile_data.pinui != 1"> סה"כ פרוייקטים: <label v-text="profile_data.rank"></label></span>
                    <span v-if="profile_data.pinui == 1">מיקום דירוג: <label v-text="profile_data.rank"></label></span>
                    </div>
                    <div class="col-md-3">
                        <div class="icon"></div>
                        <span v-if="profile_data.pinui != 1">  פרויקטים בתהליך: <label v-text="profile_data.ttl_ptojects"></label></span>
                        <span v-if="profile_data.pinui == 1"> סה"כ מתחמים: <label v-text="profile_data.ttl_ptojects"></label></span>
                    </div>
                    <div class="col-md-3">
                        <div class="icon"></div>
                        <span v-if="profile_data.pinui != 1"> פרויקטים בביצוע: <label  v-text="profile_data.aprt_before"></label></span>
                        <span v-if="profile_data.pinui == 1"> סה"כ דירות לפני: <label  v-text="profile_data.aprt_before"></label></span>
                    </div>
                    <div class="col-md-3">
                        <div class="icon"></div>
                        <span v-if="profile_data.pinui != 1"> פרויקטים מאוכלסים: <label  v-text="profile_data.aprt_after"></label></span>
                        <span v-if="profile_data.pinui == 1"> סה"כ דירות אחרי: <label  v-text="profile_data.aprt_after"></label></span>
                    </div>
                    <div class="clr"></div>
            </div>
                     <div class="activities" v-if="profile_data.pinoi_and_tama > 0" > 
                <h3>פעילות בתמ"א 38</h3>
                  <div class="col-md-3">
                    <div class="icon" ></div>
                    <span> סה"כ פרוייקטים: <label v-text="profile_data.rank"></label></span>
                    </div>
                    <div class="col-md-3">
                        <div class="icon"></div>
                        <span>  פרויקטים בתהליך: <label v-text="profile_data.ttl_ptojects"></label></span>
                    </div>
                      <div class="col-md-3">
                        <div class="icon"></div>
                        <span> פרויקטים בביצוע: <label  v-text="profile_data.aprt_before"></label></span>
                    </div>
                      <div class="col-md-3">
                        <div class="icon"></div>
                        <span> פרויקטים מאוכלסים: <label  v-text="profile_data.aprt_after"></label></span>
                    </div>
                <h3 >פעילות בפינוי בינוי</h3>
               <div class="col-md-3">
                    <div class="icon" ></div>
                    <span>מיקום דירוג: <label v-text="profile_data.rank_pinoi"></label></span>
                    </div>
                    <div class="col-md-3">
                        <div class="icon"></div>
                        <span> סה"כ מתחמים: <label v-text="profile_data.ttl_ptojects_pinoi"></label></span>
                    </div>
                    <div class="col-md-3">
                        <div class="icon"></div>
                        <span> סה"כ דירות לפני: <label  v-text="profile_data.aprt_before_pinoi"></label></span>
                    </div>
                    <div class="col-md-3">
                        <div class="icon"></div>
                        <span> סה"כ דירות אחרי: <label  v-text="profile_data.aprt_after_pinoi"></label></span>
                    </div>
                    <div class="clr"></div>
            </div>
              <!-- <div class="madad_links">
                                        <ul>
                                               
                                                    <div class="col-md-4" v-for="index in 6" :key="index">
                                                    <li><a>ייזום נדל"ן חברות בורסאיות</a></li>
                                                </div>
                                              
                                        </ul>
                            </div>-->
</div>
</template>
<script>
export default {
   props: ["profile_data"],
   data(){
        return {
            years:[],
            active_year:false
        }
        },
   mounted(){
       var self = this;
      
      // categories 
      this.net_api({ action: 'madad_info', data: { company_id: this.profile_data.id } }, function(data){ 
        
        self.years = data.data.years
      
        //console.log(self.years);


      } );
   }
}
</script>
<style lang="scss" scoped>
.tabs_wrapper{
    .md-tabs .md-button{font-size: 20px;}
    #tab-2018{font-size: 20px !important;}
    .madad_links{  box-shadow: 0 16px 38px -15px rgba(135, 147, 166, 0.48);  margin-bottom: 35px;}
    .years a{color: #000 !important;}
    &{margin-top: 40px;box-shadow: 0 16px 38px -15px rgba(135, 147, 166, 0.48);margin-left: -26px;margin-right: -26px;}
    .col-sm-4{margin: 15px 0;}
    ul{width: 80%;margin: 0px auto;;}
    ul li {color: #000;display: inline-block;position: relative;margin-bottom: 20px;}
    ul li ::before{height: 5px;width: 5px;border-radius: 100%;background: #000000;position: absolute;right: -10px;content: "";top: 7px;}
    ul li a{    color: #000;margin-bottom: 52px;text-decoration: underline}
    h3{    font-size: 26px; font-weight: 700; margin: 10px;padding-right: 30px;margin-bottom: 20px;color: #212731;}
}
.year_wrapper{
    &{display: flex;border-bottom: 2px solid #ccc;padding: 20px;justify-content:center ;}
    .name{font-size: 22px;color: #ccc;flex: 1;text-align: center;position: relative;cursor: pointer;max-width: 90px;}
    .name.active{font-size: 23px;color: #000;font-weight: 600;}
    .name.active::after{content: "";position: absolute;height: 2px;background-color: #30abb7;width: 40px;right: 0;left: 0;margin: 0 auto;bottom: -21px;}
}
#cities{
    &{padding: 20px;text-align: center;}
    ul{
        
        li{position: relative;;text-align: right;cursor: pointer;list-style-type: none!important;}
        // li::before{position: absolute;content: "◂";right: -10px;}
        
    }
}
.activities{
    &{padding-top: 30px;display: block}
    .icon{height: 40px;width: 40px;background-repeat: no-repeat;background-position: center;background-size: cover;margin: 10px auto;display: block;}
    .col-md-3{text-align: center;margin-bottom: 30px;padding: 0 ;}
    .col-md-3:nth-child(even) .icon{background-image: url(../../assets/house.svg)}
    .col-md-3:nth-child(odd) .icon{background-image: url(../../assets/deal.svg)}
    h3{    color: #30abb7;font-weight: 700;font-size: 26px;margin-bottom: 30px;}
    .row{padding-bottom: 25px;border-bottom: 1px solid #e7e7e7;margin-bottom: 20px;}
    .row .col-sm-3:last-child{border-left: none;}
    .col-sm-3{border-left: 1px solid #e7e7e7;text-align: center;}
    span{font-size: 20px;color: #212731;}
    label{color: #30abb7;  font-size: 25px;  font-weight: 600;    display: block;margin: 10px auto 0;line-height: 5px;}
}
@media only screen and (max-width: 600px) {
    .tabs_wrapper{
        h3{font-size: 24px;text-align: center;padding: 0;}
    }
    .activities.active .col-md-3{width: 50%;display: inline-block;}
    .activities .col-md-3{width: 50%;display: inline-block;}
}


</style>