<template>
  <div id="app" :class="'rtl ' + (show_side_menu ? 'menu_open' : '')">
    
     <div id="side_menu" v-if="is_mobile() && !nadlancenterview">



		
		<!-- <ul class="sharing_list">
			<li id="fc_white"><a href="http://www.facebook.com/Hidabroot" target="_blank"></a></li>
			<li id="whatsapp_white"><a href="http://lp.vp4.me/b11r" target="_blank"></a></li>
			<li id="instagram_white"><a href="https://www.instagram.com/hidabroottv" target="_blank"></a></li>
			<li id="android_white"><a href="https://play.google.com/store/apps/details?id=net.linnovate.hidabroot" target="_blank"></a></li>
			<li id="itunes_white"><a href="https://itunes.apple.com/il/app/%D7%94%D7%99%D7%93%D7%91%D7%A8%D7%95%D7%AA/id1006747141?mt=8" target="_blank"></a></li>
		</ul> -->
	
					<a class="logo" href="/"></a>

		<ul>
      <li><div id="user_drop" ><UserIcon size="sml" :user_data="user_data" /><span class="user_name_menu">שלום  {{ user_data.display_name }} !</span></div></li>
                
                  <li><router-link :to="'/profile/' + user_data.id" @click.prevent="show_side_menu = false">הפרופיל שלי</router-link></li>
			<li v-for="menu_item in menu" :key="menu_item.id">
        <a :href="menu_item.link" @click="show_side_menu=false" :style="(menu_item.color != '' ? 'color:#' + menu_item.color : '')">
        <span class="material-icons">{{menu_item.icon}}</span>{{ menu_item.name }}</a>
        </li>
      <li>  <div class="primuem_frame">
          <Courses :user_data="user_data" />
          <!-- <div class="line"></div> -->
           <!-- <HashTags :user_data="user_data" :premium="1"/> -->
        </div></li>
        <li><a @click="logout()">התנתק</a></li>

		</ul>

    </div>

    <main id="page-wrap" v-if="loaded && generalLoaded" :class="(show_login ? 'no_header' : '') + ' ' + (is_notification_open ? 'show_notifications' : '')">

      <div id="page_content">
        <div id="header_mobile"  v-if="is_mobile()">
			<a class="logo" href="/"></a>
          <div><a id="notification_btn_head" @click="show_notifications = !show_notifications"><div class="bell"><span v-if="notifications > 0">{{notifications}}</span></div><span v-if="notifications > 0">{{notifications}}</span></a>
              <!-- <transition name="slide" mode="out-in"> -->
                <div id="user_popup_notifications" v-if="show_notifications" v-on-clickaway="awayNot">
                  <Notifications @close_notifications="show_notifications = false;change_notifications()" v-on-clickaway="away" :false="false"/>
                </div> 
              <!-- </transition> -->
              </div>
              <router-link to="/inbox" id="env_icon"><i class="far fa-envelope"></i><span class="chat_num" v-if="chat > 0" :key="chat">{{chat}}</span></router-link>
        <div id="menu_hamburger" v-on:click="show_side_menu=!show_side_menu">
        <div id="nav-icon1"><span></span><span></span><span></span></div>
      </div>
      </div>

        <LoginPage v-if="!nadlancenterview && goto_login && !close_popup" @goToSignup="goto_signup=true; goto_login=false;"  @closePopup="close_popup=true;show_login = true;goto_login = false"/>
        
        <SingupPage v-if="!nadlancenterview && goto_signup && !close_popup" @goToLogin="goto_signup=false; goto_login=true;" @closePopup="close_popup=true;show_login = true;goto_signup = false"/>
        
        <template >
            <Header :user_data="user_data" :menu="menu" v-if="!is_mobile() && !nadlancenterview" :chat="chat" :notifications="notifications"/>
            
            <AppRight v-if="!nadlancenterview" :user_data="user_data" :menu="menu" v-show="$route.matched[0].props.default.hideSideBars !== true" />
            <AppLeft v-if="!nadlancenterview" :user_data="user_data"  v-show="$route.matched[0].props.default.hideSideBars !== true" @force_edit="force_edit = true"/>

            <router-view :key="$route.path" @show_loader="loaded=false" @loaded="loaded=true" :user_data="user_data" @menu="menu" @chat_change="chat = 1"></router-view>
            <div id="chats_wrapper" v-if="!nadlancenterview && $route.name != 'Inbox'">
              <div v-for="chatId in open_chats" :key="chatId"><Chat :user_id="chatId" @close="close_chat(chatId)" ref="chatbox"  /></div>
            </div>
        </template>
        <div class="disabeld_scroll" v-if="!nadlancenterview && show_login && !goto_login" @click="shake=!shake" v-show="$route.matched[0].props.default.hideLogin !== true">
        <div id="bottom_banner"  :class="(shake == true ? 'shake' : '')" @click="shake=false">
          <h3>קשרים וידע בענף הנדל"ן הם שני הפרמטרים המבטיחים להצלחה- הרשם לקהילת מרכז הנדל"ן והתחבר לאלפי מומחים מהענף</h3>
          <div class="btn " @click="goto_signup = true; close_popup = false">הרשם לקהילה</div>
          <div class="btn" @click="goto_login = true ; close_popup = false">התחבר</div>
        </div>
        </div>
      </div>
 
    </main>
    
    <div v-if="!loaded" id="app_loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
<link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons">

  </div>
</template>

<script>

import Courses from './components/sides/subjects.vue'
import Notifications from './views/Notifications.vue'

import Header from './components/header.vue'
import LoginPage from './views/login.vue'
import SingupPage from './views/signup.vue'
import AppRight from './components/app_right_side.vue'
import AppLeft from './components/app_left_side.vue'
import Chat from './components/chat.vue'
import UserIcon from './components/user_icon.vue'
import { mixin as clickaway } from 'vue-clickaway';


export default {
  name: 'app',
    mixins: [ clickaway ],
  components: {
    Header,LoginPage,AppRight,AppLeft, Chat,SingupPage,UserIcon,Courses,Notifications,
  },
  metaInfo() { return {
    // Children can override the title.
    title: 'קהילת מרכז הנדל"ן',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'I have things here on my site.'},
        {title:  " - מרכז הנדל\"ן"},
				{description:  " במרכז הנדל\"ן - הבית של ענף הנדל\"ן. מידע על ההנהלה, תחומי התמחות, פעילות מקצועית בתחום הנדל\"ן וריכוז כתבות ועדכונים שוטפים"},
				{'og:image': "https://www.nadlancenter.co.il/themes/MGZ/images/share.jpg"}
    ]
  }},
  data(){
    return {
      nadlancenterview: false,
      open_chats: [],
      user_data: {},
      chat: {},
      notifications:'',
      close_popup:false,
      user_parse: {},
      show_notifications: false,
      show_side_menu: false,
      show_login: false,
      force_edit:false,
      goto_signup:false,
      goto_login:false,
      shake:false,
      loaded: false,
      generalLoaded: false,
      show_user_popup:false,

      is_notification_open: false,
      bar_opened:true,
      menu: [],
    }
    
  },
  methods: {
       awayNot: function() {
     this.show_notifications =  false
    },
     logout(){
      localStorage.removeItem('user_token');
      document.location = '/';
    }
,
    close_chat(id){
      var index = this.open_chats.indexOf(id);
      if (index !== -1) {
        this.open_chats.splice(index, 1);
      }
    },
    
    parse_user_channel(msg)
    {
      var data = JSON.parse(msg.message);
      // console.log(data);

      switch(data.mtype)
      {
        case 'chat_msg': { 
          let exists = false;
          for (let i in this.$refs.chatbox)
          {
            let chatbox = this.$refs.chatbox[i];
            if (chatbox.user_id == data.data.uid_from)
            {
              chatbox.pushMessage({ author: data.data.uid_from, type: 'text', data: { text: data.data.msg } });
              exists = true;
              break;
            }
          }

          if (!exists)
          {
            if (this.open_chats.indexOf(data.data.uid_from) === -1) this.open_chats.push(data.data.uid_from);
          }

          // TODO: Update When your on Inbox as well - Avichay

          break;
        }
        case 'add_notifay':{
          this.notifications++
          break;
        }
      }
    },

    
    init_general(data)
    {
      var self = this;
        
        self.user_data = data.user;
        self.$store.users_follow = data.users_follow;
        self.$store.user = data.user;
        self.notifications = data.notifications;
        self.chat = data.chats;
        self.$store.users_count = data.users.num;
        self.$store.companies_count = data.companies.num;
        self.$store.experties = data.experties;

        self.menu = data.menu;


        self.$store.users_home = data.users_home;


        if (!(self.user_data.id > 0))
        {
          self.show_login = true;
        }
        else{ 
            //  console.log(self.$store.user)
            //  if(!self.isNull(self.$store.user.job))
            //  {
            //    self.alert(1)
            //  }
        }

        self.$pnSubscribe({ channels: ['user_cnl_' + self.user_data.id] });
        self.$pnGetMessage('user_cnl_' + self.user_data.id, self.parse_user_channel);

        self.generalLoaded = true;
      }
  },
  mounted() {
document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});

  this.$store.inapp = (!this.isNull(this.$route.query.inapp));

  if(!this.isNull(this.$route.query.token))
  {
    localStorage.setItem('user_token',this.$route.query.token);
  }
    window.appVue = this;

    if (!this.userToken)
    {
      this.show_login = true;
    }
      this.loaded = true;
      // console.log(this.$store.user.id)
      // categories 
     


      var self = this;
      if (this.menu.length == 0) this.net_api({ action: 'general' }, function(data) { self.init_general(data.data); } );
    
  }
}
</script>
<style lang="scss" scoped>
.chat_num{display: block;
    width: 15px;
    height: 15px;
    line-height: 16px;
    background: red;
    color: #fff;
    font-size: 13px;
    text-align: center;
    position: absolute;
    right: -8px;
    top: -2px;
    border-radius: 100%;}
#env_icon{position: absolute;left: 60px;font-size: 24px;}
#user_popup_notifications{position: absolute;width: 100%;left: 0px;}
#header_mobile{
  #notifications::after{display: none;}
}
#notification_btn_head{position: absolute;left: 10px;top: 13px;}
#notification_btn_head{
   span {display: block; width: 15px;height: 15px; line-height: 16px;; background: red; color: #fff; font-size: 13px; text-align: center; position: absolute; right: -8px; top: -2px; border-radius: 100%; }
}
.bell{mask-image: url(assets/bell.svg);height: 25px; width: 24px;mask-size: contain;background: #000;}
@media only screen and (max-width: 600px) {  

} 
  

#user_drop{
  .user_icon{margin: 0 auto;}
}
.disabeld_scroll{
  &{position: fixed;height: 100%;width: 100%;top: 0;z-index: 1;background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0.5) 100%);}
  #bottom_banner{background: url(assets/bottom_banner.jpg);position: absolute;bottom: 0;width: 100%;height: 180px;background-size: contain;text-align: center;padding-top: 20px;z-index: 2;}
  #bottom_banner.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
  h3{font-size: 30px;
    width: 50%;
    margin: 0 auto;
    line-height: 35px;
    font-weight: 600;
    margin-bottom: 20px;}
  .btn:nth-child(2){    margin: 0 10px;background-color: #000 !important;color: #fff;border-radius: 0;}
  .btn:nth-child(3){  margin: 0 10px;  background-color: #fff !important;  color: #000 !important;  border-radius: 0;  width: 130px;}
  .btn{width: 200px !important;padding: 0 40px !important;border-radius: 3px !important;}
}

</style>