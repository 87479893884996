<template>
    <div id="course-item-page" v-if="loaded">
            <!-- <Header :menu="menu" v-if="!is_mobile()"/> -->
<div class="inner_course">
        <template >
            
        <div class="video_page" :key="ref">
            <Video  :key="video_url" v-if="video_url != ''" ref="player" :video_url="video_url" @video_loaded="init_video()" :height="650"/>
            <div class="options" v-if="!is_mobile()">
                 <md-dialog-alert class="course_inner_content"
                            
                        :md-active.sync="confirm_send"
                        md-content="שאלתך נשלחה בהצלחה!"
                        md-confirm-text="מעולה" />
                  <md-dialog :md-active.sync="showDialog" class="course_inner_content">
                      <h4>שאל את המרצה</h4>
                        <md-dialog-title>מה תרצה לשאול?</md-dialog-title>
                            <div class="inputs">
                            <div class="col-md-12" >
                                <md-field>
                                <label>גוף ההודעה</label>
                                <md-input v-model="question"></md-input>
                            </md-field>
                            </div>
                        </div>
                        <md-dialog-actions>
                            <md-button class="md-primary" @click="showDialog = false; prof_ask();confirm_send = true">שלח</md-button>
                        </md-dialog-actions>
                        </md-dialog>
    <md-button class="md-primary md-raised pop_btn" @click="showDialog = true" v-if="course.ask == 1">שאל את<br> המרצה</md-button>
                <a  @click="scroll('about')"><div class="btn">אודות הקורס</div></a>
                <a @click="scroll('questions')"  v-if="questions.length > 0"><div class="btn">שאלות ותשובות</div></a>
               <a  @click="scroll('files')"  v-if="fiels.length > 0"> <div class="btn">קבצים להורדה</div></a>
                <a  @click="scroll('messages')"  v-if="alerts.length > 0"><div class="btn">התראות מערכת</div></a>
            </div>
            <p v-text="info.short_text" id="about"></p>
            <div class="questions section" id="questions"  v-if="questions.length > 0">
                <h4>שאלות ותשובות</h4>
                <div class="question" v-for="item in questions" :key="item.open" @click="item.open = !item.open"> 
                    <p v-text="item.question" class="question_content"></p>
                    <i class='fas fa-chevron-down' v-if="!item.open"></i>
                    <i class='fas fa-chevron-up' v-if="item.open"></i>
                    <div class="answer" v-if="item.open">
                     <p v-text="item.answer" class="answer"></p>
                    </div>
                </div>
            </div>
            <div class="files section" id="files"  v-if="fiels.length > 0">
                <h4>קבצים להורדה</h4>
               <a :href="file.link" v-for="file in fiels" :key="file.id"> <div class="btn file">{{ file.name }}<i class="fas fa-download"></i></div></a>
            </div>
             <div class="messages section" id="messages"  v-if="alerts.length > 0">
                <h4>התראות מערכת</h4>
                 <div class="message" v-for="alert in alerts" :key="alert.id">
                     <div class="pic" :style="'background-image:url('+ alert.pic_parsed+')'"></div>
            <div class="description">
                     <h3 v-text="alert.name"></h3>
                    <p v-text="alert.content"></p>
            </div>
                </div>
               
            </div>
        </div>
         <div class="menu">
            <div class="progress"  v-if="!is_mobile() && course.presentage > 5">
                    <!-- <div class="arrow"  @click="show_more_progress()" ><i :class="'fas '+(!is_show_more_progress ? 'fa-chevron-down' : 'fa-chevron-up')"></i></div> -->
              <vue-ellipse-progress  :progress="course.presentage" :legend-value="course.presentage" :size="110"  :thickness="5"  emptyThickness="5%"  color="#fff" fontColor="#fff" emptyColor="#00BEC7">
                        <span slot="legend-caption">ההתקדמות שלך</span>

              </vue-ellipse-progress>

                <h4 v-text="course.name"></h4>
                 
                  <!-- <div class="profs" v-for="writer in writers" :key="writer.id">
                    <span class="writer">מרצה: {{writer.name}}</span>
                   
                    </div> -->
            </div>

            <h5><b>קורס {{cats.length}}</b> {{time_convert(course.total_length)}}</h5>
            <div class="items_wrapper">
            <div class="item" v-for="item in cats" :key="item.id" :id="'item'+item.id">
                <div class="course_inner_content">
                    <div @click="item.show_more = !item.show_more" :key="item.show_more">
                    <i class='fas fa-chevron-down' v-if="!item.show_more" ></i>
                    <i class='fas fa-chevron-up' v-if="item.show_more"></i>
                    <div class="flex_items">
                        <div class="left">
                             <div class="content" v-text="item.name"></div>
                                                     <div class="series_pic" :style="'background-image:url('+item.pic_parsed+')'"></div>

                        </div>
                         <div class="right">
                        <div class="amount">
                            <span>{{item.num_episodes_whatched}}/{{item.items.length}}</span>
                            <label> {{time_convert(item.length_total)}}</label>
                        </div>
                    </div>
                </div>
                </div>
                 <transition name="slide" mode="out-in">
                    <div class="items" v-if="item.show_more">
                        <div @click="get_lesson(lesson.id)" v-for="lesson in item.items" :key="lesson.id">
                        <div class="lesson" >
                            <div class="col-md-5">
                               <div class="wrap" v-if="lesson.watched == 1"> <i class="fas fa-check"></i></div>
                                <div class="pic" :style="'background-image:url('+ lesson.pic_parsed+')'"></div>
                            </div>
                            <div class="col-md-7">
                                <p v-text="lesson.title" class="dets"></p>
                                <span v-text="lesson.length_video + ' דקות'"></span>
                            </div>
                            <div class="clr"></div>
                        </div>
                        </div>
                    </div>
                </transition>
                </div>
            </div>
        </div>
        </div>
        </template>
        </div>
    </div>
</template>

<script>
import Video from './video.vue';

// import Header from './course_header.vue';


export default { 
   props: ["user_data"],
    components: {  Video },
 metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'cs',
      // all titles will be injected into this template
      titleTemplate: 'Real Estate Development Master Class',
       meta: [
                { name: 'description', content:  'Real Estate Development Master Class '},
                { property: 'og:title', content: "Real Estate Development Master Class "},
                { property: 'og:site_name', content: 'urecenter'},
                {property: 'og:type', content: 'website'},  
                 {property: 'og:image', content: 'https://urecenter.com/img/logo.6874f727.png' },  
                {name: 'robots', content: 'index,follow'} 
            ]
    },
    data(){
        return {
          showDialog:false,
            id: this.$route.params.id,
            profile_data: {},
            experties: [],
      
            confirm_send : false,
            show : false,
            is_show_more_progress: false,
            loaded: false,
            lead_name:"",
            series_id:"",
            question:"",
            video_url:"",
            current_id:"",
            alerts: [],
            fiels: [],
            ref: 0,
            last_time_updated:0,
            questions: [],
            cats: [],
            users: [],
            writers: [],
            course_data_pid: [],
            courses: [],
            info: [],
            course: [],
                  menu:[
                {id:1,name:"Main Course", link:'/course_index/158'},
                {id:2,name:"About", link:"/course_index/158/#about_course"},
                {id:3,name:"Speakers", link:"//course_index/158/#lecturers_area"},
            ],
        }
    },
  methods: {
        scroll(value) {  
      var elmnt = document.getElementById(value);
        elmnt.scrollIntoView({behavior:'smooth'});
        },
           scroll_to_lesson(id){
                var container = this.$el.querySelector(".items_wrapper");
                var elm = this.$el.querySelector("#item" + id);
                container.scrollTop = elm.offsetTop;

    },
      init_video()
      {
            var player = this.$refs.player.player;
            var self = this;

             player.on('play', function(){ setTimeout(function(){ self.save_user_watch_time(); }, 500); });
            player.on('pause', function(){ self.save_user_watch_time(); });
            player.on('ended', function(){ self.save_user_watch_time(); });
            
                setInterval(function(){
                   self.save_user_watch_time();
                }, 15000);
      },
      show_more_progress(){
          this.is_show_more_progress = !this.is_show_more_progress
        
      },
      save_user_watch_time(){

            var player = this.$refs.player.player;
           
            var settings = { 
                item_id: (this.current_id > 0 ?this.current_id : this.id), 
                duration: player.currentTime
    };
    
    if (settings.duration > this.last_time_updated) 
    {
        this.net_api({ action: 'update_time_a', data:{ duration:settings.duration,id:this.current_id }});
         this.last_time_updated = settings.duration;
    }
      },
      prof_ask()
        {

           this.net_api({ action: 'prof_ask', data: { question: this.question} }, function(){ 
            

        } ); 

        } , 
        get_lesson(id){
                        var self = this;
              this.net_api({ action: 'lessone/get', data: { id: id} }, function(data){ 
                self.info = data.data;
                self.video_url =  data.data.vid_embed;
                // self.writers = data.data.writers;
                self.course_data_pid = data.data.course_data;
                self.series_id = data.data.series.id;
                self.current_id = data.data.id;
        } );
          this.net_api({ action: 'course_data', data: {id: id } }, function(data){ 
          
          for (let i in data.data.questions)
            {
                data.data.questions[i].open = false;
            }
        self.questions = data.data.questions;
        self.alerts = data.data.alerts;
        self.fiels = data.data.fiels;
        self.ref++
 } );
            },
      toggle_item(){
          this.course_inner_content = !this.course_inner_content
          alert(this.course_inner_content)
      },
      scrollBehavior: function (to) {
         
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },

  },
  mounted(){
  window.scrollTo(0,0);
   this.get_lesson(this.id);

      var self = this;
      
      // categories 

      this.net_api({ action: 'course/get_course_by_lesson',data: {id: this.id } }, function(data){ 
                for (let i in data.data.series)
                {
                    if(data.data.series[i].id != self.series_id)
                    {
                        data.data.series[i].show_more = false;
                    }
                    else{
                        data.data.series[i].show_more = true;
                    }
                 
                    
                }
            self.writers = data.data.writers;

            self.course = data.data;
              self.cats = data.data.series;
              self.loaded = true;
 } );
    
    
  }
    } 
   
</script>
<style lang="scss" scoped>
.inner_course{padding-top: 0px;display: flex;}
.video_page{flex-basis: 75%;padding: 0 10px;order: 2;}
.menu{flex-basis: 25%;padding: 0 10px;min-width: 450px;order: 1;}
.ep-container .ep-legend--value__counter span:last-child:after{content: "%";}
.ep-container span{font-size: 20px;font-weight: 500;font-family: Arial, Helvetica, sans-serif;}
.ep-container{    position: absolute;right: 10px;top: 25px;}
#course-item-page{padding-top: 20px;}
.item{background: #fff;padding: 20px 10px;box-shadow: 0px 10px 20px rgb(0 0 0 / 20%);margin-bottom: 10px;cursor: pointer;position: relative;min-height: 140px;}
.item h3{font-size: 16px;font-weight: 600;}
.item .fa-chevron-down{position: absolute;right: 10px; top: 20px;}
.item .fa-chevron-up{position: absolute; right: 10px; top: 20px;}
.flex_items{
    &{padding-left: 15px;display: flex;padding-right: 20px;}
    .right{flex-basis: 60%;height: 100%;}
    .left{flex-basis: 40%;height: 100%;}
    }

.item .series_pic{height: 100px;     width: 100%;; background-size: cover; background-position: center;border-radius: 5px;}
      .writer{color: #fff;font-size: 22px;margin: 0 5px;}
.item{
    .content{width: 69%;font-weight: 600;font-size: 17px;;}
    .items{
        .wrap{
            &{    position: absolute;top: 30px; right: 3px; background: #30abb7; color: #fff;border-radius: 100%;padding: 3px;}
             .fa-check{color: #fff;font-size: 20px;    padding-top: 4px;}
        }
        .col-md-7{padding-top: 0px;}
        .lesson{padding: 20px 0;border-top: 2px solid #f3f4f8;width: 100%;margin: 0 auto;}
        span{display: inline-block;font-size: 15px;}
        &{margin-top: 45px;}
        .dets{color: #000;    font-size: 16px;font-weight: 700;margin: 10px 0 0;}
        .pic{height: 80px;width: 90%;background-position: center;background-size: cover;display: inline-block;vertical-align: middle;margin: 0 20px;}
        p{margin-top: 0;}
    }
}
h5{margin-bottom: 10px;}
p{margin-top: 10px;}
.col-md-9 #about{font-size: 22px;margin: 10px 0;padding-top: 20px;}
.progress{ padding: 15px;object-fit: contain;background-image: radial-gradient(circle at 50% 100%, #5ed5dc, #30abb7 79%, #30abb7 79%);margin-bottom: 30px;min-height: 160px;position: relative;}
.hide_progress{height: 30px;}
.menu h5{font-size: 17px;font-weight: 500;}
.menu h5 b{    font-size: 20px;padding: 0 10px;}
.progress h4{width: 75%;color: #fff;font-size: 28px;line-height: 28px;}
.hide_progress{
    .ep-container{display: none !important;}
    .profs{display: none !important;}
    h4{width: 100%;color: #fff;font-size: 20px;margin-top: -10px;}
}
.items_wrapper{height: calc(100vh - 100px);overflow: auto;padding: 10px;}
.btn{padding: 8.9px 20.5px 12.1px 17.5px;object-fit: contain;border-radius: 23px;background-color: #30abb7 !important;line-height: 17px;margin: 0 20px;color: #fff;font-weight: 600;}
.options .pop_btn{position: absolute;top: -70px;left: 200px;height: 75px;width: 60px;border-radius: 100%;font-size: 20px;background: #30abb7 !important;}
.options{
    &{position: relative}
    }
    .course_inner_content{
        .md-dialog-actions{margin-top: -10px;}
        .md-field.md-theme-default:before{background: #fff;}
        .md-field.md-theme-default.md-focused .md-input{color: #fff;}
        .md-button.md-theme-default.md-primary{color: #30abb7;background: #fff;font-size: 25px}
        h4{font-size: 50px;color: #fff;margin-top: 10px;}
        .md-dialog-title{color: #fff;}
        .md-dialog-container{background: #30abb7 !important; width: 440px;height: 340px;;}
        .md-field .md-input{height: 45px;}
        .md-field.md-theme-default label{color: #fff;left: auto;right: 10px;}
        .md-field.md-theme-default:after{background: #fff;}
    }

.questions{
    .question_content{font-size: 16px;}
    .answer{font-weight: 500;font-size: 20px;margin-top: 20px;}
    &{padding: 30px 0;cursor: pointer;}
    h4{font-size: 30px;margin-bottom: 20px;}
    .question{    padding: 10px;margin-bottom: 10px;width: 40%;border: 1px solid rgb(0 0 0 / 20%);padding-right: 20px;position: relative;}
    .fa-chevron-down,.fa-chevron-up{line-height: 10px;margin-right: 30px;position: absolute;top: 20px;right: 20px;}
    p{width: 85%;}
}
.messages{
    .message{margin-bottom: 50px;}
    .description{display: inline-block;    width: fit-content;text-align: left; padding-left: 10px;}
    .pic{height: 60px;width: 60px;display: inline-block;margin-left: 10px;vertical-align: top;background-size: cover;border-radius: 100%;  box-shadow: 0 14px 22px 0 rgba(0, 0, 0, 0.15);}
    &{padding: 30px 0;margin-bottom: 30px;}
    h4{font-size: 30px;margin-bottom: 40px;}
    .question{    padding: 10px;margin-bottom: 10px;width: 40%;border: 1px solid rgb(0 0 0 / 20%);padding-right: 20px;}
    .fa-chevron-down{float: left;line-height: 10px;}
    p{width: 50%;line-height: 26px;}
}
.files{
    .btn{  border: solid 1px #313131;background: transparent !important;color: #000 !important;padding: 5px 40px;display:inline-block;margin: 0 20px;}
    .fa-download{margin-right: 10px;}
    &{padding: 30px 0;}
    h4{font-size: 30px;margin-bottom: 20px;}
    .question{    padding: 10px;margin-bottom: 10px;width: 40%;border: 1px solid rgb(0 0 0 / 20%);padding-right: 20px;}
    .fa-chevron-down{float: left;line-height: 10px;}
    p{display: inline-block;}
}
.section{position: relative;padding: 100px 0;}
.section::after{content: "";position: absolute;width: 60%;height: 1px;background: #979797;top: 50px;right: 0;}
@media only screen and (max-width: 600px) 
{
    .menu{min-width: 100%;order: 2;}
    .item .items .pic{height: 150px;background-size: cover;margin: 0 auto;padding: 0;width: 100%;}
    .item .items .lesson{background: #f7f7f7;padding: 0;}
    .inner_course{padding-top: 0;flex-wrap: wrap;}
    .video_page{flex-basis: 100%;padding-top: 210px;order: 1}
    .items_wrapper{height: 100%;    padding: 0px;}
    .questions .question{width: 100%;}
    .section{padding: 30px 0;}
    .section::after{display: none;}
    .messages p{width: 100%;}
    .options{
        &{text-align: center;}
        .pop_btn { height: 36px;line-height: 15px;max-width: 100%;margin: 0 3px;padding: 0 8px !important;margin-bottom: 10px; position: initial;font-size: 15px; border-radius: 50px; width: 50%;}
        .btn{ height: 36px;line-height: 36px;max-width: 100%;margin: 0 3px;padding: 0 8px !important;margin-bottom: 10px;  width: 45%;  }
    }

}

.ep-legend--container span:nth-child(2){    font-size: 15px; width: 70px; display: block; color: #fff; text-align: center;font-weight: 600;font-family: 'almoni-dl';}
    .ep-legend--value span{    font-size: 26px;font-weight: 600;font-family: 'almoni-dl';}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
 .slide-leave-active,
.slide-enter-active {
  transition: 0.6s;
}
.slide-enter {
  transform: translate(0%, 0px);
}
.slide-leave-to {
  transform: translate(0%, 0px);
}
.arrow{position: absolute;top: 5px;left: 10px;}
.amount{
    &{text-align: right;padding-top: 10px;}
    span{font-size: 15px;font-weight: 600;display: inline-block;margin-left: 5px;}
    label{color:#30abb7;font-size: 16px;font-weight: 600;}
}
</style> 