<template>
<div>
    <div class="title_wrapper">
       <h3> קולגות מענף הנדל"ן</h3>
       <a href="/profile_index">לכל הקולגות<i class="fas fa-arrow-left"></i></a>
        </div>
        <div class="colleage_wrapper">                 
                      <div class="col-md-4" v-for="colleage in colleagues" :key="colleage.id">
                         <div class="colleage" :key="colleage.is_followed">
                         <router-link :to="'/profile/' + colleage.id">
                           <UserIcon size="big" :user_data="colleage" :clickable="false"/>
                           <h4 class="name" v-text="colleage.display_name"></h4>
                          <p class="job" v-text="colleage.job"></p>
                            <div class="logo" :style="'background-image:url('+ colleage.logo_parsed +')'" ></div>                         
                           </router-link>
                          <div class="follow" @click="follow(colleage.id) ; colleage.is_followed = true" v-if="!colleage.is_followed" ><i class="far fa-eye"></i>הוסף כקולגה</div>
                         <div class="follow"  @click="unfollow(colleage.id); colleage.is_followed = false" v-if="colleage.is_followed" ><i class="fas fa-eye-slash"></i>הורד </div>
                         </div>
                    </div>
                 <div class="clr"></div>
        </div>
</div>
</template>

<script>

import UserIcon from '@/components/user_icon.vue';

export default {
          props: ["colleagues" , "user_data"],

    components: { UserIcon },
    data(){
        return {
            id: this.$route.params.id,
            loaded: false,
        
            users: [],
        }
    },
    methods:{
         follow(to_id)
        {
            this.net_api({ action: 'follow/add', data:{ item_type: 'post', to_id: to_id} }, function(){
            });
        },
     unfollow(to_id)
        {
            this.net_api({ action: 'follow/remove', data:{ item_type: 'post', from_id: this.$store.user.id , to_id: to_id} }, function(){
            });
        },
    },
 mounted(){

       


         
  }
}
</script>
<style lang="scss" scoped>
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.colleage_wrapper{
    &{margin-right: -15px;margin-left: -15px;}
    .colleage{
        .logo{margin: 0 auto;box-shadow: none;   height: 60px;width: 130px;background-size: contain; background-repeat: no-repeat;}
        .user_icon{    box-shadow: none;margin: 0 auto;height: 120px;width: 120px;}
    &{    background: #fff;margin: 20px 0;    padding: 15px 0 0;text-align: center;}
    .user_icon.size_mid{margin: 0 auto;;}
    .name{font-size: 23px;margin-top: 6px;color: #30abb7;font-weight: 600;min-height: 20px;max-height: 20px;overflow: hidden;}
    .job{font-size: 20px;color: #212731;margin-bottom: 10px;min-height: 20px;max-height: 20px;overflow: hidden;}
    .follow{width: 100%; height: 45px; background: #d2d7df; line-height: 45px; font-size: 20px; margin-top: 20px;cursor: pointer;}
    }
}
  @media only screen and (max-width: 600px) {
   .col-md-4{width: 50%;padding-left: 3px;padding-right: 3px;display: inline-block;}
   .colleage{margin: 6px 0 !important;}
  }
</style>