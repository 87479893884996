<template>
<div>
    <div class="title_wrapper">
       <h3>נקודות חוזקה של הפרויקט</h3>
        </div>
        <div class="box">
            <div class="col-md-4">
                <i class="fas fa-walking"></i>
                <span v-text="profile_data.strength_a"></span>
            </div>
            <div class="col-md-4">
                <i class="fas fa-school"></i>
                <span v-text="profile_data.strength_b"></span>
            </div>
            <div class="col-md-4">
                <i class="fas fa-road"></i>
                <span v-text="profile_data.strength_c"></span>
            </div>
            <div class="clr"></div>
        </div>
       <div class="reviews_wrapper">
           <h3>חוות דעת המומחים</h3>
             <div class="col-md-4"  v-for="review in profile_data.reviews" :key="review.id">
                        <div class="review " >
                            <div class="pic" :style="'background-image:url(' + review.logo_parsed+ ')'"></div>
                            <h3 class="name" v-text="review.name"> </h3>
                            <h4 class="title" v-text="review.title"></h4>
                            <p class="content" v-text="review.content"></p>
                        </div>
                   </div>
                   <div class="clr"></div>
        </div>
</div>
</template>

<script>


export default {
      props: ["profile_data"],

    components: {  },
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
        }
    },
  methods: {
  }, 
}
</script>
<style lang="scss" scoped>
.box{padding: 20px;margin-top: 15px;}
.box{
    .fa-walking , .fa-school , .fa-road{font-size: 30px;margin-left: 10px;vertical-align: middle;}
    span{color: #30abb7;font-size: 20px;font-weight: 600;}
    .col-md-4{padding-left: 5px;padding-right: 5px;text-align: center;}
}
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.reviews_wrapper{
    h3{font-size: 25px;margin-bottom: 30px;}
    &{margin-right: -15px;margin-left: -15px;}
    .col-md-4:nth-child(3)::before{content: "";position: absolute;right: 0;top: 10px;height: 150px;width: 1px;background: #ccc;}
    .col-md-4:nth-child(3)::after{content: "";position: absolute;left: 0;top: 10px;height: 150px;width: 1px;background: #ccc;}
    .review{
        &{text-align: center;margin-bottom: 20px;overflow: hidden;position: relative;clear: both;}
        h3{margin-bottom: 0;}
        .pic{     margin: 0 auto; height: 110px; width: 110px;  background-size: cover; background-position: center; border-radius: 100%; background-repeat: no-repeat;}
   
    .name{font-size: 23px;margin-top: 12px;color: #30abb7;font-weight: 600;}
    .title{font-size: 18px;color: #212731;line-height: 30px;font-weight: 400;}
    .content{font-size: 20px;width: 90%;margin: 0 auto;color: #212731;margin-top: 1px;line-height: 25px;}
    }
}
@media only screen and (max-width: 600px) {
    .box{text-align: center;}
    .col-md-4{width: 70%;display: inline-block;text-align: center;margin: 0 ;font-size: 20px;margin-bottom: 10px;}
    .reviews_wrapper .col-md-4{width: 100% !important;}
    .review{max-height: auto !important;min-height: auto !important;}
}
</style>