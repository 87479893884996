<template>
  
    <div class="dropdown_menu" :key="showMenu">
        <a class="btn_menu" @click="showMenu=!showMenu">
            <slot name="btn"></slot>
        </a>
        <div class="menu" v-if="showMenu">
            <slot name="menu"></slot>
        </div>
    </div>
</template>

<script>
export default {
  data(){
      return {
          showMenu:false
      }
  },
}
</script>

<style lang="scss" scoped>

.dropdown_menu {
  & { position: relative; }
  .menu {  position: absolute; left: -15px; top: 30px; background:#fff; padding: 10px; border-radius: 5px; box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    a { display: block; line-height: 35px; padding: 0 15px; }
  }
}
</style>