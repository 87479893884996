<template>
<div>
           <md-dialog :md-active.sync="edit_profile"  class="profile_edit_pop">
              <md-dialog-title>עריכת פרופיל</md-dialog-title>
                  <div class="inputs">
                    <div class="col-md-4">
                      <h3>פרופיל</h3>
                        <div class="image" >
                                <UserIcon size="big" :user_data="(pic_uploaded ? pic_uploaded : profile_data)" :clickable="false"/>
                                 <div class="attach_options">
                    <input id="fileUpload" type="file" ref="file" hidden @change="uploadFile">
                                            <md-progress-bar md-mode="indeterminate" v-if="pic_loaded"></md-progress-bar>

                    <a @click="chooseFiles()" class="change_pic">שנה תמונת פרופיל</a>
                    </div>
                  </div>
                  <div class="name" v-text="edit_profile_data.display_name"></div>
                  <div class="job" v-text="edit_profile_data.job"></div>
                  <div class="about">
                    <h3>אודות:</h3>
                  </div>
                  <div class="about" v-html="edit_profile_data.about"></div>
                    </div>
                    <div class="col-md-8">
                    <h3>מידע בסיסי</h3>

                   <md-field>
                      <label> שם מלא:</label>
                      <md-input v-model="edit_profile_data.display_name" placeholder="שם מלא:" :class="(!edit_profile_data.display_name ? 'empty' : '')" required></md-input >
                  </md-field>
                   <md-field>
                      <label> תפקיד:</label>
                      <md-input v-model="edit_profile_data.job" placeholder=" תפקיד:" :class="(!edit_profile_data.job ? 'empty' : '')" required></md-input >
                  </md-field>
                   <md-field>
                      <label> פלאפון:</label>
                      <md-input v-model="edit_profile_data.phone"  placeholder=" פלאפון:" :class="(!edit_profile_data.phone ? 'empty' : '')"></md-input >
                  </md-field>
                      <md-checkbox v-model="edit_profile_data.display_phone" value="1">הצג מספר פלאפון בפרופיל</md-checkbox>
                   <md-field>
                      <label> כתובת:</label>
                      <md-input v-model="edit_profile_data.address" placeholder=" כתובת אימייל:" :class="(!edit_profile_data.address ? 'empty' : '')"></md-input >
                  </md-field>
                   <md-field>
                      <label> אודות:</label>
                      <md-textarea v-model="edit_profile_data.about" placeholder=" אודות:" :class="(!edit_profile_data.about ? 'empty' : '')"></md-textarea >
                  </md-field>
               
                                      <div style="padding-top: 10px;"><label style="font-weight:bold;"> באיזה חברה את/ה עובד/ת?</label></div>

                   <autocomplete :input="inputCompanies" url="ac_companies" :class="(!edit_profile_data.company ? 'empty' : '')"/>
                    <div style="padding-top: 10px;"><label style="font-weight:bold;">תחומי התמחות</label></div>

                    <div style="text-align:right;">
                    <!-- <md-checkbox v-for="expertItem in $store.experties" :key="expertItem.id" v-model="edit_profile_data.experties" :value="expertItem.id">{{expertItem.name}}</md-checkbox> -->
                    <md-field>
        <label for="movies">בחר התמחות מקצועית</label>
              <md-select v-model="edit_profile_data.experties_parse" @md-selected="open_other()" name="movies" id="movies" multiple :class="(!edit_profile_data.experties ? 'empty' : '')">
                    <md-checkbox v-for="expertItem in $store.experties" :key="expertItem.id" v-model="edit_profile_data.experties" :value="expertItem.id">{{expertItem.name}}</md-checkbox>
              </md-select>
      </md-field>
         <!-- <md-chips :md-limit="2" v-model="others" md-placeholder="התמחות אחרת"></md-chips> -->
                    </div>
                    </div>
                <div class="clr"></div>
                  
                    <!-- <div class="col-md-6 password" >
                    <md-field>
                   <label>סיסמה:</label>
                    <md-input v-model="edit_profile_data.password" type="password"></md-input>
                </md-field>
                  </div> -->
                    
                  
              </div>
              <md-dialog-actions>
                  <md-button class="md-primary" @click="profile_edit()">שמור שינויים</md-button>
                  <md-button class="md-primary" @click="edit_profile = false;"> ביטול</md-button>
              </md-dialog-actions>
              </md-dialog>

  </div>
</template>
<style lang="scss">
.profile_edit_pop .inputs .md-checkbox{width: 100%;}
</style>
<script>

import UserIcon from '@/components/user_icon.vue';
import autocomplete from '@/components/forms/inputs/autocomplete.vue';

export default {
  props: ["profile_data"],
    components: { UserIcon, autocomplete },
    data(){
        return {
            id: this.$route.params.id,
            link: '/profile/' + this.$route.params.id,
            pic_uploaded:false,
            pic_loaded:false,
            edit_profile:false,
            new_pic:'',
            others:[],
            inputCompanies: {value: this.profile_data.company, title: 'חברה' },
            edit_profile_data: {
              id: this.profile_data.id,
              display_name: this.profile_data.display_name,
              display_phone: this.profile_data.display_phone,
              job: this.profile_data.job,
              phone: this.profile_data.phone,
              address: this.profile_data.address,
              about: this.profile_data.about,
              company: this.profile_data.company,
              experties: this.profile_data.experties_parse.map(function(rows){ return rows.id })              
            }
        }
    },
  methods: {
    open_other()
    {
        console.log('adw');
    },
     chooseFiles() {
            document.getElementById("fileUpload").click()
        },
        uploadFile(){
            
            var self = this;
            let formData = new FormData();
            formData.append('file', this.$refs.file.files[0]);

     var headers = { 'Content-Type': 'application/json; charset=utf-8', };
    
     if(!this.isNull(localStorage.getItem('user_token'))) 
     { 
       headers.Authorization = localStorage.getItem('user_token');
     }
    self.pic_loaded = true; 
    this.$http.post(window.api_url + 'change_profile_pic', formData, {/*emulateJSON:true,*/ headers: headers})
    .then(response => {
    if (response.body == 'error')
        {
            localStorage.setItem('user_token', '');
            document.location.reload();
        }
        
        self.new_pic = response.body.data.id
        self.pic_loaded = false; 
        self.pic_uploaded = response.body.data; 

        self.attachments.push(response.body.data);
        //alert(response.body.data)
        
        //console.log(response.body);
    }, response => { 
        
        console.error(response.body);
    });


        },
        

     profile_edit(){
       
       var self = this;
       if(self.isNull(self.edit_profile_data.display_name))
     {
              self.alert('נא להזין שם משמש')

     }
       else if(self.isNull(self.edit_profile_data.job))
     {
              self.alert('נא להזין תפקיד')

     }
else{


               this.net_api({ action: 'edit_profile', data: this.edit_profile_data }, function(){ 
/*
                 for (let i in self.edit_profile_data)
                 {
                   self.profile_data[i] = self.edit_profile_data[i];
                 }
                 */
                  // alert(self.link);
                 // alert(self)
                 self.edit_profile = false
                document.location = self.link;
// 

        } ); 
       
      }
      },


 
        
  },
  computed:{
      CompanyVal(){
          return this.inputCompanies.value;
      }
  },
  mounted(){


  },

  watch:{
      CompanyVal(){ 
          this.edit_profile_data.company = this.CompanyVal;
      }
  }
}
</script>

