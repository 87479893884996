<template>
  <div style="direction:ltr;padding-bottom:30px">
        <div class="title_wrapper">
       <h3> הדמיות הפרויקט</h3>
        </div>
    <LightGallery
      :images="images"
      :index="index"
      :disable-scroll="true"
      @close="index = null"
    />
    <ul>
    <carousel class="owl-rtl"  :responsive="{0:{items:1,nav:true},1100:{items:3,nav:true},1400:{items:3,nav:true,speed: 3000,}}" :margin="10" :rtl="true" :nav="true" :dots="false" :loop="false" :navText="['<i class=material-icons>navigate_next</i>','<i class=material-icons>navigate_before</i>']" >

      <li v-for="(thumb, thumbIndex) in images" :key="thumbIndex" @click="index = thumbIndex">
        <img :src="thumb.url">
      </li>
    </carousel>
    
    </ul>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

  export default {
   props: ["images"],
    components: {  carousel },
    data() {
      return {
      
        index: null,
      };
    },
  };
</script>
<style lang="scss">
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;margin-bottom: 30px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.owl-carousel{
img{height: 240px !important;border-radius: 8px;cursor: pointer;}
.owl-next{
  .material-icons{    font-size: 33px; line-height: 54px;}
    &{    position: absolute;top: 90px; left: -50px; ;width: 62px!important;height: 62px!important;box-shadow: 0 19px 19px 0 rgb(54 71 111 / 22%);
    background: #fff!important;color: #000!important;border-radius: 100%!important;}
}
.owl-prev{
  .material-icons{    font-size: 33px; line-height: 54px;}
    &{    position: absolute;top: 90px; right: -50px; ;width: 62px!important;height: 62px!important;box-shadow: 0 19px 19px 0 rgb(54 71 111 / 22%);
    background: #fff!important;color: #000!important;border-radius: 100%!important;}
}

}
</style>