
<template>
    <div>
        <div class="Courses">
            <h3>האירועים הקרובים</h3>
         <!-- <router-link :to="'/course_index/' + course.id" class="course" v-for="course in courses" :key="course.id"> -->
                  <a target='_blank' :href="(course.external_link ? course.external_link : '/course_index/'+course.id)" class="course" v-for="course in courses" :key="course.id">
                     <div class="circle" v-if="course.date"><div class="date_pic">{{course.date}}</div></div>
                     <div class="details">
                        <div class="name" v-text="course.name"></div>
                        <div class="title" v-text="course.short_text"></div>
                     </div>
            <a> <i class="fas fa-arrow-left"></i></a>
         <!-- </router-link> -->
                  </a>
                    <h3 v-if="knowlage.length > 0">מרכז הידע</h3>
         <!-- <router-link :to="'/course_index/' + course.id" class="course" v-for="course in courses" :key="course.id"> -->
                  <a target='_blank' :href="(course.external_link ? course.external_link : '/course_index/'+course.id)" class="course" v-for="course in knowlage" :key="course.id">
                     <div class="circle" v-if="course.date"><div class="date_pic">{{course.date}}</div></div>
                     <div class="details">
                        <div class="name" v-text="course.name"></div>
                        <div class="title" v-text="course.short_text"></div>
                     </div>
            <a> <i class="fas fa-arrow-left"></i></a>
         <!-- </router-link> -->
                  </a>
               <!-- <router-link :to="'/courses_index'"> <a class="link">לכל האירועים והקורסים</a></router-link> -->
        </div>
    </div>
</template>
<script>


export default {
    components: {  },
    props: ["colleagues"],
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            loaded: false,
            knowlage:[],
            courses:[]
        }
    },
    mounted(){
        var self = this;
            this.net_api({ action: 'course/results', data: {subject: 1} }, function(data){ 
     
     self.courses = data.data.items;
 } );
       this.net_api({ action: 'course/results', data: {premium: 1} }, function(data){ 
           self.knowlage = data.data.items
 } );
    }
}
    </script>
<style lang="scss" scoped>
.Courses{
    .link{color: #30abb7;font-size: 17px;text-decoration: underline;}
    &{    text-align: right;padding-right: 30px;}
    h3{    margin-bottom: 20px;display: block;text-align: center;}
    .course{
        .name{font-size: 15px;}
        .circle{ position: absolute; right: -10px;top: 0px;width: 42px;height: 42px; }
        .date_pic::after {position: absolute;top: -8px; bottom: -8px;left: -8px; right: -8px;background:linear-gradient(162deg, #e4b920, #b68c00 53%, #fae19c);content: '';z-index: -1;border-radius: 100%;}
        .date_pic{height: 100%;width: 100%;position: relative;border: 4px solid transparent;border-radius: 100%;line-height: 27px;text-align: center;font-size: 15px;padding-top: 4px;font-weight: 600;background: #fff;}
        .user_icon {flex: 1;    max-width: 50px; vertical-align: middle;margin-left: 10px;}
        .details{flex: 2;padding-right: 50px;}
        .title{    font-size: 16px;color: #666;line-height: 20px;}
        .fa-arrow-left{    color: #30abb7;font-size: 13px;}
        a{flex: 1;text-align: center;}
        &{display: flex;margin-bottom: 20px;position: relative;}
    }
  
}

</style>