<template>

  <div id="app_right_side">
      <div id="app_right_side_menu">
        <ul>
          <li v-for="item in menu" :key="item.id" :class="($route.path == item.link ? 'acitve' : '')"><router-link :to="item.link"><i :style="'-webkit-mask-image: url(' + item.icon  + '); mask-image: url(' + item.icon  + ')'"></i><div v-if="item.count" class="count">({{item.count}})</div><span v-text="item.name"></span></router-link></li>
        </ul>
      </div>
      <div class="line"></div>
       <div class="primuem_frame">
         <h3>פרימיום קהילת מרכז הנדל"ן</h3>
          <Courses :user_data="user_data" />
          <!-- <div class="line"></div> -->
           <!-- <HashTags :user_data="user_data" :premium="1"/> -->
        </div>
  </div>
     
</template> 

<script>
  // import HashTags from './sides/hashtags_premium.vue'
import Courses from './sides/subjects.vue'
export default {
  components:{Courses},
  props: ["user_data"],
  data(){
    return {
      menu: [
        
        {name: 'שיח נדל"ני', link: '/' , icon: require('@/assets/menu/book.svg')},
        {name: 'חברי קהילה', link: '/profile_index', icon: require('@/assets/menu/customer.svg'), count:this.$store.users_count},
        {name: 'חברות נדל"ן', link: '/company_index', icon: require('@/assets/menu/customer.svg'),count:this.$store.companies_count},
        {name: 'הודעות', link: '/inbox' , icon: require('@/assets/menu/connect.svg')},
        {name: 'עזרה', link: '/terms' , icon: require('@/assets/menu/lightbulb.svg')},
       // {name: 'זירת העסקאות', link: '/inbox' , icon: require('@/assets/menu/ticket.svg')},
       // {name: 'עזרה', link: '/settings' , icon: require('@/assets/menu/information.svg')},

      ]
    }
  },
  methods: {

  }
}
</script>
    

<style lang="scss" scoped>
.line{height: 1px; width: 80%; background: #ccc; display: block; margin: 25px auto;}
.primuem_frame{
  &{border: 4px solid #e4c776;}
  h3{    text-align: center; margin-bottom: 20px; margin-top: -10px; background: #fff; width: 90%; margin: 0 auto; margin-top: -7px; margin-bottom: 15px; color: #d6ab16;}
}
#app_right_side { position: fixed; width:300px; top: 54px; bottom: 0px; right: 0px; background: #fff; padding-top:20px; overflow-y: auto; }
#app_right_side_menu {
  .count{position: absolute;left: 20px;top: 0} 
  li { display: block;  line-height: 60px;position: relative;}
  li a { display: block;  padding: 0 15px; font-size: 20px;  color: #212731;}
  li a i { display: inline-block;  width:25px; height: 30px; mask-repeat: no-repeat; mask-position: right center; background: right center no-repeat; background-color: #000; vertical-align: middle; margin-left: 12px; }
  li:hover a, li.acitve a { background: #222f3c; color: #fff; 
    i{ background-color: #fff; }
  }
}
</style>