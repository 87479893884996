<template>
  <div>
      <autocomplete ref="ac" :search="search" :placeholder="input.title" @submit="doUpdate" :defaultValue="input.value" :key="forceUpdate"></autocomplete>

  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default {
  components: { Autocomplete },
      props: ["input", "url"],
    data: () => ({
      value: null,
      forceUpdate: 0
    }),

  mounted(){
    
    var self = this;
     var ac_url = 'forms/ac_results';
     if (!this.isNull(this.url)) ac_url = this.url;

      if (this.input.value > 0)
      {
        
         this.net_api({ action: ac_url, data: { id: this.input.value} }, function(data){ 
           
           self.input.value = data.data.value;
           self.forceUpdate++;
        } );
        
      }
  },

  methods: {
    
    // Search function can return a promise
    // which resolves with an array of
    // results. In this case we're using
    // the Wikipedia search API.
    search(input) {
        this.value = input;
      return new Promise(resolve => {
        if (input.length < 1) {
          return resolve([])
        }


        const term = input.toLowerCase();

        var headers = {
            'Content-Type': 'application/json; charset=utf-8',
        };
        
        if(!this.isNull(localStorage.getItem('user_token'))) 
        { 
            headers.Authorization = localStorage.getItem('user_token');
        }


     var ac_url = window.api_url + 'forms/ac_results';
     if (!this.isNull(this.url)) ac_url = window.api_url + this.url;


        this.$http.post(ac_url, {token: this.input.token, q: term }, {/*emulateJSON:true,*/ headers: headers})
        .then(response => {
            if (response.body == 'error')
            {
            localStorage.setItem('user_token', '');
            document.location.reload();
            }

            var ret = [];
            for (let i in response.body.data) ret.push(response.body.data[i].value);
            resolve(ret)

        });

      })
    },

    // Wikipedia returns a format like this:
    //
    // {
    //   pageid: 12345,
    //   title: 'Article title',
    //   ...
    // }
    // 
    // We want to display the title
    doUpdate(result) {
      return this.value = result;
    },

    // Open the selected article in
    // a new window
  },
  watch:{

      value(){
          this.input.value = this.value;
      }

  }
}


</script>

<style lang="scss" scoped>
  .md-autocomplete {

  }
</style>

