<template>
<div v-if="projects.length > 0">
    <div class="title_wrapper">
       <h3>    פרוייקטי נדל"ן לדוגמא </h3>
      
        </div>
        <div class="projects_wrapper">
              <carousel class="owl-rtl" :responsive="{0:{items:1,nav:true},1100:{items:3,nav:true},1400:{items:3,nav:true}}" :margin="10" :loop="true" :rtl="true" :nav="true" :dots="false" :navText="['<i class=material-icons>navigate_next</i>','<i class=material-icons>navigate_before</i>']" >
                          <div class="project" v-for="project in projects" :key="project.id">
                              <router-link :to="'/project/' + project.id" v-if="project.clickable == 0">
                            <div class="pic"  v-if="project.pic_parsed" :style="'background-image:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.29735644257703087) 35%, rgba(0,0,0,0) 100%),url('+ project.pic_parsed + ')'"></div>    
                            <div class="pic_default"  v-if="!project.pic_parsed"></div>    
                            <div class="description">
                           <h4 class="name" v-text="project.name"> </h4>
                          <p class="job" v-text="project.project_type"></p>
                          </div>
                          </router-link>
                          <span v-if="project.clickable == 1">
                            <div class="pic"  v-if="project.pic_parsed" :style="'background-image:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.29735644257703087) 35%, rgba(0,0,0,0) 100%),url('+ project.pic_parsed + ')'"></div>    
                            <div class="pic_default"  v-if="!project.pic_parsed"></div>    
                            <div class="description">
                           <h4 class="name" v-text="project.name"> </h4>
                          <p class="job" v-text="project.project_type"></p>
                          </div>
                          </span>
                         </div>
            </carousel>
        </div>
</div> 
</template>

<script>

import carousel from 'vue-owl-carousel'

export default {
    props:["profile_data", "project_id"],
    components: {  carousel },

    data(){
        return {
            user_data: this.$route.params.id,
            loaded: false,
            projects:[],
            
        }
    },
  methods: {
  }, 
   mounted(){


      var self = this;
      
      // categories 
      this.net_api({ action: 'projects', data: { id: this.profile_data.id, project_id:this.project_id} }, function(data){ 
        
        self.projects = data.data.items;
        self.loaded = true;
        self.projects_num = data.data;
        self.experties = data.data.experties_parse;

      } );
   


         
  }
}
</script>
<style lang="scss" scoped>
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.projects_wrapper{
    &{margin-right: -15px;margin-left: -15px;direction: ltr;}
    .project{
        .pic{box-shadow: none; margin: 0 auto; height: 230px; background-size: cover; width: 100%; border: none; border-radius: 0; background-position: center;}
        .pic_default{box-shadow: none;margin: 0 auto;height: 230px;width: 100%;border: none;border-radius: 0;background-image: url(../../assets/project_default.png);background-size: cover;}
    &{     background: #fff;margin: 20px 1.5%;padding: 0 0 0;position: relative;text-align: center;}
    .description{    position: absolute;bottom: 0px;right: 10px;text-align: right;}
    .name{font-size: 23px;margin-top: 17px;color: #fff;font-weight: 600;}
    .job{font-size: 20px;color: #fff;margin-bottom: 10px;text-align: right;}
    .follow{width: 100%; height: 45px; background: #d2d7df; line-height: 45px; font-size: 20px; margin-top: 20px;}
    }
}


</style>