<template>
<div>
    <div class="title_wrapper">
       <h3>  הנהלת החברה</h3>
        </div>
       <div class="manegment_wrapper">
             <div class="col-md-4"  v-for="manager in management" :key="manager.id">
                        <div class="manager " >
                            <UserIcon size="big" :user_data="manager" :clickable="false"/>
                            <h4 class="name" v-text="manager.name"> </h4>
                            <p class="job" v-text="manager.title"></p>
                        </div>
                   </div>
                   <div class="clr"></div>
        </div>
</div>
</template>

<script>

import UserIcon from '@/components/user_icon.vue';

export default {
      props: ["management"],

    components: { UserIcon },
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
        }
    },
  methods: {
  }, 
}
</script>
<style lang="scss" scoped>
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.manegment_wrapper{
        .col-md-4:nth-child(3n+1) { clear: both;}

    &{margin-right: -15px;margin-left: -15px;}
    .manager{
        .user_icon{    box-shadow: none ;margin: 0 auto; width: 100%; height: 250px; border-radius: 0;}
    &{   background: #fff;margin: 20px 1.5%;    padding: 0 0 0;text-align: center;}
    .user_icon.size_mid{margin: 0 auto;;}
    .name{font-size: 23px;margin-top: 25px;color: #30abb7;font-weight: 600;}
    .job{font-size: 20px;color: #212731;padding-bottom: 25px;}
    .follow{width: 100%; height: 45px; background: #d2d7df; line-height: 45px; font-size: 20px; margin-top: 20px;}
    }
}
@media only screen and (max-width: 600px) {
    .manegment_wrapper{
         .col-md-4:nth-child(3n+1) { clear: unset;}
         .col-md-4:nth-child(2n+1) { clear: both;}
        .manager{margin: 10px 5px;}
        .name{min-height: 40px;font-size: 20px !important;margin-top: 10px !important;width: 88%;margin: 0 auto;}
        .col-md-4{width: 50%;display:inline-block;padding: 0;}
        .manager .user_icon{height: 210px;}
    }
}
</style>