<template>
  <div id="company_profile_page" v-if="loaded">
    <div class="centered">
      <div class="braedcrumbs">
        <router-link to="/">ראשי</router-link><i class="fas fa-chevron-left"></i> <router-link to="/company_index">חברות</router-link><i class="fas fa-chevron-left"></i><span v-text="profile_data.name"></span>
      </div>
        <div class="box">
          <div class="info">
               <div class="user_logo">
            <UserLogo size="midi" :user_data="profile_data" :clickable="false"  />
            </div>
            <div class="user_info">
              <h1 v-text="profile_data.name"></h1>
              <h2 v-text="profile_data.feild_parsed"></h2>
              <span class="num_friends" v-if="colleagues.length > 1">{{colleagues.length}} עובדים </span>
             <!-- <span class="num_points">168 נקודות</span>-->
            </div>
                <div class="user_pic" v-if="profile_data.madad == 1"><div class="description" v-text="profile_data.experties_madad"></div>
                <div class="madad_logo" ></div>
            </div>
          </div>
             <ul class="profile_props">
                <li v-if="profile_data.experties_parse"><label class="lbl">תחומי התמחות:</label><div class="prop_content">
                  <router-link class="specialization_tag" v-for="exp in profile_data.experties_parse.slice(0 , 4)" :key="exp.id" v-text="exp.name" :to="'/company_index?experties=' + exp.id"></router-link>
                  </div>
                  </li>
               
                <li v-if="profile_data.web_site"><label class="lbl">אתר אינטרנט:</label><div class="prop_content">
                  <div class="web_link"  ></div>
                    <a :href="profile_data.web_site" target="_blank"   style="color:darkgrey">{{ profile_data.web_site }}</a>
                  </div></li>
                <li v-if="profile_data.address"><label class="lbl">כתובת:</label><div class="prop_content">
                  <div class="address" v-text="profile_data.address"  >
                  </div>
                  <div class="social_media">
                     <a :href="profile_data.facebook_link" target="_blank"  v-if="profile_data.facebook_link "> <div class="soci facebook" ></div></a>
                     <a :href="profile_data.instagram_link" target="_blank"  v-if="profile_data.instagram_link "> <div class="soci instagram" ></div></a>
                
                    </div>
                  </div></li>
                <li v-if="profile_data.phone"><label class="lbl">טלפון:</label><div class="prop_content"><div class="phone" >
                  <a :href="`tel:`+ profile_data.phone"  style="color:darkgrey">{{ profile_data.phone }}</a>
                </div></div></li>
                 <li v-if="!isNull(profile_data.projects_num) && profile_data.projects_num.Total > 0"><label class="lbl"> כמות פרויקטים:</label><div class="prop_content">
                  <div class="web_link"  ></div>
                    <a :href="profile_data.web_site" target="_blank"   style="color:darkgrey">{{ profile_data.projects_num.Total }}</a>
                  </div></li>
            </ul>
            <div class="options">
              <div class="btn join"  @click="add_emplyee(profile_data.id) ; profile_data.is_employee = true" v-if="!profile_data.is_employee" > הצטרף כעובד <i class="fas fa-user-tag"  ></i></div>
               <div class="btn join"  @click="remove_emplyee(profile_data.id); profile_data.is_employee = false" v-if="profile_data.is_employee" >  כבר לא עובד שם<i class="fas fa-user-tag"  ></i></div>

               <!--<div class="btn join" @click="follow(profile_data.id)"> הצטרף כקולגה <i class="fas fa-user-tag"></i></div>
              <div class="btn join"  @click="follow(profile_data.id) ; profile_data.is_followed = true" v-if="!profile_data.is_followed" >  הצטרף כקולגה <i class="fas fa-user-tag"  ></i></div>
               <div class="btn join"  @click="unfollow(profile_data.id); profile_data.is_followed = false" v-if="profile_data.is_followed" > הורד עוקב<i class="fas fa-user-tag"  ></i></div>
               -->

            </div>
                <MadadWidget :profile_data="profile_data"  v-if="profile_data.not_show_madad == 1"/>
            <a class="btn" @click="open_chat(profile_data.id)"  v-if="user_data.id == profile_data.id">הודעה</a>
      
            <div class="about" :key="profile_data.is_read_more"  v-if="profile_data.description">
                <h3>אודות {{profile_data.name}}:</h3>

                 <div v-if="profile_data.description.length >= 400">
                  <div class="content" v-html="(profile_data.is_read_more ? profile_data.description : profile_data.description.substring(0,400))"></div>
                  <a v-if="!profile_data.is_read_more && profile_data.description " @click="profile_data.is_read_more=true">קרא עוד </a>
                </div>
                  <div v-if="profile_data.description.length < 400">
                  <div class="content" v-html="profile_data.description"></div>
                </div>
            </div>
            
            

        </div>
      <CompanyVideo :video="video" v-if="loaded  && video != '' "/>
      <Management :management="management" v-if="loaded  && management.length > 0" />
      <Employees :colleagues="colleagues" v-if="loaded && colleagues.length > 0" :user_data="user_data"/>
      <Simulations :images="simulations" v-if="simulations.length > 0"/>
      <Interfaces :user_interfacses="user_interfacses" v-if="loaded && user_interfacses.length > 0" :user_data="user_data" />
      <Projects :profile_data="profile_data" v-if="loaded" />
      <Articles :profile_data="tags" :tags="tags" v-if="tags.length > 1" :name="profile_data.name"/>
    </div>
  </div>
</template>

<script>

import MadadWidget from '@/components/profile/madad.vue';
import UserLogo from '@/components/user_logo.vue';


// @ is an alias to /src
import Simulations from '../components/profile/simulations.vue'; 

import Employees from '../components/profile/employees.vue';
import CompanyVideo from '../components/profile/company_video.vue';
import Management from '../components/profile/management.vue';
import Projects from '../components/profile/projects.vue';
import Interfaces from '../components/profile/Interfaces.vue';
import Articles from '../components/articles.vue';

export default {
   props: ["user_data"],
    components: {  UserLogo,MadadWidget,Management,Employees,Projects, Interfaces, Articles, CompanyVideo,Simulations },

  metaInfo() { 
      
     return this.parse_meta(this.profile_data.meta);
    },
    data(){
        return {
            id: this.$route.params.id,
            profile_data: {},
            experties: [],
            loaded: false,
            users: [],
            tags:[],
            colleagues:[],
            user_interfacses: []
        }
    },
  methods: {
     /* add_employee()
      {
          
        var self = this;
        
        // categories 
        this.net_api({ action: 'add_employee', data: { user: this.user_data.user.id , profile: this.profile_data.id} }, function(data){ 
            
            self.companies = data.data.items;
        

        } ); 

      },*/
       follow(to_id)
        {
            this.net_api({ action: 'follow/add', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id,company:1 } }, function(){
            });
        },
     unfollow(to_id)
        {
            this.net_api({ action: 'follow/remove', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id} }, function(){
            });
        },
           add_emplyee(company_id)
        {
            this.net_api({ action: 'follow/add_emplyee', data:{  user_id: this.user_data.id , company_id: company_id,company:1 } }, function(){
            });
        },
     remove_emplyee(company_id)
        {
            this.net_api({ action: 'follow/remove_employee', data:{ user_id: this.user_data.id , company_id: company_id} }, function(){
            });
        },
  },
  mounted(){


      var self = this;
      
      var filter = { id: this.id};
      if (!(this.id > 0)) filter = { name: this.id};

      // categories 
      this.net_api({ action: 'company/get_profile', data: filter }, function(data){ 
        data.data.item.is_read_more = false;
        self.simulations = data.data.item.spec_gallery;

        self.tags = data.data.item.tags;
        self.profile_data = data.data.item;
        self.management = data.data.item.managament;
        self.video = data.data.item.video;
        self.colleagues = data.data.colleagues_list;
        self.user_interfacses = data.data.user_interfacses;
        self.loaded = true;

      } );

         
  }
    }
</script>

<style lang="scss" scoped>
#company_profile_page {
   .braedcrumbs{
    &{margin: 15px 0;}
    .router-link-active{color: #000;vertical-align: middle;}
    .fa-chevron-left{    font-size: 11px; margin: 0 5px; vertical-align: middle;}
  }
  .nav-tabs .nav-link.active{    color: #212731; font-weight: 700; font-size: 25px  !important;}
    .num_friends, .num_points { color:#00aeba; font-size: 20px; }
    .num_points { font-weight: bold; 
    &::before { content: "·"; margin:0 8px; font-size: 30px;}
    }
    .info{
      &{display: flex;    margin: 30px 0 0px;}
      .user_pic {
          .madad_logo{background-image: url(../assets/madad_logo.png);height: 140px;width: 150px;border-radius: 100%;background-size: cover;margin: 0 auto;}
          .description{    color: #212731;font-size: 15px;font-weight: 600;margin-bottom: -7px;}
          &{flex: 2;text-align:center;margin-top: -25px;}
        .user_icon {margin: 0 auto;}
      }
      .user_info{
        .title{color: #00aeba;font-size: 20px;line-height: 50px;}
        h1{margin-bottom: 3px;line-height: 35px;font-size: 31px;}
        h2{    color: #212731 ;font-size: 26px; font-weight: 500; text-align: right;margin-bottom: 5px;}
        &{flex: 4;padding-top: 30px;padding-right: 25px;}
      }
      .user_logo{flex: 2;margin-top: 10px;}
      }
    .profile_props { 
      &{margin-top: 10px;}
        li { display: block; margin-bottom: 10px;
            .lbl { display: inline-block; font-weight: bold; font-size: 22px; margin-left: 20px; }
            .prop_content { 
              &{display: inline-block;  }
               .specialization_tag{height: 40px;border-radius: 20px;width: fit-content;background-color: #8793a6;padding: 0 15px;color: #ffff;line-height: 38px;display: inline-block;margin: 0 5px;font-size: 19px;}
      .web_link{color: darkgray;font-size: 19px;text-decoration: underline;}
        .address{color: #212731;font-size: 20px;font-weight: 500;display:inline-block;}
        .phone{color: #212731;font-size: 20px;font-weight: 500;}
            }
         }
    }
    .social_media{
      & {vertical-align: middle;display: inline-block; }
      .soci { vertical-align: middle;display: inline-block;  height: 25px;width: 25px;background-position: center;background-size: cover;}
      .facebook{background-image: url(../assets/facebook.svg);margin: 0 15px 0 5px;}
      .instagram{background-image: url(../assets/instagram.svg);}
    }
    .options{
      &{width: 100%;text-align: center;margin: 20px 0;}
      .btn{margin: 0 10px;height: 50px;line-height: 48px;font-size: 17px;padding: 0 50px;background: transparent;color: #000 !important;border-color: #000 !important;border: 1px solid;}
      .join{color: #fff !important;background: #30abb7;border-color: #fff !important;font-size: 19px;}
    }
    .about { background: #f5f5f5; margin-left: -25px; margin-right: -25px; margin-bottom: -25px; padding: 50px; text-align: center; border-bottom: 14px solid #00aeba;
        h3 {margin-bottom: 10px;}   
      .content ::v-deep ul{list-style-type: disc;}
      .content ::v-deep {line-height: 30px !important;}
        .content {    font-size: 20px;font-weight: 500;
        
            ul li{list-style-type: disc !important;}
            }
            a{    font-weight: 800;
    font-size: 20px;
    margin-top: 20px;
    line-height: 35px;
   display: block;}

     }
}
@media only screen and (max-width: 600px) {
  #company_profile_page{
    .info{
      &{display: flex;
    margin: 0 0 20px !important;
    position: relative;
    justify-content: center;
    align-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;}
      .user_logo{display: inline-block; margin: 0;padding: 0;vertical-align: top;}
      .user_logo.size_big{width: 150px !important;background-size: contain;margin: 0 auto;height: 55px;}
      .user_info{
        &{display: inline-block;padding-top: 10px;}
        .title{font-size: 16px;line-height: 30px;}
        h1{font-size: 25px;margin-bottom: 5px;}
        h2{font-size: 22px;}
        .num_friends{font-size: 14px;}
        .num_points{font-size: 14px;}
        .num_points::before{font-size: 15px;}
      }
      .user_pic{margin-top: 15px;}
      .madad_logo{width: 120px !important;height: 120px !important; margin: 0 auto;}
      
    }
    .about{padding: 5px;}
     .profile_props li .prop_content .specialization_tag{margin: 5px 5px;padding: 0 10px;font-size: 17px;height: 35px;}
  }
       

}
</style>