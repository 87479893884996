<template>
  <div class="home">
    <div class="centered"><Feed ref="feed" :id="id" @postData="postData=$event" :check="true"/></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Feed from '@/components/feed.vue'

export default {
  components: {  Feed },
    data(){
        return {
            id: this.$route.params.id,
            postData: {}
        }
    },
metaInfo() { 
      
     return this.parse_meta(this.postData.meta);
    },
 

  methods: {
   
  }
}
</script>
