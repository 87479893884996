<template>
  <div id="feed_container">


                      

      <!--
      <div class="filter">
                      <h2>מיין לפי:</h2>

        <md-field>
                <label for="movie">מיין לפי</label>
                <md-select v-model="movie" name="movie" id="movie">
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                </md-select>
                </md-field>
                <md-field>
                <label for="movie">מיין לפי</label>
                <md-select v-model="movie" name="movie" id="movie">
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                </md-select>
                </md-field>
        </div>
        --> 
         <!-- <div class="title_wrapper">
                 <h3> תנאי שימוש</h3>
                 
         </div> -->
      <div class="posts_list">
        <div class="post" style="margin-top:100px">

            <div class="content_area">
                <div class="first_row"> <div class="logo"></div></div>
                 <div class="sec_row">
                <!-- <span class="user_title">{{name}}</span> -->
               </div>
                <span class="line"></span>
                <div class="content">
                  <span class="content" v-html="content"></span>
                </div>
            </div>
          
        </div>
      </div>

  </div>
</template>

<script>


export default {
    props: ["feed_key", "hashtag", "user_id", "color", "id" , "check"],
    data(){
        return {
            posts: [],
            alert_delete :false,
            active_edit:false,
            active_delete:false,
            deleted_post:{},
            get_without_agree:false,
            edit_post:false,
            edit_post_item: {},
            content:'',
            name:'מרכז הנדל"ן',
    
            
        }
    },
    mounted(){
        var self = this
             this.net_api({ action: 'terms'}, function(data){
                 self.content = data.data

        });
    },

}
</script>

<style lang="scss" scoped>
    .logo{background-image: url(../assets/logo.png);height: 95px;
    width: 225px;
    background-repeat: no-repeat;
    background-size: cover;}

#feed_container {
    .less_text{display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
    .read_more{text-align: right;margin: 10px ;font-weight: bold;display: block;text-decoration: underline;}
.filter{
    h2{    display: inline-block;vertical-align: middle;margin-left: 20px;}
    &{margin-bottom: 20px;}
    .md-field{display: inline-block;width: 30%;margin: 0 20px;text-align: right;direction: rtl;border-bottom: none;}
    .md-field label{text-align: right;left: unset;}
    .md-field:after{display: none;}
}
    .post { 
        & { background: #fff; box-shadow: 0px 10px 20px rgba(0,0,0,0.2); border-radius: 5px; margin-bottom: 30px; transition: all 1s ease; opacity: 1;width: 50%;margin: 0 auto; }

        .editbtn { position: absolute; left: 10px; top: 0px; z-index: 3;}
        
        .content_area { padding: 25px 85px; position: relative;
        .first_row{display: block;}
        .sec_row{display: block;}
            .user_icon { display: block; position: absolute; right:-30px; top: 10px;  }
            .user_name { display: inline-block; font-size: 29px; font-weight: bold; margin-bottom: 10px; color:#212731; }
            .specialty{margin-right: 15px;color: #00aeba;font-size: 20px;}
            .user_title { display: inline-block; font-size: 18px; font-weight: normal; margin-bottom: 10px; color: #7f7f7f; margin-left: 10px ;
                &::after { content: "·"; margin-right: 10px; } 
            }
            .post_date {  color: #7f7f7f; font-size: 18px; font-weight: normal; display: inline-block;}

            .line { display: block; height: 2px; background: #00aeba; margin: 15px 0; width: 45px; clear: both; }
            .content { white-space: pre-wrap; font-size: 20px; color: #000; line-height: 1.4; overflow-x: hidden;}

            .gallery { 
                .pic { /*height: 300px; background: center top no-repeat; background-size: contain;*/ max-width:90%; height: auto;  }
            }   
        }
        .url_area { 
            & { border-radius: 5px; border: 1px solid #666; overflow: hidden; background: #eee; }
            iframe { width: 100%; height: 460px;}
            .pic { width: 100%; height: 300px; background: center center no-repeat; background-size: cover;}
            .url_content { padding: 15px;}
            h3 { font-size: 20px; font-weight: bold; }
            h4 { font-size: 15px; font-weight: normal; }
        }
        .social_line {
                & { padding: 10px 15px;}
            .soci_btn { 
                span{margin-right: 2px;}
                & {  font-size: 18px; columns: #000; display: inline-block; height: 40px; line-height: 40px; padding: 0 15px; border-radius: 40px;}
                &:hover { background: #e9eaec; }
            }
        }
        .comments {
            .add_comment { background: #f5f5f5;}
        }

        .hashtags { 
            & { position: absolute; left: 0px; top: 0px; padding: 25px; }
            a { display: inline-block; line-height: 40px; border-radius: 40px; color: #fff; background: #8495a9; padding: 0 15px; margin-right: 8px;}
        }
    }


.list-enter-from, .list-leave-to {  transform: translateY(30px); }
.list-enter-from { opacity: 0; }

}
@media only screen and (max-width: 600px) {
#feed_container{
    .post .hashtags{    position: initial;padding: 0;margin: 10px 0;line-height: 34px;}
    .post{margin-bottom: 25px;}
    .post .hashtags a{line-height: 27px;padding: 0 10px;font-size: 13px;}
    &{padding: 0 0px}
    .post .content_area{padding: 35px 20px;}
     .soci_btn{font-size: 14px !important;padding: 0 10px !important;}
    .post .content_area .user_icon{right: 0px;top: -20px;}
    .post .content_area .user_icon.size_mid{height: 50px;width: 50px;}
    .post .content_area{
        .user_name{font-size: 20px;margin-bottom: 0;}
        .line{margin: 0 0 10px;}
    }
}
}
</style>