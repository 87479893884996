<template>
    <div>
        <div class="Courses">
            <h3>קורסים ואירועים קרובים </h3>
         <a target='_blank' :href="course.external_link" class="course" v-for="course in courses" :key="course.id">
                     <UserIcon size="sml" :user_data="course" :clickable="false" />
                     <div class="details">
                        <div class="name" v-text="course.name"></div>
                        <div class="title" v-text="course.short_text"></div>
                     </div>
            <a> <i class="fas fa-arrow-left"></i></a>
         </a>
               <!-- <router-link :to="'/courses_index'"> <a class="link">לכל האירועים והקורסים</a></router-link> -->
        </div>
    </div>
</template>
<script>

import UserIcon from '@/components/user_icon.vue';

export default {
    components: { UserIcon },
    props: ["colleagues"],
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            loaded: false,
            courses:[]
        }
    },
    mounted(){
        var self = this;
            this.net_api({ action: 'course/results', data: { free:1 } }, function(data){ 
        
                self.courses = data.data.items;
      

 } );
    }
}
    </script>
<style lang="scss" scoped>
.Courses{
    .link{color: #30abb7;font-size: 17px;text-decoration: underline;}
    &{    text-align: right;padding-right: 30px;}
    h3{    margin-bottom: 20px;display: block;}
    .course{
        .user_icon {flex: 1;    max-width: 50px; vertical-align: middle;margin-left: 10px;}
        .details{flex: 2;}
        .title{    font-size: 16px;color: #666;line-height: 20px;}
        .fa-arrow-left{    color: #30abb7;font-size: 13px;}
        a{flex: 1;text-align: center;}
        &{display: flex;margin-bottom: 20px;}
    }
  
}

</style>