<template>
    <div>
        <div class="HashTags">
            <h3> נושאים פופולארים למעקב</h3>
            <router-link v-for="hashtag in hashtags" :key="hashtag.id" :to="'/hashtag/' + hashtag.name" >
                <div class="specialization_tag">#{{ hashtag.name }}</div>
            </router-link>
            <!-- <a class="link">לכל  הנושאים</a> -->
        </div>
    </div>
</template>
<script>


export default {
       props:["premium"],

    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            loaded: false,
             PeopleYoMightKnow:[],
            hashtags: [],
        }
    },
 mounted(){

var self = this;
      this.net_api({ action: 'hashtags_home', data: { id: this.id } }, function(data){ 
        
        self.hashtags = data.data;

      } );

         
  },
}
</script>
<style lang="scss" scoped>
.HashTags{
    .link{color: #30abb7;font-size: 17px;text-decoration: underline;display: block;margin-top: 10px;}
    .specialization_tag{height: 40px;border-radius: 20px;width: fit-content;background-color: #8793a6;padding: 0 20px;color: #ffff;line-height: 38px;display: inline-block;margin: 0 5px;margin-bottom: 10px;}
    &{ text-align: right;padding-right: 30px;}
    h3{ margin-bottom: 20px;}
  
}

</style>