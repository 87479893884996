<template>
  <div class="home">
    <div class="centered"><NewPost feed_key="main" @add_post="add_post" /></div>
    <div class="centered"><Feed feed_key="main" ref="feed" /></div>
  </div>
</template>

<script>
// @ is an alias to /src
import NewPost from '@/components/new_post.vue'
import Feed from '@/components/feed.vue'

export default {
  components: { NewPost, Feed },


  metaInfo() { return {
    // Children can override the title.
    title: 'קהילת מרכז הנדל"ן',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'I have things here on my site.'}
    ]
  }},

  methods: {
    add_post(data)
    {
      this.$refs.feed.add_post(data.id)
    }
  },
  mounted(){
    if(this.$store.user && this.$store.user.id > 0 )
    {
        this.complete_signup(this.$store.user.id);
    }
          
  }
}
</script>

<style scoped>
.home { padding-top: 25px;}
</style>