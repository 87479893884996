<template>

  <div id="app_left_side" v-if="loaded">
    <div id="app_left_side_user_data">
      <UserIcon size="mid" :user_data="user_data" />
      <h3 v-text="user_data.display_name" />
      <h4 v-text="user_data.job" />
      <h5 v-text="user_data.company_name" />
      <ul class="lists">
        <li v-if="profile_data.colleagues_list_sum > 0"><div class="name_list" >קולגות בענף:</div><div class="vals" v-text="profile_data.colleagues_list.length"></div></li>
        <div class="clr"></div>
        <li v-if="profile_data.num_posts > 0"><div class="name_list" >פוסטים שפורסמו:</div><div class="vals" v-text="profile_data.num_posts"></div></li>
        <div class="clr"></div>
        <li v-if="profile_data.num_views > 0"><div class="name_list" > צפו בפרופיל שלך:</div><div class="vals" v-text="profile_data.num_views"></div></li>
                <div class="clr"></div>

      </ul>
      <div id="profile_finish" v-if="profile_data.profile_steps_precentage < 81">
        <vue-ellipse-progress :progress="profile_data.profile_steps_precentage"  :color="'#212731'"  :size="180"  :thickness="10"  emptyThickness="0%"  lineMode="in 2">
  <p slot="legend-caption">מהפרופיל הושלם</p>
  
</vue-ellipse-progress>
                      <div @click="change_location"  class="btn">השלם את הפרופיל</div>
<div class="clr"></div>
      </div>
      <div id="reputation">
        <span class="num">{{profile_data.reputation}}</span>
        <label>המוניטין שלך</label>
        <span class="followers_num">{{profile_data.colleagues_list.length}} עוקבים</span>
              <div class="on-hover">המוניטין הנדל"ני שלך מורכב ממעורבות שלך בקהילה, מתרומתך והחיבורים שאתה עושה</div>
      </div>
        <PeopleYoMightKnow :user_data="user_data" />
        <div class="line"></div>
        <UsersCarusel :user_data="user_data" />
                <div class="line"></div>
       <!-- <SuggestedGroups :user_data="user_data" />
                <div class="line"></div>-->
        <HashTags :user_data="user_data" />
              <div class="line"></div>
          <Courses :user_data="user_data" />
          <div class="line"></div>
          
               
    </div>
  </div>
     
</template> 

<script>
import UserIcon from './user_icon.vue';
import PeopleYoMightKnow from './sides/people_you_might_know.vue';
import UsersCarusel from './sides/users_carusel.vue'
/*import SuggestedGroups from './sides/SuggestedGroups.vue'*/
import HashTags from './sides/HashTags.vue'
import Courses from './sides/Courses.vue'

export default {
    components: {UserIcon,PeopleYoMightKnow,UsersCarusel,HashTags,Courses},

  props: ["user_data"],
  data(){
    return { 
      profile_data: {},
      more_users: [],
      loaded:false
    }
  },
  computed:{
  },
  mounted(){

var self = this;
      this.net_api({ action: 'user/get_profile', data: { id: this.user_data.id} }, function(data){ 
        
        self.profile_data = data.data;
        self.items = data.data.colleagues_list;
        self.loaded = true;
        // console.log(self.PeopleYoMightKnow);

      } );

         
  },
  methods: {
      change_location(){
      if(this.$route.path == '/profile/'+this.profile_data.id)
      {
          document.location = '/profile/'+this.profile_data.id+'?edit=true';
      }
         this.$router.replace('/profile/'+this.profile_data.id+'?edit=true');
        

        }
  }
}
</script> 

<style lang="scss" scoped>
.followers_num{font-weight: 600;}
.lists{
  &{padding: 50px 0 30px;}
  .name_list{float: right;padding-right: 20px;font-weight: 600;font-size: 18px;}
  .vals{    float: left;padding-left: 20px;color: #30abb7;font-size: 16px;font-weight: 600;}
  .clr{padding: 5px 0;}
}
.line{height: 1px; width: 80%; background: #ccc; display: block; margin: 25px auto;}
#app_left_side { position: fixed; width:300px; top: 54px; bottom: 0px; left: 0px; background: #fff; padding-top:20px;margin-top: 30px; overflow-y: auto; }
#app_left_side_user_data { 
  &{text-align: center; }
  h4{font-size: 19px;margin: 4px 0;}
  h3{font-size: 27px; margin-bottom: 10px;}
  h5{color: #9c9c9c;font-size: 18px;}
  .user_icon.size_mid{    width: 120px;
    height: 120px;
    margin-bottom: 19px;}
}
#reputation{cursor: pointer;}
#reputation .on-hover{ opacity: 0;transition: opacity 1.8s;font-size: 20px;width: 90%;margin: 0 auto;}
#reputation:hover .on-hover{display: block;transition: opacity 0.5s;opacity: 1;}
.user_icon { margin: 0 auto; margin-bottom: 10px; }

</style>