import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import hashtag from '../views/hashtag.vue'
import Profile from '../views/profile.vue'
import Project from '../views/project.vue'
import Company from '../views/company.vue'
import Notifications from '../views/Notifications.vue'
import Inbox from '../views/inbox.vue'
import post from '../views/post.vue'
import terms from '../views/terms.vue'
import company_index from '../views/company_index.vue'
import profile_index from '../views/profile_index.vue'
import project_index from '../views/project_index.vue'
import courses_index from '../views/courses_index.vue'
import course_index from '../components/courses/index.vue'
import Course from '../components/courses/item.vue'
import new_post from '../components/new_post.vue'
import loginByFB from '../components/loginByFB.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', props: { hideSideBars:false, hideLogin:false }, component: Home },
  { path: '/hashtag/:tag', name: 'hashtag', props: { hideSideBars:false , hideLogin:false}, component: hashtag },
  { path: '/post/:id', name: 'post', props: { hideSideBars:false, hideLogin:true }, component: post },
  { path: '/project/:id', name: 'Project', props: { hideSideBars:false, hideLogin:true }, component: Project },
  { path: '/profile/:id', name: 'Profile', props: { hideSideBars:false , hideLogin:true }, component: Profile },
  { path: '/company/:id', name: 'Company', props: { hideSideBars:false, hideLogin:true }, component: Company },
  { path: '/Course/:id', name: 'course', props: { hideSideBars:true , hideLogin:false}, component: Course },
  { path: '/Notifications', name: 'Notifications', props: { hideSideBars:true, hideLogin:false }, component: Notifications },
  { path: '/terms', name: 'terms', props: { hideSideBars:false, hideLogin:false }, component: terms },
  { path: '/inbox', name: 'Inbox', props: { hideSideBars:false, hideLogin:false }, component: Inbox },
  { path: '/company_index', name: 'company_index', props: { hideSideBars:false , hideLogin:false}, component: company_index },
  { path: '/profile_index', name: 'profile_index', props: { hideSideBars:false, hideLogin:true }, component: profile_index },
  { path: '/courses_index', name: 'courses_index', props: { hideSideBars:false, hideLogin:true }, component: courses_index },
  { path: '/project_index', name: 'project_index', props: { hideSideBars:false , hideLogin:true}, component: project_index },
  { path: '/course_index/:id', name: 'course_index', props: { hideSideBars:true , hideLogin:false}, component: course_index },
  { path: '/new_post', name: 'new_post', props: { hideSideBars:true , hideLogin:false}, component: new_post },
  { path: '/loginByFB/:id', name: 'loginByFB', props: { hideSideBars:true , hideLogin:false}, component: loginByFB },

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: '/community/',
  routes
})

export default router
