<template>
  <div id="app">
    <div 
      id="iframe-wrapper"
      :style="iframe.wrapperStyle" height="300">
      <iframe 
        v-if="loaded"
        :src="iframe.src"
        :style="iframe.style"
        :height="iframe.style.height"
        :width="iframe.style.width"
        allowfullscreen
        webkitallowfullscreen
        type="application/pdf"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#iframe-wrapper{width: 100% !important;height: 250px !important;margin-bottom: 30px;}

</style>

<script>
export default {
  props: ["video"],
  data() {
    return {
      loaded: false,
      iframe: {
        src: "//cdn.bamboo-video.com/api/embed?id=" + this.video + "&uiconf_id=11629215&type=entry&iid=5acb09316986fb539d5007a4",
        style: null,
        wrapperStyle: null,
      }
    }
  },
  mounted() {
      
    this.iframe.style = {
     
      width: "100%",
      height: "100%",
   
    }    
    this.iframe.wrapperStyle = {
      overflow: 'hidden',

    } 
    this.loaded = true;
  }
}
  
</script>