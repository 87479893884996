<template>
  <div id="chat_user_details">
      <div class="inner">
        <UserIcon size="big" :user_data="userData" :clickable="false" />
        <h3 v-text="userData.display_name"></h3>
        <h3 v-text="userData.job"></h3>
        <h3 v-text="userData.company_name"></h3>
    </div>
  </div>
</template>

<script>

import UserIcon from '../user_icon.vue';
export default {
    props: ["userData"],
    components: { UserIcon },
    data(){
        return {
        }
    },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
#chat_user_details .inner { width: 170px; margin: 0 auto;}
#chat_user_details .inner { padding-top: 40px;
    .user_icon { border-radius: 8px !important; margin: 0 auto; margin-bottom: 25px; }
    h3 { font-size: 24px; color: #343c4b; font-weight: normal; text-align: center;margin: 7px;}
}
</style>