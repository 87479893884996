<template>
    <div class="wrapper"> 
        <div v-if="loaded">
          <h3>המלצות למעקב</h3>
         
              <carousel class="owl-rtl" :autoplay="true"  :responsive="{0:{items:1,nav:true},1100:{items:1,nav:true},1400:{items:1,nav:true}}" :margin="10" :loop="true" :rtl="true" :nav="true" :dots="false" :navText="['<i class=material-icons>navigate_next</i>','<i class=material-icons>navigate_before</i>']" >
                  <div class="acquaintances" v-for="item in users_follow" :key="item.id">
                      <router-link :to="'/profile/' + item.id">
                      <UserIcon size="big" :user_data="item" :clickable="false"/>
                      <h4 class="name" v-text="item.display_name"></h4>
                    <p class="job" v-text="item.job"></p>
                    </router-link>
                    <div :key="item.is_followed">
                    <div class="follow"  @click="follow(item.id) ; item.is_followed = true" v-if="!item.is_followed">  הוסף כקולגה <i class="far fa-eye"></i></div>
                    <div class="follow"  @click="unfollow(item.id); item.is_followed = false" v-if="item.is_followed" > הורד <i class="far fa-eye-slash"></i></div>
                    </div>
 
                    <a class="btn new_post" style="rgb(0, 0, 0) !important " @click="open_chat(item.id)" v-if="$store.user.id != item.id && item.is_senior < 1">הודעה <i class="far fa-comments"></i></a>
                  </div>
              </carousel>
              <a href="/profile_index" class="go_to">מצא עוד חברים</a>
        </div>
    </div>
</template>

<script>


import carousel from 'vue-owl-carousel'
import UserIcon from '../user_icon.vue';

export default {
    components: {  carousel ,UserIcon},

  props: ["user_data" ],
  data(){
    return { 
      loaded: false,
      users_follow: [],
      profile_data: {},
    }
  },
  computed:{
 
  },
   mounted(){

    var self = this;

    if (!this.isNull(self.$store.users_follow))
    {
        self.users_follow = self.$store.users_follow;
        self.loaded = true;
    }
    else
    {
        this.net_api({ action: 'users_follow', data: {} }, function(data){ 

            self.users_follow =  data.data;

            self.loaded = true;
        } );
    }

    },

   methods: {
     follow(to_id)
        {
            this.net_api({ action: 'follow/add', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id} }, function(){
            });
        },
     unfollow(to_id)
        {
            this.net_api({ action: 'follow/remove', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id} }, function(){
            });
        },
  },
}
</script>
<style lang="scss" >
.wrapper{direction: ltr;}
.go_to{color: #30abb7 !important;font-size: 20px; text-decoration: underline !important;}
.wrapper{
  .owl-next{    position: absolute  !important; left: 10px!important;font-size: 76px !important; top: 38%;box-shadow: none !important;}
  .owl-prev{    position: absolute; right: 10px!important;font-size: 76px !important; top: 38%;box-shadow: none !important;}
  .material-icons{font-size: 30px;}
  .owl-theme .owl-prev, .owl-theme .owl-next{background: none !important;color: #000  !important;}
}
.acquaintances{
  .owl-carousel .owl-next{box-shadow: none;}
  .owl-carousel .owl-prev{box-shadow: none;}
        .link{color: #30abb7;font-size: 17px;text-decoration: underline;}

    .name{    color: #30abb7;margin: 15px auto 0;font-size: 20px;}
    .job{font-size: 17px;  color: #000;}
    .user_icon.size_big{margin: 0 auto;margin-top: 4px;}
    .follow{width: 70%;height: 40px; border-radius: 26px;margin: 15px auto;background-color: #30abb7;line-height: 40px;color: #fff;font-weight: 400;font-size: 19px;}
    .new_post{    width: 70%;height: 40px;border-radius: 26px;background-color: #fff !important;color: #000!important;border: 1px solid #000;line-height: 40px;color: #000 !important;}
    &{text-align: center;margin: 50px 0 0;}
    }
</style>