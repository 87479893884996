<template>
    <div class="cat" :id="'lecturer'+cat.writer_data.id">
          <div class="item">
            <div class="col-md-6">
                <router-link :to="'/course/'+courses[0].id"> <h2 v-text="cat.name"></h2></router-link>
                <p v-text="cat.description"> </p>
             <router-link :to="'/course/'+courses[0].id"> <div class="btn"> לסרטונים נוספים <div class="more" style="    display: inline-block;color: #30abb7;">({{courses.length}}+)</div></div></router-link>
            </div>
            <div class="col-md-6">
  <!-- <div class="course_pic" :style="'background-image:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.29735644257703087) 35%, rgba(0,0,0,0) 100%),url('+ cat.pic_parsed +')'"> -->
                <div class="course_pic" :style="'background-image:url('+ cat.pic_parsed +')'">
                   <router-link :to="'/course/'+courses[0].id">   <i class="far fa-play-circle"></i></router-link>
                <!-- <div class="wrap">
                    <h3 v-text="cat.name"></h3>
                   
                    </div> -->
                    <div class="bottom">
                     <!--   <span>שיעור מספר 5 |</span>
                        <span v-text="cat.id"></span>-->
                    </div>
                     <div class="amount">
                          <h3 v-text="cat.name"></h3>
                        <!-- <span>{{cat.num_episodes_whatched}}/{{cat.items.length}}</span> -->
                        <label>{{time_convert(cat.length_total)}}</label>
                    </div>
                </div>
            </div>
            <div class="clr"></div>
        </div>
        
        <div class="item smalls" v-for="(course, index) in courses" :key="course.id">
            <router-link :to="'/course/'+course.id">
            <div class="col-md-6">

            </div>
            <div class="col-md-6">
                <div :class="'course_pic' + (course.watched == 1 ? ' watched' : '')" :style="'background-image:url('+ course.pic_parsed +')'"> <i class="far fa-play-circle"></i></div>
               
                    <h3 v-html="course.title"> </h3>
                    <h4 v-html="course.content">  </h4>
                    <div class="bottom">
                        <span>שיעור מספר {{index + 1}} |</span>
                        <span> {{course.length_video}} דקות </span>
                    </div>
                
            </div>
            </router-link>
        </div>
           <div class="clr"></div>

    </div>
</template>
<script>
export default {
    props: ["courses" , "cat"],
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            loaded: false,
            
        }
    },
  methods: {
  }, 
}
</script>
<style lang="scss" scoped>
.btn{background-color: #212731 !important;color: #fff;font-size: 20px;font-weight: 600 !important;}
.amount h3{font-size: 22px;width: 85%;margin-bottom: 10px;}
.amount{padding:6px 20px 6px;   position: absolute;  bottom: 0;  width: 100%;  color: #fff;  height: auto;  background: linear-gradient(
90deg
, rgba(0,0,0,0.5) 35%, rgba(0,0,0,0.5) 100%);
span{font-size: 19px;}
label{font-size: 19px;}
}
.cat{padding:20px 0;background: #fafafa;margin: 30px 0;}
.item{background: transparent;box-shadow: none;}
.item h3{font-weight: 500;font-size: 19px;}
.watched{opacity: 0.6;}
.item:nth-child(1){
    .col-md-6{padding: 0;}
    .btn{  background-color: #212731;}
    .fa-play-circle{font-size: 60px;position: absolute;left: 0;right: 0;margin: 0 auto;top: 36%;color: #fff;}
    h2   {padding-top: 70px;margin-bottom: 10px;width: 90%;line-height: 30px;}
    p{    font-size: 18px;margin-bottom: 20px;width: 90%;}
    &{margin-bottom: 70px;padding: 0;}
    .course_pic::after{content: "";position: absolute;right: -30px;height: 80%;top: 28px;background: #30abb7;width: 30px;z-index: 1;}  
    .course_pic{   
        &{height: 400px;position: relative; ;z-index: 2;background-position: center;background-size: cover;background-repeat: no-repeat;}
        .wrap{position: absolute;bottom: 40px;color: #fff;right: 20px;}
        .wrap h3{font-size: 22px;width: 85%;margin-bottom: 10px;}
        
        h4{ bottom: 60px;font-weight: 400}
        .bottom{    position: absolute;bottom: 0;width: 100%;padding: 5px;padding-right: 20px;color: #fff;}
    }
}
.item{position: relative;}
//.smalls:nth-child(4n+1){clear: both;}
.item:nth-child(1)::after{content: "";position: absolute;width: 90%;height: 14px;bottom: -40px;background-size: cover;background-image: url(../../assets/line.png);left: 0;right: 0;margin: 0 auto;}
.item:nth-child(n+2) {
        .fa-play-circle{font-size: 40px;position: absolute;left: 0;right: 0;margin: 0 auto;top: 36%;color: #fff;}

   .col-md-6:nth-child(1){display: none;}
   &{width: 25%;float: right;position: relative;    box-shadow: none;background: transparent;}
   .col-md-6{width: 100%;}
   .bottom{color: #000;font-size: 15px;font-weight: 600;}
   .course_pic{height: 140px;width: 100%;background-position: center;background-size: cover;background-repeat: no-repeat;position: relative;margin-bottom: 10px;}
}
@media only screen and (max-width: 600px) {
    .item:nth-child(1)::after{display: none;}
    .centered_courses{
        .item:nth-child(1) h2{padding-top: 0;width: 100%;text-align: center;}
        .item:nth-child(1) {text-align: center;margin-bottom: 20px;}
        .item:nth-child(1) .col-md-6{margin-bottom: 20px;}
        .item:nth-child(1) .course_pic{background-size: contain;height: 250px;}
        .item:nth-child(n+2){width: 100%;margin-bottom: 20px;margin: 0 auto;}
        .item:nth-child(n+2) .course_pic{height: 150px;}
        .cat:nth-child(even) .col-md-6:nth-child(1){padding-right: 0;width: 100%;}
        .cat:nth-child(even) .col-md-6{float: unset;margin-bottom:20px}
    }
}

</style>