import { render, staticRenderFns } from "./simulations.vue?vue&type=template&id=28f8d2fe&scoped=true&"
import script from "./simulations.vue?vue&type=script&lang=js&"
export * from "./simulations.vue?vue&type=script&lang=js&"
import style0 from "./simulations.vue?vue&type=style&index=0&id=28f8d2fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f8d2fe",
  null
  
)

export default component.exports