<template>
    <div :class="'user_icon size_' + size_class" :style="'background-image:url(' + (isNull(user_data.pic_parsed) ? iconUrl : user_data.pic_parsed) + ');'">
        <div class="premium" v-if="user_data.premium == 1"></div>
        <router-link :to="'/profile/' + user_data.id" v-if="clickable !== false && user_data.id != 'anonymous'"></router-link>
    </div>
    
</template>


<script>


export default {  
    
  props: ["user_data", "size", "clickable"],
    data(){
        return {
            open:true,
        };
    },

  computed: {
      size_class()
      {
          var ret = '';
          switch (this.size)
          {
              case 'sml': ret = 'sml'; break;
              case 'mid': ret = 'mid'; break;
              case 'big': ret = 'big'; break;
          }

          return ret;
      },
      iconUrl () {
            return require('../assets/no-user.jpg')
            // The path could be '../assets/img.png', etc., which depends on where your vue file is
        }
  },
  methods: {
  
  }
}
</script>

<style lang="scss" scoped>
.user_icon { display: block; border-radius: 100%; background: #eee center center no-repeat;  background-size: cover;   box-shadow: 0 14px 22px 0 rgba(0, 0, 0, 0.15);position: relative;
.premium {position: absolute;height: 30px;width: 25%;right: 1%;top:1%px;background-image: url(../assets/premium.svg);background-size: contain;background-position: center; background-repeat: no-repeat;}

    &.size_sml { width:50px; height: 50px; }
    &.size_mid { width: 90px; height: 90px;  }
    &.size_big { width: 140px; height: 140px;  }
    a { display: block; width: 100%; height: 100%;}
     @media only screen and (max-width:600px) {
         &.size_sml { width:50px; height: 50px}
        &.size_mid { width: 90px; height: 90px}
        &.size_big { width: 100px; height: 100px}
     }
}
</style>