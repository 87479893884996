<template>
   <transition name="slide">
<div :class="'therd ' + (!side_menu ? 'side_menu_opend' : 'side_menu_closed')">
      <div class="side_menu" v-if="is_mobile()"> <div id="menu_hamburger" @click="menu_handle" :key="menu_handle">
        <i class="fas fa-chevron-left"></i>
        <i class="fas fa-chevron-right"></i>
      </div>
      </div>
  <div id="threads">
      <ul class="list">
          <li  v-for="item in list" :key="item.id" @click="activeItem = item.uid;$emit('close_menu');side_menu= true" :class="(activeItem == item.uid ? 'active' : '') ">
              <div :class="(item.msg_to_read > 0 ? 'to_read' : '')">
              <UserIcon size="sml" :user_data="item.user_data" :clickable="false" />
              <span class="name" v-text="item.user_data.display_name" v-if="!side_menu && is_mobile()"></span>
              <span class="name" v-text="item.user_data.display_name" v-if="!is_mobile()"></span>
              </div>
            </li>
      </ul>
  </div>
  </div>
   </transition>
</template>

<script>

import UserIcon from '../user_icon.vue';
export default {
    components: { UserIcon },
    data(){
        return {
            list: [],
            side_menu: true,
            activeItem: ''
        }
    },
  methods: {
      menu_handle(){
          this.side_menu = !this.side_menu
          this.$emit('side_menu');
      },

  },
  computed:
  {
      activeItemComp(){
          var ret = null;
          for (let i in this.list)
          {
              if (this.activeItem == this.list[i].uid)
              {
                  ret = this.list[i];
              }
          }

          return ret;
      }
  },
  mounted(){
      
      var self = this;
      // categories 
      this.net_api({ action: 'chat/get_threads', data: { } }, function(data){ 
          
          self.list = data.data.list;
          if(self.isNull(self.list))
          {
              
          self.$emit('empty')
          }
          self.activeItem = data.data.list[0].uid;
      });
  },
  watch: {
      activeItem(){
          this.$emit('open_thread', this.activeItem, this.activeItemComp.user_data)
      }
  }
}
</script>

<style lang="scss" scoped>
 #nav-icon1{height: 30px;margin: 3px ;width: 35px;}
 #nav-icon1 span{color: #fff !important;}

  .side_menu_opend #menu_hamburger #nav-icon1 span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .side_menu_opend {
    .fa-chevron-left{display: none;}
  }
  .side_menu_closed{
      &{transition: .3s all ease-in-out}

  
   .user_icon.size_sml{height: 35px;width: 35px;}
 #threads .list li{padding: 10px;}
 .fa-chevron-right{display: none;}
  }
.side_menu{
      &{transition: .3s all ease-in-out}
  #menu_hamburger{position: absolute;
    left: -29px;
    right: auto;
    background: #4c4c4c;
    width: 30px;
    line-height: 32px;
    height: 30px;text-align: center;}
 
}

.therd{position: relative;}
#threads {
    &{max-height: calc(100vh - 60px);overflow: auto;}
    .list { 
        li { display: block; cursor: pointer; overflow: hidden; padding: 15px;
            &.active { background: #1e212a; }
            &.to_read { background: #444853; }
            .user_icon  { float:right; margin-left:10px; }
            .name { display: block;}
            .msg { display: block; font-size: 13px; color: #999;}
        }
    }
}

</style>