<template>
  <div class="home">
    <div class="centered">
      <h1 v-text="tag"></h1>
      <Feed :hashtag="tag" ref="feed" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Feed from '@/components/feed.vue'

export default {
    data(){
        return {
            tag: this.$route.params.tag,

        }
    },
  components: { Feed },
  methods: {
  }
} 
</script>
<style scoped>
   h1{margin:10px 0 30px;}
@media only screen and (max-width: 600px) {
    h1{margin: 40px 0;text-align: center;}
}
</style>
