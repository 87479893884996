<template>

      <header class="head">
        
        <div class="logo"><router-link to="/" id="logo"></router-link></div>
        <div id="head1">
          <div class="centered-big">

              <ul class="menu">
                <li v-for="item in menu" :key="item.id"><a :href="item.link" target="_blank" v-text="item.name"></a></li>
                <li><router-link to="/profile_index"><i class="fas fa-search"></i></router-link></li>
              </ul>

          </div>

          <div id="menu_left_area" >
            
            <ul class="left_menu">
              <li><router-link to="/inbox"><a id="notification_btn"><div class="envelope"></div><span v-if="chat > 0" :key="chat">{{chat}}</span></a></router-link></li>
              <li><a id="notification_btn" @click="show_notifications = !show_notifications"><div class="bell"></div><span v-if="notifications > 0">{{notifications}}</span></a>
              <!-- <transition name="slide" mode="out-in"> -->
                <div id="user_popup_notifications" v-if="show_notifications" v-on-clickaway="awayNot">
                  <Notifications @close_notifications="show_notifications = false;change_notifications()" v-on-clickaway="away"/>
                </div> 
              <!-- </transition> -->
              </li>
              <li><div id="user_drop" @click="show_user_popup=!show_user_popup">
                <UserIcon size="sml" :user_data="user_data" :clickable="false" /><span>שלום,  {{ user_data.display_name }}</span><i class="fas fa-angle-down"></i></div>
              <transition name="slide" mode="out-in">
                <div id="user_popup" v-if="show_user_popup" v-on-clickaway="away">
                <ul>
                  <li><router-link :to="'/profile/' + user_data.id">הפרופיל שלי</router-link></li>
                  
                  <li><a @click="logout()">יציאה</a></li>
                </ul>
                </div> 
              </transition>
              </li>
            </ul>

            </div>
          </div>

        <div id="head2">
           <ul>
                <li><router-link to="/settings">Settings</router-link></li>
              </ul>
        </div>
      </header>
</template>

<script>

import { mixin as clickaway } from 'vue-clickaway';
import UserIcon from './user_icon.vue';
import Notifications from '../views/Notifications.vue'
  
export default {
  mixins: [ clickaway ],
  components: { UserIcon,Notifications },
  props: ["user_data", "chat","menu","notifications"],
  data(){
    return {
      show_user_popup: false,
      show_notifications: false
    }
  },
  methods: {
    awayNot: function() {
     this.show_notifications =  false
    },
    change_notifications(){
        this.notifications = (this.notifications-1)
    },
    logout(){
      localStorage.removeItem('user_token');
      document.location = '/';
    },
    away: function() { 
      this.show_user_popup = false;
    },

  }
}
</script>

<style lang="scss" scoped>
#app {
  .slide-leave-active,
.slide-enter-active {
  transition: 0.6s;
}
.slide-enter {
  transform: translate(0%, 0);
}
.slide-leave-to {
  transform: translate(0%, 0);
}

  header { 
    background: #fff; height: 54px;  box-shadow: 1px 0 20px 0 rgba(0,0,0,0.3); position: fixed; top: 0px; left: 0px; right:0px; z-index: 10;
    @media only screen and (min-width: 600px) { }
    @media only screen and (max-width: 600px) { position: fixed; left: 0px; top: 0px; right:0px; z-index: 99; padding-left: 50px; }

    #head1 {
      &{ background: #222f3c; color:#fff; height: 54px; line-height: 54px;}
      .menu { list-style:none; margin-right: -25px;
        li { display: inline-block;
         a { color: #fff; display: block; padding: 0 25px;}
        }
      }
    }
    #head2 {
      & { background: #fff; color:#222f3c; height: 36px; line-height: 36px;}
      ul { list-style:none;
        li { display: inline-block;}
      }
    }

  }
  #menu_left_area { position: absolute; left: 20px; top: 0px; bottom: 0px;}
  #menu_left_area .fa-angle-down{font-size: 16px;margin-right: 5px;color: #30abb7;cursor: pointer;}

  .left_menu {
    li { display: inline-block; padding-right: 25px; position: relative; }
  }

  #user_drop {
    &{cursor: pointer;} 
    .user_icon { display: inline-block; vertical-align: middle; margin-left: 10px;}
  }
  #user_icon { cursor: pointer; position: absolute; right:50px; top: 10px; width:40px; height: 40px; line-height: 40px; text-align: center; font-weight: bold; border-radius: 100%; background-color: #0077ff; color: #fff;}
  #user_popup {
    & { position: absolute; right: 0px; top: 54px; z-index: 90; background: #fff; border:1px solid #eee; border-top:none;}
    ul { list-style: none; line-height: 40px;}
    ul li { display: block;}
    ul li a { display: block; padding: 0 0px 0 30px;font-weight: 600;}
  }

  #notification_btn { color: #fff; position: relative; display: inline-block;
  .bell{background-image: url(../assets/bell.png);height: 25px; width: 24px; background-size: contain;}
  .envelope{background-image: url(../assets/mail.png);height: 25px; width: 24px; background-size: contain;}
    span {display: block; width: 15px;height: 15px; line-height: 16px;; background: red; color: #fff; font-size: 13px; text-align: center; position: absolute; right: -8px; top: -2px; border-radius: 100%; }
  }
}
#user_popup_notifications{position: absolute;width: 400px;left: -66px;}

</style>