<template>
  <div id="company_index_page">
  <div class="centered" id="company_index_page">
    <div class="braedcrumbs">
        <router-link to="/">ראשי</router-link><i class="fas fa-chevron-left"></i> <router-link to="/project_index">פרוייקטי נדל"ן</router-link>
      </div>
      <h3>אינדקס פרוייקטי נדל"ן</h3>
      <div class="filter">
          <md-field>
      <label>חפש לפי שם פרויקט </label>
      <i class="fas fa-search"></i>
      <md-input @keyup="refresh_results()" v-model="search"></md-input>
    </md-field>
    <md-field>
          <label for="movie">תחום התמחות</label>
          <md-select name="movie" id="movie" v-model="experties_selected" @md-selected="load_experties()">
            <md-option  v-for="expert in experties" :key="expert.id"  v-bind:value="expert.id" >{{expert.name}}</md-option>
          </md-select>
        </md-field>
         <!-- <md-field>
          <label for="movie">תחום התמחות</label>
          <md-select name="movie" id="movie">
            <md-option value="fight-club">שמאות</md-option>
          </md-select>
        </md-field> --> 
        
      </div>
      <div class="letters">
          <a v-text="letter" v-for="letter in letters" :key="letter" @click="activeLetter = letter; load_results()" :class="(activeLetter == letter ? 'active' : '')"></a>
      </div>
      <div class="companies_wrapper">
        <div class="col-md-4"  v-for="company in companies" :key="company.name">
        <div class="company" >
              <div class="pic"  v-if="company.logo_parsed" :style="'background-image:url('+company.logo_parsed+')'"></div>    
              <div class="pic_default"  v-if="!company.logo_parsed"></div>    
              <div class="name" v-text="company.name"></div>
              <div class="title" v-text="company.short_text"></div> 
             <router-link :to="'/project/' + company.id">לעמוד הפרויקט</router-link>
</div>
        </div>
        <div class="clr"></div>
      </div>
  </div>
  </div>
</template>

<script>


export default {
  
    components: {  },
     metaInfo() { return {
    // Children can override the title.
    title: "רשימת פרוייקטי נדלן",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'I have things here on my site.'}

    ]
  }},
    data(){
        return {
            loaded: false,
            own_profile:false, 
            companies:[],
            experties_selected:"",
            search:"",
            letters: [
                "א", "ב", "ג", "ד","ה" , "ו" , "ז" , "ח" ,"ט" , "י","כ","ל" ,"מ" ,"נ" ,"ס" ,"ע" ,"פ","צ", "ק","ר","ש","ת"
            ],
            activeLetter: ""
        }
    },
  methods: {
      load_results()
      {
          
        var self = this;
        
        // categories 
        this.net_api({ action: 'projectIndexRefresh', data: { letters: this.activeLetter} }, function(data){ 
            
            self.companies =  data.data.items.items;
        

        } ); 

      },
       load_experties()
      {
          
        var self = this;
        
        // categories 
        this.net_api({ action: 'projectIndexRefresh', data: {experties: this.experties_selected} }, function(data){ 
            
            self.companies = data.data.items.items;


        } ); 

      },
      refresh_results()
      {
          
        var self = this;
        
        // categories 
        this.net_api({ action: 'projectIndexRefresh', data: { letters: this.search} }, function(data){ 
            
            self.companies = data.data.items.items;
        

        } ); 

      }

  },
  mounted(){

        var self = this;
        
        // categories 
        this.net_api({ action: 'projectIndexRefresh', data: { letters: ""} }, function(data){ 
            self.companies = data.data.items.items;
            self.experties = data.data.experties;
        

        } ); 
 
  }
}
</script>

<style lang="scss" scoped>
#company_index_page{
  .col-md-4:nth-child(3n+1){clear: both;}
    .braedcrumbs{
    &{margin: 15px 0;}
    .router-link-active{color: #000;vertical-align: middle;}
    .fa-chevron-left{    font-size: 11px; margin: 0 5px; vertical-align: middle;}
  }
  &{background: #fff;min-height: 100vh;padding-top: 20px;}
    h3{font-size: 35px;margin: 30px 0;}
    .filter{
        .md-field label{left: unset;right: 10px;}
        .md-field.md-theme-default:after{display: none;}
        .md-field input[type="text"]{background: #d8d8d8 !important;height: 40px;}
        .md-field{position: relative;}
        .fa-search{position: absolute;left: 20px;top: 25px;}
        .md-field:nth-child(2){width: 35%; display: inline-block;background: #d8d8d8 !important;}
        .md-field:nth-child(3){width: 35%;display: inline-block;background: #d8d8d8 !important;margin: 0 20px;}
    }
    .letters {
      &{margin-bottom: 20px;}
        a { text-align: center;line-height: 50px;display: inline-block;color: #666666;font-size: 23px;height:50px;width: 35px;;
                    &.active { font-weight: bold;color: #000;position: relative; }
                    &.active::after{content: "";position: absolute;width: 35px;height: 2px;background: #448aff;bottom: 0px;right: 0;;}
        }
    }
    .companies_wrapper{
          .col-md-4:nth-child(3n+1) { clear: both;}

      .company{
        .pic_default{box-shadow: none;;height: 82px;width: 100%;border: none;border-radius: 0;background-image: url(/img/project_default.71f03bea.png);background-size: contain;background-repeat: no-repeat;margin: 0 auto;background-position: center;}

        .pic{    width: 185px;height: 60px;margin: 0 auto;background-size: contain;box-shadow: none;background-repeat: no-repeat;margin: 0 auto;background-position: center;}
        &{ border: 1px solid #a5a5a5;background: #fff;padding: 30px 23px;text-align: center;margin-bottom: 30px;border-radius: 5px;}
        .name{color: #212731;font-size: 23px;font-weight: 600;margin-top: 16px;;max-height: 40px;overflow: hidden;}
        .title{    line-height: 35px;min-height: 30px;max-height: 30px;overflow: hidden;}
        a{    color: #30abb7; text-decoration: underline; font-size: 18px; font-weight: 600;}
      }
    }
  .col-md-4{padding-left: 15px;padding-right: 15px;}
}
</style>