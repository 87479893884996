<template>
  <div id="login_page">
    <md-dialog-alert
      :md-active.sync="show_error"
      md-content="הזנת פרטים שגויים, אנא הרשם או שחזר סיסמה."
      md-confirm-text="OK" />

        <div id="form_wrapper" md-theme="default-dark" v-if="!password_reset">
            <div class="top">
            <a class="close" @click="$emit('closePopup')">X</a>
          <h3> כניסה לחשבון קיים </h3> 
          <span >משתמש חדש?<a @click="$emit('goToSignup')">הצטרפו עכשיו</a></span>
          </div>
          <div class="content">
          <span class="title">התחבר באמצעות</span>
           <a class="btn" :href="($store.inapp ? '/#loginFB' : 'https://admin.community.nadlancenter.co.il/cfacebook/login')"> <span class="fb_btn" id="fb_btn" @logged="fb_logged" ><img src="https://img.icons8.com/color/48/000000/facebook-new.png" class="google_img"/></span></a>
           <a class="btn" href="https://admin.community.nadlancenter.co.il/cgoogle/login"> <span class="fb_btn" id="fb_btn" @logged="fb_logged" ><img src="https://img.icons8.com/fluent/48/000000/google-logo.png" class="google_img"/></span></a>
           <span class="title">או באמצעות</span>
           <div v-if="is_loading">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-if="!is_loading">
            <div v-if="!show_step_b">
                <form @submit.prevent="login()">
                    <md-field>
                        <label>אימייל</label>
                        <md-input v-model="username" required=""></md-input>
                    </md-field>

                    <md-field>
                        <label>סיסמא</label>
                        <md-input type="password" v-model="password" required=""></md-input>
                    </md-field>
                    <div style="text-align:left;" @click="password_reset=true"><a>שכחתי סיסמא</a></div>
                  <!--  <md-checkbox v-model="remember_me" value="1" class="md-primary">זכור אותי</md-checkbox>-->


                <div style="margin-bottom:15px;"><input type="submit" value="התחבר" class="btn"></div>
                



                </form>
            </div>
            
            </div>
            </div>

        </div>
          <div id="form_wrapper" md-theme="default-dark" v-if="password_reset" class="password_reset">
            <div class="top">
                          <a class="close" @click="$emit('closePopup')">X</a>

          <h3> איפוס סיסמא</h3> 
          <span >הזן את המייל לקבלת הוראות לשחזור סיסמא </span>
          </div>
          <div class="content">
                         <h3 v-if="password_send" style="padding-top:30px">סיסמתך נשלחה בהצלחה למייל!</h3>

            <div v-if="!is_loading">
            <div v-if="!show_step_b">
                <form @submit.prevent="reset_pass()">
                    <md-field>
                        <label>כתובת מייל</label>
                        <md-input v-model="username" required=""></md-input>
                    </md-field>

                <div style="margin-bottom:15px;"><input type="submit" value="אפס סיסמא" class="btn"></div>
                



                </form>
            </div>
            
            </div>
            </div>

        </div>
 


</div>
      

</template>


<script>


export default {
  data(){
      return {

      FB: {},
      model: {},
      scope: {},
        password_reset:false,
        token: '',
        password_send:false,
        show_step_b: false,
        is_loading: false,
        username: '',
        password: '',
        remember_me: false,
        show_error: false
      }
  },
  created(){
      
    
  },
  methods:{
      handleSdkInit({ FB, scope }) {
        this.FB = FB
        this.scope = scope
console.log(FB)
console.log(scope)
console.log(this.model)

      },
    login()
    {
      var self = this;
      self.is_loading = true;


    const formData = { 
    password: this.password,
    username: this.username 
    };

    this.net_api({ 'action': 'login', data: formData}, function(data){
       
        if (self.isNull(data) || self.isNull(data.data))
        {
            self.is_loading = false;
            self.show_error = true;
            //alert('Wrong details. Please try again.');
        }
        else
        {
            localStorage.setItem('user_token', data.data);
            document.location = document.location.href;

            //alert(data.jwt)
            //captainnemo
        }
        
    });

    },
    reset_pass()
    {
      var self = this;
      self.is_loading = true;


    this.net_api({ 'action': 'reset_password', data: {username:this.username}}, function(){
       
        
        
    });
    this.password_send = true
    },

    login_fb(){
/*
      var nw=window.open('https://admin.community.nadlancenter.co.il/cfacebook/login', 'loginFB', 'left=100,top=100,width=1000,height=700,toolbar=no,menubar=no,status=no,scrollbars=no,resizable=no');

      nw.focus();

      window.loginEl = this;
      
      nw.onbeforeunload = function(event){
        var message = 'Important: Please click on \'Save\' button to leave this page.';
        if (typeof event == 'undefined') {
            event = nw.event;
        }
        if (event) {
            event.returnValue = message;
        }
        return message;
      }
      */
    },
     login_g(){

      var nw=window.open('https://admin.community.nadlancenter.co.il/cgoogle/login', 'loginFB', 'left=100,top=100,width=1000,height=700,toolbar=no,menubar=no,status=no,scrollbars=no,resizable=no');

      nw.focus();

      window.loginEl = this;
      
      nw.onbeforeunload = function(event){
        var message = 'Important: Please click on \'Save\' button to leave this page.';
        if (typeof event == 'undefined') {
            event = nw.event;
        }
        if (event) {
            event.returnValue = message;
        }
        return message;
      }
    },
    fb_logged(token){
      localStorage.setItem('user_token', token);
      document.location = document.location.href;
    }

  }
}
</script>

<style scoped>
.close{    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #fff !important;
    font-family: cursive;}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
.md-field input { color: #333 !important; -webkit-text-fill-color: #333;}

/*.fb_btn {
    text-align: center;
    width: 100%;
    height: 48px;
    line-height: 48px;

    display: inline-block;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
    padding-left: 41px;
    margin-bottom: 15px;
}*/

</style>