<template>
  <div id="profile_page" >
    <div class="centered" v-if="loaded">
      <div class="braedcrumbs">
        <router-link to="/">ראשי</router-link><i class="fas fa-chevron-left"></i> <router-link to="/profile_index">חברי קהילה </router-link><i class="fas fa-chevron-left"></i><span v-text="profile_data.display_name"></span>

      </div> 

        <EditProfile :profile_data="profile_data" ref="editProfileComp"></EditProfile>

        <div class="box">
          <div class="info">
            <div class="user_pic">
            <UserIcon size="big" :user_data="profile_data" :clickable="false"/>
            </div>
            <div class="user_info">
              <div class="title" v-if="profile_data.nadlan_specieal == 1"><i class="far fa-star"></i>  {{ profile_data.specialty }}</div>
              <h1 v-text="profile_data.display_name"></h1>
              <h2 v-text="(profile_data.company_name ? profile_data.job + ' ב' + profile_data.company_name : profile_data.job)"></h2>
              <span class="num_friends" v-if="colleagues.length > 0">{{colleagues.length}} חברים</span>
              <span class="num_points" v-if="me.id == profile_data.id">  <a @click="open_edit()">ערוך את הפרופיל <i style="color:#30abb7" class="fas fa-pencil-alt"></i></a> </span>
                   

            </div>
             <div class="user_logo">
            <UserLogo size="big" :user_data="profile_data" v-if="profile_data.company > 0"  :company_id="profile_data.company"/>
            </div>
          </div>
          
            <ul class="profile_props">
                <li v-if="profile_data.experties_parse != ''"><label class="lbl">תחומי התמחות:</label><div class="prop_content">
                  <router-link class="specialization_tag" v-for="exp in profile_data.experties_parse.slice(0 , 4)" :key="exp.id" v-text="exp.name" :to="'/profile_index?experties=' + exp.id"></router-link>
                  </div>
                  </li>
                    <li v-if="profile_data.reputation > 100"><label class="lbl">מוניטין קהילתי:</label><div class="prop_content">
                      <p v-text="profile_data.reputation"></p>
                  </div>
                  </li>
                <li v-if="profile_data.web_site"><label class="lbl">אתר אינטרנט:</label><div class="prop_content">
                    <a :href="profile_data.web_site" target="_blank" class="link">{{ profile_data.web_site }}</a>
                  </div></li>
                <li v-if="profile_data.address"><label class="lbl">כתובת:</label><div class="prop_content">
                  <div class="address" v-text="profile_data.address">
                  </div>
                  <div class="social_media">
                     <a :href="profile_data.facebook_link" target="_blank"  v-if="profile_data.facebook_link"> <div class="facebook" ></div></a>
                     <a :href="profile_data.instagram_link" target="_blank"  v-if="profile_data.instagram_link"> <div class="instagram" ></div></a>
                
                    </div>
                  </div></li>
                <li v-if="profile_data.phone && profile_data.display_phone == '1'"><label class="lbl">טלפון:</label><div class="prop_content"><div class="phone" >
                  <a :href="`tel:`+ profile_data.phone">{{ profile_data.phone }}</a>
                </div></div></li>
            </ul>
            <div class="options">
              <a class="btn profile_edit message" @click="open_chat(profile_data.id)" v-if="$store.user.id != profile_data.id && profile_data.is_senior == '0'"><i class="far fa-comments" ></i> הודעה</a>
               <div class="btn join"  @click="follow(profile_data.id) ; profile_data.is_followed = true" v-if="!profile_data.is_followed && profile_data.id != user_data.id" ><i class="fas fa-user-tag"  ></i>  הוסף כקולגה</div>
               <div class="btn join"  @click="unfollow(profile_data.id); profile_data.is_followed = false" v-if="profile_data.is_followed && profile_data.id != user_data.id" ><i class="fas fa-user-tag"  ></i> הורד </div>
<!--
                <div class="btn join"  @click="college(profile_data.id) ; profile_data.is_college = true" v-if="!profile_data.is_college && profile_data.id != user_data.id" ><i class="fas fa-user-tag"  ></i> הוסף קולגה</div>
               <div class="btn join"  @click="uncollege(profile_data.id); profile_data.is_college = false" v-if="profile_data.is_college && profile_data.id != user_data.id" ><i class="fas fa-user-tag"  ></i> הורד קולגה</div>
             -->
              <router-link to="/"> <div class="btn new_post"  v-if="user_data.id == profile_data.id"><i class="far fa-clipboard"></i> העלה פוסט</div></router-link>
              <!--<div class="btn profile_edit"  v-if="user_data.id == profile_data.id">ערוך פרופיל <i class="far fa-comments"></i></div>-->
            </div>

        
            <div class="about" :key="profile_data.is_read_more" v-if="profile_data.about">
                <h3>קצת על עצמי</h3>
                <div v-if="profile_data.about.length >= 400">
                  <div class="content" v-html="(profile_data.is_read_more ? profile_data.about : profile_data.about.substring(0,400))"></div>
                  <a v-if="!profile_data.is_read_more && profile_data.about " @click="profile_data.is_read_more=true">קרא עוד </a>
                </div>
                  <div v-if="profile_data.about.length < 400">
                  <div class="content" v-html="profile_data.about"></div>
                </div>
            </div>

        </div>
      <Colleagues :colleagues="colleagues" :user_data="user_data" v-if="colleagues.length > 0"/>
      <Articles :profile_data="profile_data" v-if="profile_data.tags" :tags="profile_data.tags"/>
      
      <Feed :feed_key="'user_'+ profile_data.id" ref="feed" />
    </div>
  </div>
</template>

<script>

import UserIcon from '@/components/user_icon.vue';
import Colleagues from '@/components/profile/Colleagues.vue';
import UserLogo from '@/components/user_logo.vue';
import EditProfile from '@/components/profile_edit.vue';



// @ is an alias to /src

// @ is an alias to /src
import Feed from '@/components/feed.vue'
import Articles from '../components/articles.vue';

export default {
  props: ["user_data","force_edit"],
    components: { UserIcon, Feed, UserLogo, Colleagues,Articles, EditProfile },
     metaInfo() { return this.parse_meta(this.profile_data.meta); },
    data(){
        return {
            id: this.$route.params.id,
            link: '/profile/' + this.$route.params.id,
            profile_data: {},
            edit_profile_data: {},
            active_profile:false,
            pic_uploaded:false,
            pic_loaded:false,
            edit_profile:false,
            edit_profile_item:{},
            experties: [],
            new_pic:'',
            
            items: [
          {
            text: 'Admin',
            href: '#'
          },
          {
            text: 'Manage',
            href: '#'
          },
          {
            text: 'Library',
            active: true
          }
        ],
            loaded: false,
            users: [],
            own_profile:false,
            
        }
    },
    computed:{
      
        // is_edit(){
        //  if(self.$route.query.edit)
        // {
        //   return  this.open_edit();
        // }
          
        // },
    },
  methods: {
    open_edit(){
      if(this.id == this.$store.user.id)
      {
          this.$refs.editProfileComp.edit_profile = true;
      }   
    },
     follow(to_id)
        {
            this.net_api({ action: 'follow/add', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id} }, function(){
            });
        },
     unfollow(to_id)
        {
            this.net_api({ action: 'follow/remove', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id} }, function(){
            });
        },
         college(to_id)
        {
            this.net_api({ action: 'college/add', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id} }, function(){
            });
        },
     uncollege(to_id)
        {
            this.net_api({ action: 'college/remove', data:{ item_type: 'post', from_id: this.user_data.id , to_id: to_id} }, function(){
            });
        },
 
        
  },
  mounted(){


      var self = this;
   
      
      // categories 
      this.net_api({ action: 'user/get_profile', data: {update_views:1, id: this.id , user_id: this.user_data.id} }, function(data){ 
    
        data.data.is_read_more = false;
        self.profile_data = data.data;
        self.edit_profile_item = { 
          display_name: data.data.display_name,
          job: data.data.job,
          phone: data.data.phone,
          address: data.data.address,
          about: data.data.about,
          company: data.data.company,
          experties: data.data.experties_parse.map(function(rows){ return rows.id })
         };

        self.loaded = true;
        self.colleagues = data.data.colleagues_list;
        

        self.experties = data.data.experties_parse;

        if(self.$route.query.edit)
        {
          setTimeout(function(){  self.open_edit();}, 150);
        }
        else{
      if(self.$store.user && self.$store.user.id > 0 )
          {
              self.complete_signup(self.$store.user.id);
          }
        }
      } );
      

      this.net_api({ action: 'company/get_profile', data: { id: 4} }, function(){ 
        
        
       // self.colleagues = data.data.colleagues_list;

      } );
     
  }
}
</script>

<style lang="scss" scoped>
#profile_page {
  
  .braedcrumbs{
    &{margin: 15px 0;}
    .router-link-active{color: #000;vertical-align: middle;}
    .fa-chevron-left{    font-size: 11px; margin: 0 5px; vertical-align: middle;}
  }
    .num_friends, .num_points { color:#00aeba; font-size: 20px; }
    .num_points { font-weight: bold; 
    &::before { content: "·"; margin:0 8px; font-size: 30px;}
    }
    .info{
      &{display: flex;    margin: 30px 0 40px;}
      .user_pic {flex: 1;text-align:center;padding-left: 20px; 
        .user_icon { border:4px solid #fff; box-shadow: 0px 0px 30px rgba(0,0,0,0.3); }
      }
      .user_info{
        .title{color: #00aeba;font-size: 20px;line-height: 50px;}
        h1{margin-bottom: 18px;}
        h2{    color: #212731 ;font-size: 27px;line-height: 28px; font-weight: 500; text-align: right;margin-bottom: 5px;}
        &{flex: 4;}
      }
      .user_logo{flex: 2;margin-top: 15px;box-shadow: none;background-size: contain;}
      }
    .profile_props {
        li { display: block; margin-bottom: 10px;
            .lbl { display: inline-block; font-weight: bold; font-size: 22px; margin-left: 20px; }
            .prop_content { 
              &{display: inline-block;  }
               .specialization_tag{height: 40px;border-radius: 20px;width: fit-content;background-color: #8793a6;padding: 0 10px;color: #ffff;line-height: 38px;display: inline-block;margin: 0 5px;}
      .web_link{color: darkgray;font-size: 19px;text-decoration: underline;}
      .web_link a{color: darkgray;font-size: 19px;text-decoration: underline;}
        .address{color: #212731;font-size: 20px;font-weight: 500;display:inline-block;}
        .phone{color: #212731;font-size: 20px;font-weight: 500;}
        .phone a{color: #000;font-size: 22px;}
            }
            .link{color: #adadad;text-decoration: underline;}
         }
    
      .social_media{
        &{vertical-align: middle;display: inline-block;}
        .facebook{background-image: url(../assets/facebook.svg);height: 25px;width: 25px;background-position: center;background-size: cover;display: inline-block;margin: 0 15px 0 5px;}
        .instagram{background-image: url(../assets/instagram.svg);height: 25px;width: 25px;background-position: center;background-size: cover;display: inline-block;}
      }
    }
    .options{
      &{width: 100%;text-align: center;margin: 20px 0;}
      .btn{margin: 0 10px;height: 50px;line-height: 48px;font-size: 17px;padding: 0 50px;background: transparent;color: #000 !important;border-color: #000 !important;border: 1px solid;}
      .new_post{color: #fff !important;background: #30abb7;border-color: #fff !important;}
      svg{margin-left: 5px;}
            .join{color: #fff !important;background: #30abb7;border-color: #fff !important;}
                  // .message{color: #fff !important;border-color:#fff !important ;}


    }
    .about { background: #f5f5f5; margin-left: -25px; margin-right: -25px; margin-bottom: -25px; padding: 50px; text-align: center; border-bottom: 14px solid #00aeba;
        h3 { color:#00aeba; margin-bottom: 10px;}
        .content{font-size: 20px;font-weight: 500;line-height: 30px;}
              .content ::v-deep {line-height: 30px !important;}
    
     }
}
@media only screen and (max-width: 600px) {
  #profile_page{
    .info{
      &{display: block !important;margin: 0 0 20px !important;}
      .user_pic{display: inline-block; }
      .user_info{
        &{display: inline-block;}
        .title{font-size: 16px;line-height: 30px;}
        h1{font-size: 25px;margin-bottom: 5px;}
        h2{font-size: 22px;}
        .num_friends{font-size: 14px;}
        .num_points{font-size: 14px;}
        .num_points::before{font-size: 15px;}
      }
      .user_logo.size_big{background-size: contain;width: 200px ;margin: 0 auto;height: 75px;}
    }
    .about{padding: 5px;}
     .profile_props li .prop_content .specialization_tag{margin: 5px 5px;padding: 0 10px;font-size: 14px;height: 35px;}
  }
       

}
</style>