<template>
<div>
    <div class="title_wrapper">
       <h3>   עובדי החברה</h3>
        </div>
        <div class="employees_wrapper">
            <div class="col-md-4"  v-for="employee in colleagues" :key="employee.id">
                         <div class="employee " >
                         <router-link :to="'/profile/' + employee.id">
                           <UserIcon size="big" :user_data="employee" :clickable="false" />
                           <h4 class="name" v-text="employee.display_name">אמיתי ואך</h4>
                          <p class="job" v-text="employee.job">מתכנת</p>
                         </router-link>
                         <div class="follow" @click="follow(employee.id) ; employee.is_followed = true" v-if="!employee.is_followed && employee.id != user_data.id " :key="employee.is_followed"><i class="far fa-eye"></i> הוסף כקולגה</div>
                         <div class="follow"  @click="unfollow(employee.id); employee.is_followed = false" v-if="employee.is_followed && employee.id != user_data.id" ><i class="fas fa-eye-slash"></i>הורד </div>
                         </div>
                  </div>
                  <div class="clr"></div>
        </div>
</div>
</template>

<script>

import UserIcon from '@/components/user_icon.vue';

export default {
    components: { UserIcon },
    props: ["colleagues"],
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            loaded: false
        }
    },
    methods:{
         follow(to_id)
        {
            this.net_api({ action: 'follow/add', data:{ item_type: 'post', to_id: to_id} }, function(){
            });
        },
     unfollow(to_id)
        {
            this.net_api({ action: 'follow/remove', data:{ item_type: 'post', from_id: this.$store.user.id , to_id: to_id} }, function(){
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.employees_wrapper{
    &{margin-right: -15px;margin-left: -15px;}
    .employee{
        .user_logo{margin: 0 auto;box-shadow: none;}
        .user_icon{box-shadow: none;margin: 0 auto;;}
    &{    background: #fff;margin: 20px 1.5%;    padding: 15px 0 0;text-align: right;}
    .user_icon.size_mid{margin: 0 auto;;}
    .name{font-size: 23px;margin-top: 17px;color: #30abb7;font-weight: 600;padding-right: 20px;min-height: 20px;max-height: 20px;overflow: hidden;}
    .job{font-size: 20px;color: #212731;margin-bottom: 10px;padding-right: 20px;min-height: 20px;max-height: 20px;overflow: hidden;}
    .follow{width: 100%; height: 45px; background: #d2d7df; line-height: 45px; font-size: 20px; margin-top: 20px;text-align: center;cursor: pointer;}
    }
}
@media only screen and (max-width: 600px) {
    .employees_wrapper{
        .col-md-4{width: 50%;display:inline-block;}
    }
}
</style>