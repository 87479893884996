<template>
<div>
       <h3> חברי קהילה מובילים שאתה עשוי להכיר</h3>
        <div class="acquaintances_wrapper">                 
                      <div class="col-md-6" v-for="item in PeopleYoMightKnow" :key="item.id">
                         <div class="acquaintances " >
                         <router-link :to="'/profile/' + item.id">
                           <UserIcon size="big" :user_data="item" :clickable="false"/>
                           <h4 class="name" v-text="item.display_name"></h4>
                          <p class="job" v-text="item.job"></p>
                         </router-link>
                         </div>
                    </div>
                 <div class="clr"></div>
        </div>
</div>
</template>

<script>

import UserIcon from '@/components/user_icon.vue';

export default {
       

    components: { UserIcon },
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            loaded: false,
             PeopleYoMightKnow:[],
            users: [],
        }
    },
 mounted(){

    var self = this;

    if (!this.isNull(self.$store.users_home))
    {
        self.PeopleYoMightKnow = self.$store.users_home;
        self.loaded = true;
    }
    else
    {
        this.net_api({ action: 'users_home', data: {} }, function(data){ 

            self.PeopleYoMightKnow =  data.data;

            self.loaded = true;
        } );
    }

    },
}
</script>
<style lang="scss" scoped>
h3{    font-size: 22px; width: 60%; line-height: 23px; margin: 0 auto; color: #212731; font-weight: 600;position: relative;}
h3::after{ position: absolute ;content: '';bottom: -10px;width: 50px;height: 3px;background: #30abb7;left: 0;right: 0 ;margin: 0 auto;opacity: 0/9;}
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.acquaintances_wrapper{
    .col-md-6:nth-child(2n+1) { clear: both;}
    .acquaintances{
        .user_logo{margin: 0 auto;box-shadow: none;height: 40px;}
        .user_icon{    box-shadow: none;margin: 0 auto;height: 120px;width: 120px;}
    &{    background: #fff;margin: 20px 0;    padding: 15px 0 0;text-align: center;}
    .user_icon.size_mid{margin: 0 auto;;}
    .name{font-size: 20px; margin-top: 6px; color: #212731; font-weight: 600;}
    .job{font-size: 20px;color: #30abb7;margin-top: 4px;}
    .follow{width: 100%; height: 45px; background: #d2d7df; line-height: 45px; font-size: 20px; margin-top: 20px;}
    }
}
</style>