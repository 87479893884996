<template>
<div v-if="articles.length > 0">
    <div class="title_wrapper">
        <div class="col-md-8">
       <h3 v-if="name"> כתבות אחרונות על {{name}}</h3>
       <h3 v-if="!name" class="user_articles"> כתבות אחרונות</h3>
       </div>
               <div class="col-md-4">
            <a :href="'https://www.nadlancenter.co.il/tags/' + name" target="_blank" v-if="name">לכל הכתבות<i class="fas fa-arrow-left"></i></a>
               </div>
               <div class="clr"></div>
       
        </div>
    <div class="articles_wrapper">
             <div class="article " v-for="article in articles" :key="article.id">
                  <a :href="article.link" target="_blank" >   
                     <div class="article_pic" :style="'background-image:url(' + article.parsed_pic + ')'"> 
                    <!--<div class="article-description"></div>-->
                    </div>
                    <div class="article_content">
                        <h4 class="title" v-text="article.title">ביממה אחת: 13-15 מכרזי מגורים בפריפריה נסגרו ללא זוכה</h4>
                        <p class="content" v-text="article.short_text"></p>
                        <span>{{ article.writer_data.name }} |{{ article.date_parsed }}</span>
                    </div>
                    </a> 
                 </div>
                 
        </div>
    
</div>
</template>

<script>


export default {
    components: {  },
    props:["profile_data", "tags" , "name"],
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            articles:[],
            loaded: false
        }
    },
  methods: {

  }, 
  mounted(){
      
      var self = this;
      
      // categories 
      this.net_api({ action: 'newest_articles', data: { id: this.id, tags: this.tags,tag:0 } }, function(data){ 
        
        data.data.is_read_more = false;
        self.articles = data.data;
     

      } );
  }
}
</script>
<style lang="scss" scoped>
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 25px; font-weight: 500;line-height: 35px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}

.articles_wrapper{
    &{margin-right: -15px;margin-left: -15px;}
    .article{
        .article_pic{min-width: 210px;display: inline-block;height: 100%;    width: 23%;vertical-align: top;    background-size: cover;background-position: center center;position: relative;}
        .article_content{
           &{display: inline-block;width: 70%;    height: 100%;padding-right: 25px;padding-top: 10px;}
           .title{    color: #212731;font-size: 22px;font-weight: 600; line-height: 28px; margin-bottom: 5px; max-height: 60px; overflow: hidden;}
           .content{    
    max-height: 60px;
    min-height: 60px;
    overflow: hidden;
    width: 90%;
    margin-bottom: 0;
    font-family: 'arial';
  
    font-size: 18px;
    color: #505050;
    margin-top: 0;
    line-height: 20px;}
           span{color: #30abb7; font-size: 16px;}
    }
        .user_icon{  box-shadow: none; height: 78%; border-radius: 0;width: 100%;}
    &{       margin: 30px 1.5%;  padding: 0 0 0; height: 160px; overflow: hidden; background: #fff; box-shadow: 0 18px 40px 0 #e7eaf0; border-radius: 6px;}
    //.article-description{height: 22%;text-align: center;line-height: 36px;color: #fff;background: #212731;position: absolute;width: 100%;bottom: 0;}
    .user_icon.size_mid{margin: 0 auto;;}
    .name{font-size: 23px;margin-top: 25px;color: #30abb7;font-weight: 600;}
    .job{font-size: 20px;color: #212731;padding-bottom: 25px;}
    .follow{width: 100%; height: 45px; background: #d2d7df; line-height: 45px; font-size: 20px; margin-top: 20px;}
    }
}
@media only screen and (max-width: 600px) {

    .articles_wrapper .article{ 
        &{margin: 10px 1.5%;}
        .article_pic{min-width: 40%}
        .article_content{width: 60%;    padding-left: 5px;}
        .article_content{
            &{padding-left: 10px;}
            .title{font-size: 16px;line-height:20px;margin-bottom: 0;}
            .content{font-size: 15px;}
        }
    }
    .col-md-4 a{  position: absolute; top: -11px; left: 10px;}
}
    .user_articles{font-size: 35px !important;line-height: 70px !important;}

</style>