<template>
<div>
   <transition name="slide">

   <div class="comments" v-if="loaded">
        <div class="comment_list">
            
            <div class="comment" v-for="comment in comments" :key="comment.id">
                 <div class="editbtn">

                <DropMenu v-if="comment.user_data.id == get_me().id">
                    <template  v-slot:btn>...</template>
                    <template v-slot:menu>
                        <a class="dropdown-item"  @click="edit_comment = true; edit_comment_item = comment">עריכה</a>
                        <a class="dropdown-item" @click="onConfirm_comment_delete(comment.id)">מחיקה</a>
                    </template>
                </DropMenu>
                </div>
                <span class="user_name" v-text="comment.user_data.display_name"></span>
                <span class="user_title" v-text="(comment.user_data.company_name ? comment.user_data.job + ' ב-' + comment.user_data.company_name : comment.user_data.job)"></span>
                <span class="post_date" v-text="timeago(comment.date_created)"></span>

                 <UserIcon size="sml" :user_data="comment.user_data" class="user_pic"/>
                <div class="content">
                    <span v-html="comment.content"></span>
                </div>
                 <a :key="comment.is_like" class="soci_btn" v-if="comment.is_like" @click="unlike(comment)" ><i  style="color:#de0000" class="fas fa-heart"></i><span v-text="(comment.num_likes > 0 ?' '+ comment.num_likes : ' לייק ')"></span> </a>
                <a :key="comment.is_like" class="soci_btn" v-if="!comment.is_like" @click="like(comment)"><i class="far fa-heart" > </i><span v-text="(comment.num_likes > 0 ? ' ' + comment.num_likes : ' לייק ')"></span> </a>
       
            </div>
        </div>
        <div class="add_comment">
            <UserIcon size="sml" :user_data="$store.user" />
            
            
               <Mentionable :keys="['@']" :items="items" @open="loadIssues()" @search="loadIssues($event)"   @apply="blat">
                  <textarea ref="textarea" placeholder="הוסף תגובה..." @input="text_check()" @keyup="checkenter" @tagAdded="blat" v-model="content"></textarea>
                    <template #no-result>  {{ loading ? 'טוען...' : 'אין תוצאות' }}</template>
                </Mentionable>
<a class="sendBtn" @click="save()">שלח</a>
          <div class="emojis_btn"><EmojiIcon :on-emoji-picked="_handleEmojiPicked"  /></div>

        </div>
    </div>
   </transition>
     <md-dialog :md-active.sync="edit_comment" :key="edit_comment_item.id" class="post_edit">
                        <md-dialog-title> ערוך את התגובה <div class="close" @click="edit_comment = false">X</div></md-dialog-title>
                            <div class="inputs">
                            <UserIcon size="mid" :user_data="$store.user"/>
                            <h4>{{$store.user.display_name}}</h4>
                            <div class="col-md-12" >
                                <md-field>
                                <md-textarea v-model="edit_comment_item.content"></md-textarea >
                            </md-field>
                            </div>
                        </div>
                        <md-dialog-actions>
                            <md-button class="md-primary" @click="edit_comment = false;onConfirm_comment_edit()">שמירה</md-button>
                        </md-dialog-actions>
    </md-dialog>
</div>
</template>
        
        
<script>
import Mentionable from '@/components/mention/Mentionable.vue'

import DropMenu from './dropMenu.vue';
import EmojiIcon from './vue-beautifull-chat/icons/EmojiIcon.vue'
import UserIcon from './user_icon.vue';
export default {
    props: ["post_id", "initial_comments"],
    components: { UserIcon, EmojiIcon ,DropMenu,Mentionable},
    data(){
        return {
            edit_comment: false,
            loaded: false,
            content: '',
            comments: [],
              items: [],
              mentions:[],
             loading: false,
            edit_comment_item:[]
        }
    },
    methods:{
        blat(val){
            this.mentions.push({ id: val.id, name:val.label });
        },
           loadIssues (searchText = null) {
            this.loading = true
            this.items = this.fetchIssues(searchText)
            this.loading = false
            },
            fetchIssues(searchText){
                let array = []
                this.net_api({ action: 'check_tag_user', data:{text:searchText} }, function(data){
                    
                    for(let i in  data.data)
                    {
                        array.push({label:  data.data[i].display_name,id: data.data[i].id,pic:data.data[i].pic_parsed,value:  data.data[i].display_name.replace(" ", "_")}) 
                        // array.push({label:  data.data[i].display_name,value:  data.data[i].display_name.replace(" ", "_")}) 
                    }
                });
                return  (array)
            
            },
        like(comment)
        {
             comment.num_likes = ++comment.num_likes
                             comment.is_like = true;

            this.net_api({ action: 'likes/add', data:{ item_type: 'comment', item_id: comment.id } }, function(){

                comment.is_like = true;
            });
        },
          onConfirm_comment_edit () {
                      var self = this;

   
                   this.$confirm("האם אתה בטוח שאתה רוצה לשנות את התגובה?", "" , "", { confirmButtonText: 'אישור',cancelButtonText: 'ביטול'}).then(() => {
                    this.net_api({ action: 'edit_comment', data: { item_id:this.edit_comment_item.id, content: this.edit_comment_item.content ,} }, function(){ 
                   self.alert("התגובה שונתה בהצלחה");
                    } );
                    }); 
      },
       onConfirm_comment_delete (delete_id) {
        var self = this;
          this.$confirm("האם אתה בטוח שאתה רוצה למחוק את התגובה?", "" , "", { confirmButtonText: 'אישור',cancelButtonText: 'ביטול'}).then(() => {
        this.net_api({ action: 'delete_comment', data: { item_id:delete_id,post_id:this.post_id } }, function(){ 
            //self.posts.splice
            self.get_item_by_id(delete_id).is_deleted = true;

            // for (let i in self.comments)
            // {
            //     if (self.comments[i].id == delete_id)
            //     {
            //         self.comments.splice(i, 1);
            //         break;
            //     }
            // }

            self.alert("התגובה נמחקה בהצלחה");
        } );
        });
      },
        get_item_by_id(id) {

            for (let i in this.items)
            {
                if (this.comments[i].id == id) return this.comments[i];
            }

            return {};

        },
        unlike(comment)
        {
             comment.num_likes = --comment.num_likes
                             comment.is_like = false;

            this.net_api({ action: 'likes/remove', data:{ item_type: 'comment', item_id: comment.id } }, function(){

                comment.is_like = false;
            });
        },
      
        _handleEmojiPicked(emoji) { 
            //var textarea = this.$refs.textarea;
            //textarea.value = textarea.value + emoji;
            this.content = this.content + emoji;
        /*
      this._checkSubmitSuccess(
        this.onSubmit({
          author: 'me',
          type: 'emoji',
          data: {emoji}
        })
      )*/
    },

        text_check()
        {
            /*
            var textarea = this.$refs.textarea;
            
            //var urlRegex = /(https?:\/\/[^\s]+)/g;
            var urls = textarea.value.match(/(https?:[^\s]+)/);

//console.log(urls)

            if (!this.isNull(urls))
            {
                this.post_url = urls[0];
            }
            else
            {
                this.post_url = '';
            }
            */
        },

        checkenter(e){
            if (e.keyCode === 13) {
                
                this.save();
            }   
        },
        
        save(){
        
            var self = this;
            var data = {};
            data.content = this.content;
            data.post_id = this.post_id;
            data.mentions = this.mentions;

            this.content = '';
            this.text_check();

            this.net_api({ action: 'comment/add', data:data }, function(data){
                
               // self.
                self.net_api({ action: 'comment/get', data:{ id: data.data, post_id: self.post_id  } }, function(){

                    // self.comments.push(data.data);
                });
                    


            });
        }

    },

    mounted(){
        
        var self = this;
        this.net_api({ action: 'comment/results', data:{ post_id: this.post_id } }, function(data){

              for (let i in data.data.items)
            { 
              data.data.items[i].is_deleted = false;
            }
            self.comments = data.data.items;

            self.loaded = true;
        });
            
    }
}
</script>

<style lang="scss" scoped>
.editbtn{position: absolute;top: 4px;left: 15px;}
.comments {
    .add_comment { background: #f5f5f5; min-height: 66px; position: relative;
        textarea { width:100% !important; overflow: hidden; padding: 10px 80px 10px 95px; border:none; outline: none;  font-size: 17px; line-height: 1.8; background: none;; height: 50px;; }
        .user_icon  { position: absolute; right: 5px; top: 5px;}
        .emojis_btn { left: 10px; top: 20px; position: absolute; }
        .sendBtn { position: absolute; left: 50px; top: 15px; background: #1e2732; color: #fff !important; border-radius: 3px; padding: 4px 10px; font-size: 13px;}
    }

    .comment_list {
        & { padding-right: 100px; padding-left: 50px;}
        .comment { 
            & { position: relative; background: #f5f5f5; padding: 15px; margin-bottom: 30px; border-radius: 5px; }
            .user_pic { position: absolute; top: 0px; right: -60px; width: 50px; height: 50px; border-radius: 100%; background:#eee center center; background-size: cover; }
            .user_name { display: block; font-size: 18px; font-weight: bold; }
            .user_title { display: inline-block; font-size: 17px; font-weight: normal; margin-bottom: 10px; color: #000; margin-left: 10px ;
                // &::after { content: "·"; margin-right: 10px; } 
            }

            .content { white-space: pre-wrap; margin-bottom: 15px; font-size: 20px;line-height: 26px;}
            .like { font-size: 15px;}
            .post_date { color: #7f7f7f; font-size: 18px; font-weight: normal; display: inline-block; position: absolute;  top: 15px;left: 55px;}
        }
    }
}
@media only screen and (max-width: 600px) {
.comments .comment_list{padding-left: 15px;padding-right: 53px;}
.comments .add_comment{min-height: 50px;}
.user_icon.size_sml{width: 40px;height: 40px;}
.comments .comment_list .comment{padding: 10px;margin-bottom: 7px;}
.comments .comment_list .comment .user_icon.size_sml{height: 40px;width: 40px;right: -50px;}
.comments .comment_list .comment .content{font-size: 18px;}
.comments .comment_list .comment .user_name{font-size: 18px;}
.comments .comment_list .comment .user_title{font-size: 14px;margin-bottom: 5px;}
}
</style>