<template>
    <div id="courses_page">
        <div id="hero" :style="'background-image:url('+info.pic_parsed+')'">
            <p v-html="info.course_subject"> </p>
            <h3 v-text="info.name"></h3>
            <p v-text="info.short_text"></p>
        </div>
        <div id="about_course">
            <div  class="centered_courses"> 
                          <h3 v-html="info.what_learn"></h3>
            <p v-html="info.about"></p>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_a != '' ? 'background-image:url('+info.icon_a_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_a">תעודה הסמכה</span>
                    <label v-text="info.pros_a"></label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_b > 0 ? 'background-image:url('+info.icon_b_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_b">תעודה הסמכה</span>
                    <label v-text="info.pros_b"></label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_c > 0 ? 'background-image:url('+info.icon_c_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_c">תעודה הסמכה</span>
                    <label v-text="info.pros_c"> </label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_d > 0 ? 'background-image:url('+info.icon_d_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_d">תעודה הסמכה</span>
                    <label v-text="info.pros_d"></label>
                </div>
            </div>
            <div class="clr"></div>
        </div>
        </div>
    <div class="centered_courses">
        <Lecturers :writers="writers" :title="info.lec_title"/>
        <CourseSection  v-for="cat in cats" :key="cat.id" :courses="cat.items" :cat="cat"/>

    </div>
    </div>
</template>

<script>
import CourseSection from './course_section.vue';
import Lecturers from './Lecturers.vue';



export default { 
   props: ["user_data"],
    components: {  CourseSection ,Lecturers},

    data(){
        return {
          showDialog:false,
            id: this.$route.params.id,
            profile_data: {},
            experties: [],
            loaded: false,
            lead_name:"",
            lead_phone:"",
            users: [],
            cats: [],
            writers: [],
            courses: [],
            info: []
        }
    },
  methods: {

  },
  mounted(){

      var self = this;
         this.net_api({ action: 'course/get', data: { id: this.id} }, function(data){ 
        
        self.info = data.data;
        self.cats = data.data.series;
        self.writers = data.data.writers;

      

 } );
      // categories 
    

         
  }
    } 
   
</script>
<style lang="scss" >
#courses_page{background: #fff;}
.cat:nth-child(even){
    .col-md-6:nth-child(1){padding-right: 60px;}
    .col-md-6{float: left;}
    .course_pic::after{right: unset !important;left: -30px;}
}
#hero{  
     &{height: 50vh;text-align: center;color: #fff;padding-top: 120px;;background-position: center;background-size: cover;background-repeat: no-repeat;width: 100%;}
     p{font-size: 30px;margin-bottom: 10px;}
     h3{    font-size: 45px; width: 27% ;line-height: 45px; margin: 0 auto; margin-bottom: 10px;}
      }
      #about_course{
          .details{display: inline-block;width: 70%;vertical-align: middle;margin-right: 5px;text-align: right;}
          .details span{display: block;    padding-right: 10px;font-weight: 600;}
          .details label{    font-size: 15px;padding-right: 10px;;}
          .circle{padding: 10px;border: 1px solid;border-radius: 100%;height: 55px;width: 55px;display: inline-block;vertical-align: middle;}
          .icon{background-image: url(../../assets/diploma.png);height: 100%;width: 100%;background-size: contain;background-repeat: no-repeat;}
          &{text-align: center;padding-top: 50px;background: #fff;padding-bottom: 50px;}
          h3{font-size: 30px;margin-bottom: 30px;}
          p{    width: 70%;margin: 0 auto;font-size: 20px;margin-bottom: 30px;}
      }
//#app_left_side{display: none;}
//#app_right_side{display: none;}
.centered_courses{width: 100%;max-width: 1250px;position: relative;margin: 0 auto;}
@media only screen and (max-width: 600px) {
    #hero{padding-top: 40px;}
    #hero h3{width: 100%;}
    #hero p{width: 90%;margin: 0 auto;;}
    #about_course{
        .col-md-3{margin-bottom: 10px;}
    }
}
</style>