<template>
    <div id="inbox_page">
      <div class="centered-big">
     <h2 style="font-size: 30px;text-align: center;padding-top: 150px;" v-if="empty">אין כרגע שיחות פעילות..</h2>
  <div class="md-layout" style="height:calc(100vh - 54px);" v-if="!empty">
  
    <div :class="' threads_wrapper '+ (!side_menu ? 'side_menu_opened_emit' : 'side_menu_closed_emit')"><Threads @empty="empty = true" @open_thread="open_thread" @close_menu="side_menu = false" @side_menu="side_menu= !side_menu"/></div>

    <div class="chat_area_wrapper" id="chat_area_wrapper"><Chat v-if="openChat > 0" :key="openChat" :user_id="openChat" ref="chatbox" /></div>
    <div class="chat_user_details_wrapper" id="chat_user_details_wrapper"><ChatUserDetails v-if="openChat > 0 && !isNull(openChatUser)" :key="openChat" :userData="openChatUser" /></div>
  </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Threads from '@/components/inbox/threads.vue'
import ChatUserDetails from '@/components/inbox/user_details.vue'
import Chat from '@/components/chat.vue'

export default {
    props: ["user_data", 'menu'],
  components: { Threads, Chat, ChatUserDetails },
  data(){
      return {
          openChat: null,
          openChatUser: null,
          empty: false,
          side_menu: false
      }
  },
  methods: {
      open_thread(id, user){
          this.openChat = id;
          this.openChatUser = user;
          //console.log(this.openChatUser)
      },


    parse_user_channel(msg)
    {
      var data = JSON.parse(msg.message);
      //console.log(data);

      switch(data.mtype)
      {
        case 'chat_msg': { 
          let exists = false;
        
        let chatbox = this.$refs.chatbox;
        if (chatbox.user_id == data.data.uid_from)
        {
            chatbox.pushMessage({ author: (data.data.uid_from == this.user_data.id ? 'me' : data.data.uid_from), type: 'text', data: { text: data.data.msg } });
            exists = true;
            break;
        }
        

          if (!exists)
          {
            this.open_chats.push(data.data.uid_from);
          }

          break;
        }
        case 'login': { 
          /*
          localStorage.setItem('user_token', '');
          alert('משתמש אחר התחבר דרך פרטי החשבון שלך. המחשב שלך יתונק כעת מהאתר.');
          document.location = '/';
          */
          break; 
        }
      }
    },

  },
  mounted(){
      
        this.$pnSubscribe({ channels: ['user_cnl_' + this.user_data.id] });
        this.$pnGetMessage('user_cnl_' + this.user_data.id, self.parse_user_channel);
  }
}
</script>

<style lang="scss" scoped>
.md-layout{
  &{display: flex;}
  .threads_wrapper{flex:2;z-index: 9;}
  .chat_area_wrapper{flex:3;max-height: calc(100vh - 60px);overflow: auto;}
  .chat_user_details_wrapper{flex: 1;max-height: calc(100vh - 60px);overflow: auto;}
}

#inbox_page .threads_wrapper { background: #4c4c4c; color:#fff;  &{transition: 3s all ease-in-out} }
#inbox_page #chat_area_wrapper .sc-chat-window { height: calc(100vh - 54px) !important; max-height: 100% !important; position: static; }
#inbox_page .sc-message-list { background: #f0f0f0 !important; } 
#chat_user_details_wrapper { background: #fff;}
.sc-message { width: auto  !important;}
@media only screen and (max-width: 800px) {
  .side_menu_closed_emit{position: fixed;width: 100%;height: 100%;z-index: 9;}
  .chat_user_details_wrapper{display: none;}
  #inbox_page{
  .chat_area_wrapper{overflow: hidden !important;}
    &{max-height: 100vh;overflow: hidden;}
    .centered-big{max-width: 100% !important;}
    #chat_user_details_wrapper{display: none;}
    #chat_user_details_wrapper{min-width: 70%;max-width: 70%;}

    #threads .list li .msg{display: none;}
    .user_icon.size_sml{width: 40px;height: 40px;}
    .md-layout{    max-height: 100vh;    overflow: hidden;position: fixed;width: 100%;top: 50px !important;bottom: 0;overflow: hidden;}
    .md-layout-item.md-size-50{min-width: 70%;}
    #threads{max-height: 100vh;overflow-y: auto;}
  }

}
</style>