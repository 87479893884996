<template>
  <div id="project_profile_page" v-if="loaded">
    <div class="centered">
      <div class="braedcrumbs">
        <router-link to="/">ראשי</router-link><i class="fas fa-chevron-left"></i> <router-link to="/project_index">פרוייקטי נדל"ן</router-link><i class="fas fa-chevron-left"></i><span v-text="profile_data.name  + ', ' + profile_data.city"></span>
      </div>
        <div class="box">
            <div class="hero" :style="'background-image:url(' + profile_data.pic_parsed  +')'"  v-if="profile_data.premium == 1"> </div>
          <div class="info">
            <div class="user_info">
               <span :class="(profile_data.premium != 1 ? 'regular' : '')  ">
                   <div class="user_logo">
                    
            <UserLogo size="big" :user_data="profile_data" :clickable="false"  v-if=" profile_data.logo_parsed" />
            </div>
            </span>
            
              <h1 v-text="profile_data.name  + ', ' + profile_data.city "></h1>
              <h2 v-text="company_data.name"></h2>
              <div class="company_pic">
                <UserLogo size="big" :user_data="company_data" clickable="false"  :company_id="company_data.id"/>
              </div>
              <div class="project_type" v-text="profile_data.project_type_parsed +' |'"></div>
              <div class="applecation" @click="send_lead()" v-text="profile_data.status">בקשה להיתר</div>
            <div class="project_info">
                <div class="col-md-4">
                      <div class="icon_a"></div>
                    <span v-text=" profile_data.buildings_num +' בניינים ' "></span>
                    </div>
                <div class="col-md-4" >
                     <div class="icon_b"></div>
                    <span v-text=" profile_data.floors_num + ' קומות  '"></span>
                </div>
                <div class="col-md-4">
                 <div class="icon_c"></div>
                <span v-text="profile_data.apartment_num + ' דירות  ' "></span>
                </div>
                            <div class="clr"></div>

            </div>
            <div class="advisors">
                <h3>יועצים המלווים את הפרויקט</h3>
                <div class="col-md-4" v-for="advisor in advisors" :key="advisor.id">
                 <router-link :to="'/company/'+ advisor.id"> <div class="pic" :style="'background-image:url('+ advisor.logo_parsed +')'"></div></router-link>
                     <div class="dets">
                    <div class="name" v-text="advisor.name"></div>
                    <div class="title" v-text="advisor.feild_parsed"></div>
                </div>
                    </div> 
            </div>
                        <div class="clr"></div>

            </div>

            
          </div>
           <div id="lead">
              <div>
    <md-dialog :md-active.sync="showDialog">
      <div id="dialog_close" @click="showDialog = false">X</div>
      <md-dialog-title>{{ profile_data.name }}</md-dialog-title>
        <h3>רוצים פרטים נוספים אודות הפרוייקט?</h3>
        <div class="inputs">
        <div class="col-md-6">
            <md-field>
            <label>שם מלא: </label>
            <md-input v-model="lead_name"></md-input>
        </md-field>
        </div>
         <div class="col-md-6" >
            <md-field>
            <label>מספר פלאפון:</label>
            <md-input v-model="lead_phone"></md-input>
        </md-field>
        </div>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="send_lead() ">שלח טופס</md-button>
        <md-button class="md-primary" @click="call()">אפשר גם להתקשר</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-button class="md-primary md-raised popupbtn" @click="showDialog = true"><i class="fas fa-phone"></i>השאר פרטים  </md-button>
  </div>
             <div>
    
  </div></div>
            <div class="about" :key="profile_data.is_read_more" v-if=" profile_data.about ">
                              <h3>אודות הפרויקט</h3>

                 <div v-if="profile_data.about.length >= 400">
                  <div class="content" v-html="(profile_data.is_read_more ? profile_data.about : profile_data.about.substring(0,400))"></div>
                  <a v-if="!profile_data.is_read_more && profile_data.about " @click="profile_data.is_read_more=true">קרא עוד </a>
                </div>
                  <div v-if="profile_data.about.length < 400">
                  <div class="content" v-html="profile_data.about"></div>
                </div>
            </div>
             

        </div>
        <ReviewsStrength :profile_data="profile_data" v-if="profile_data.reviews.length > 0"/>
                  <Specgallery :images="spec" v-if="spec.length > 0 && profile_data.premium == 1"/>
 <div class="fainance" v-if=" profile_data.premium == 1">
      <div class="title_wrapper">
        <h3> היבטים פיננסים</h3>
          </div>
          <div class="box">
                  <div class="col-md-4">
                    <h4>בנק מלווה</h4>
                    <div class="bank_pic" :style="'background-image:url(' + profile_data.bank_parsed + ')'"></div>                    
                  </div>
                  <div class="col-md-8">
                    <div class="text"  v-html="profile_data.bank_text" ></div>
                  </div>
                  <div class="clr"></div>
          </div>
    </div>
      <Simulations :images="simulations" v-if="simulations.length > 0 && profile_data.premium == 1"/>
    <div class="neighborhood" v-if=" profile_data.premium == 1">
        <div class="title_wrapper">
        <h3>  אודות השכונה</h3>
          </div>
          <div class="box">
      <h3 v-html="profile_data.about_neighborhood"></h3>
       <div class="col-md-4">
                    <span v-html="profile_data.neighborhood_a"></span>
                    <div class="icon"> <i class="far fa-building"></i></div>
        </div>
         <div class="col-md-4">
                    <span v-text="profile_data.neighborhood_b"></span>
                     <div class="icon"><i class="far fa-building"></i></div>
        </div>
         <div class="col-md-4">
                    <span v-text="profile_data.neighborhood_c"></span>
                    <div class="icon"><i class="far fa-building"></i></div>
        </div>
        <div class="clr"></div>
    </div>
    </div>
      <Images :images="images"  v-if="images.length > 0 && profile_data.premium == 1"/>
      <Management :management="management"  v-if="management.length > 0"/>
      <Projects :profile_data="company_data.id" :project_id="profile_data.id"  />

      <div class="about_yazam" v-if=" profile_data.premium == 1">
         <div class="title_wrapper">
        <h3>  אודות היזם</h3>
          </div>
        <div class="box">
          <div class="yazam_text" v-html="company_data.description"></div>
          <div class="col-md-4">
            <div class="box">
            <i class="fas fa-key"></i>     
            <p>כמות פרוייקטים:</p>    
             <span v-text="company_data.projects_num"></span>
            </div>
          </div>
           <div class="col-md-4">
            <div class="box">
              <i class="fas fa-hard-hat"></i>
              <p>היקפי פעילות לפי ערים</p>
              <span v-text="company_data.projects_cities"></span>
            </div>
          </div>
           <div class="col-md-4">
            <div class="box">
              <i class="fas fa-hospital-user"></i>
                <p>היקפי פעילות לפי סוגים</p>
              <span v-text="company_data.projects_activity"></span>
            </div>
          </div>
        <div class="clr"></div>
        </div>


      </div>
     
          


    </div>
  </div>
</template>

<script>

import UserLogo from '@/components/user_logo.vue';


// @ is an alias to /src
import Projects from '../components/profile/projects.vue';

import ReviewsStrength from '../components/project/ReviewsStrength.vue';
import Management from '../components/profile/management.vue';
import Simulations from '../components/project/simulations.vue'; 
import Images from '../components/project/Images.vue';
import Specgallery from '../components/project/spec_gallery.vue';

export default { 
    props: ["user_data"],
    components: {   UserLogo,Management ,ReviewsStrength,Simulations,Specgallery,Images,Projects},
    metaInfo() {
      return this.parse_meta(this.profile_data.meta); 
    },
    data(){
        return {
          showDialog:false,
          id: this.$route.params.id,
          profile_data: {},
          experties: [],
          loaded: false,
          lead_name:"",
          lead_phone:"",
          users: [],
          company_data: {}
        }
    },
  methods: {

        send_lead(){
          if (this.lead_name == '' || this.lead_phone == '') 
          {
            alert('יש למלא את כל הפרטים');
            return false;
          }
           this.net_api({ action: 'company_send_lead', data: { lead_name: this.lead_name,lead_phone: this.lead_phone,email: this.company_data.email} }, function(){ 
            } ); 
            this.showDialog = false;

        },
        call(){
          document.location=`tel:`+ this.company_data.phone
        }
  },
  mounted(){


      var self = this;
      
      // categories 
     this.net_api({ action: 'project/get_profile', data: { id: this.id} }, function(data){ 
        
        data.data.is_read_more = false;
        self.profile_data = data.data.item;
        self.company_data = data.data.item.company_data;
        
        self.management = data.data.item.managament;
        self.simulations = data.data.item.simulations_gallery;
        self.spec = data.data.item.spec_gallery;
        self.images = data.data.item.images_gallery;
        self.advisors = data.data.item.advisors;
        
        self.loaded = true;
 } );
    

         
  }
} 
   
</script>

<style lang="scss" >
.icon_a{height: 30px;width: 30px;display: inline-block;background-image: url(../assets/business-and-trade.svg);vertical-align: middle;background-size: contain;background-repeat: no-repeat;margin-left: 10px;}
.icon_b{height: 30px;width: 30px;display: inline-block;background-image: url(../assets/stairs.svg);vertical-align: middle;background-size: contain;background-repeat: no-repeat;margin-left: 10px;}
.icon_c{height: 30px;width: 30px;display: inline-block;background-image: url(../assets/doorway.svg);vertical-align: middle;background-size: contain;background-repeat: no-repeat;margin-left: 10px;}
.popupbtn{position: fixed !important;right: 23%;top: 50vh;height: 83px !important;font-size: 16px !important;width: 75px;border-radius: 100% !important;text-align: center;background: #30abb7!important;z-index: 999;}
.popupbtn .fa-phone{display: block;text-align: center;margin: 0 auto;margin-bottom: 5px;font-size: 23px;}
    .md-dialog-container{    padding: 35px; width: 35vw; min-width: 550px; height: 240px; text-align: center;display: flex;}
    .md-dialog{
      .md-dialog-title{font-size: 17px !important;margin-bottom: 0 !important;}
      h3{margin-bottom: 6px !important;}
      #dialog_close{position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    font-family: cursive;
    color: #30abb7;
    cursor: pointer;
    font-weight: 700;}
    .md-button { font-size: 20px;}
      .md-dialog-actions{padding: 0;}
      .md-dialog .md-button:nth-child(odd) a{color: #fff  !important;}
      .md-title{padding: 0;}
      .col-md-6{
        .md-field{margin: 0;}
        .md-field label{left: unset;right: 10px;color: #000 !important;}
        .md-field.md-theme-default:after{display: none;}
        .md-field .md-input, .md-field .md-textarea{border: 1px solid #ccc;height: 33px;padding-right: 10px;}
        &{direction: rtl;height: 100%;}
        input{text-align: right;}
      }
    .md-dialog-actions{min-height: 75px !important;justify-content:center !important}
    .md-button:nth-child(odd){  width: 180px; 
    height: 38px;
    margin: 0 5px;
    border-radius: 26px;
    background-color: #30abb7;
    color: #fff !important;}
    .md-button:nth-child(even){    width: 180px;
    height: 38px;
    padding: 0;
    border-radius: 26px;
    color: #30abb7 !important;
    border: solid 1px #30abb7;}
    }

#project_profile_page {
  .about_yazam{
    .box{
      .box{border: none;    text-align: center;  font-size: 30px;}
      p{font-size: 20px;margin-bottom: 10px;}
      .fa-hard-hat, .fa-key, .fa-hospital-user{color: #30abb7; font-size: 35px; display: block; text-align: center;margin: 0 auto;margin-bottom: 10px;}
    }
  }
.neighborhood{
    .box{
      &{margin-top: 30px;}
      h3{font-weight: 400;font-size: 18px;;max-height: 100px;overflow: hidden;margin-bottom: 20px;}
      span{margin-left: 5px;}
      .svg-inline--fa{font-size: 20px;;color:#30abb7;}
      .icon{font-size: 0px;;color:#30abb7;height: 40px;width: 40px;background: #fff;box-shadow: 0 0 3px;border-radius: 100%;display: inline-block;line-height: 50px;text-align: center;}
    }
  }
  .fainance{
    .col-md-8{padding-right: 40px;}
    .col-md-8 .text h3{font-size: 23px !important;color: #30abb7 !important;margin-bottom: 5px;position: relative;}
    .col-md-8 .text h3::after{content: "";position: absolute;right: -40px;height: 30px;width: 30px;background-image:  url(../assets/check.svg);background-repeat: no-repeat;}
    .col-md-8 .text p{font-size: 18px !important;color: #000 !important;margin-bottom: 10px;}
    &{padding: 20px 0;}
     .col-md-4{border-left: 1px solid #ccc;}
    .bank_pic{height: 150px;width: 150px;background-size: cover;}
  }
  .title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
    .project_info{
         .icon{background-image: url(../assets/house.svg)}

    }
    .hero{width: 107%;background-position: center;
    background-size: cover;
    height: 370px;;margin-bottom: 20px;;margin-top: -25px;margin-left: -25px;margin-right: -25px;}
   .braedcrumbs{
    &{margin: 15px 0;}
    .router-link-active{color: #000;vertical-align: middle;}
    .fa-chevron-left{    font-size: 11px; margin: 0 5px; vertical-align: middle;}
  }
  .nav-tabs .nav-link.active{    color: #212731; font-weight: 700; font-size: 25px  !important;}
    .num_friends, .num_points { color:#00aeba; font-size: 20px; }
    .num_points { font-weight: bold; 
    &::before { content: "·"; margin:0 8px; font-size: 30px;}
    }
    .info{
        .advisors{
          &{text-align: center;}
            h3{margin-bottom: 25px;text-align: right;}

        .col-md-4{
                &{margin-bottom: 30px;}
                .dets{
                    .name{    color: #00aeba;font-weight: 600;font-size: 17px;}
                    .title{color: #000; font-size: 19px; line-height: 20px;}
                    &{flex: 1;padding-right: 10px;}
                }
                .pic{position: initial;box-shadow: none;height: 80px;width: auto;background-size: contain;background-position: center;margin-bottom: 10px;background-repeat: no-repeat;}
            }
        }
        .project_info .fa-upload{color: #30abb7;margin-right: 5px;font-size: 20px;}
        .project_info .fa-door-open{color: #30abb7;margin-right: 5px;font-size: 20px;}
        .project_info .col-md-4:nth-child(2){border-left: 1px solid #ccc;border-right: 1px solid #ccc;}
        .project_info .fa-building{color: #30abb7;margin-right: 5px;font-size: 20px;}
        .project_info{margin-top: 40px;border-top: 1px solid #ccc;border-bottom: 1px solid #ccc;padding: 20px 0;margin-bottom: 30px;}
        .project_info .col-md-4{text-align: center;font-size: 24px;}
        .project_type{    font-size: 24px;display: inline-block;padding-left: 5px;}
        .applecation{  display: inline-block;font-size: 24px;color: #30abb7;}
        .company_pic{
            .user_logo{left: 0;box-shadow: none;top: 30px;    width: 230px;background-size: cover;height: 60px;}
        }
      &{display: flex;    margin: 30px 0 40px;}
      .user_pic {
          .madad_logo{background-image: url(../assets/madad_logo.png);height: 200px;width: 200px;border-radius: 100%;background-size: cover;}
          .description{    color: #212731;font-size: 20px;font-weight: 600;}
          &{flex: 2;text-align:center;margin-top: -25px;}
        .user_icon {margin: 0 auto;}
      }
      .user_info{
        .title{color: #00aeba;font-size: 20px;line-height: 50px;}
        h1{margin-bottom: 15px;line-height: 35px;font-size: 30px;width: 65%;}
        h2{    color: #212731 ;font-size: 30px; font-weight: 500; text-align: right;margin-bottom: 15px;}
        &{flex: 4;padding-top: 45px;padding-right: 25px;position: relative;}
      }
      .user_logo{flex: 2;position:absolute;top: -40px;}
     .regular .user_logo{flex: 2;position:initial;top: 0px;display: block;margin-bottom: 10px;}
      }
    .profile_props {
        li { display: block; margin-bottom: 10px;
            .lbl { display: inline-block; font-weight: bold; font-size: 22px; margin-left: 20px; }
            .prop_content { 
              &{display: inline-block;  }
               .specialization_tag{height: 40px;border-radius: 20px;width: fit-content;background-color: #8793a6;padding: 0 20px;color: #ffff;line-height: 38px;display: inline-block;margin: 0 5px;}
      .web_link{color: darkgray;font-size: 19px;text-decoration: underline;}
        .address{color: #212731;font-size: 20px;font-weight: 500;display:inline-block;}
        .phone{color: #212731;font-size: 20px;font-weight: 500;}
            }
         }
    }
    .social_media{
      &{vertical-align: middle;display: inline-block;}
      .facebook{background-image: url(../assets/facebook.svg);height: 40px;width: 40px;background-position: center;background-size: cover;display: inline-block;margin: 0 15px 0 5px;}
      .instagram{background-image: url(../assets/instagram.svg);height: 40px;width: 40px;background-position: center;background-size: cover;display: inline-block;}
    }
    .options{
      &{width: 100%;text-align: center;margin: 20px 0;}
      .btn{margin: 0 10px;height: 50px;line-height: 48px;font-size: 17px;padding: 0 50px;background: transparent;color: #000 !important;border-color: #000 !important;border: 1px solid;}
      .join{color: #fff !important;background: #30abb7;border-color: #fff !important;}
    }
    .about { background: #212731;color: #fff; margin-left: -25px; margin-right: -25px; margin-bottom: -25px; padding: 50px; text-align: center;
        h3 {margin-bottom: 10px;color: #30abb7;}   
        .content {    font-size: 20px;font-weight: 500;}
     }
}
@media only screen and (max-width: 600px) {
  #project_profile_page{
    .about div{width: 90%;margin: 0 auto;}
    .box{padding: 0;}
    .advisors .col-md-4 .pic{background-size: contain !important;background-repeat: no-repeat;}
    .hero{max-height: 200px;background-repeat: no-repeat;background-size: contain;margin: 0 ;width: 100%;}
    .project_info{
      .col-md-4{width: 33%;display: inline-block;font-size: 20px;}
      .icon_a , .icon_b , .icon_c{margin-left: 0;display: block !important;margin: 0 auto;margin-bottom: 5px;}
    }
    .info{
      &{display: block !important;margin: 0 0 20px !important;padding: 0 10px;}
      .user_pic{display: inline-block; }
      .user_info{
        .user_logo{top: 0;width: 150px !important;right: 10px;box-shadow: none;background-size: contain;}
                .company_pic .user_logo{left: 10px;right: auto;}

        &{display: inline-block;padding-right: 0;padding-top: 85px;}
        .title{font-size: 16px;line-height: 30px;}
        h1{font-size: 25px;margin-bottom: 5px;}
        h2{font-size: 22px;}
        .num_friends{font-size: 14px;}
        .num_points{font-size: 14px;}
        .num_points::before{font-size: 15px;}
      }
      .user_logo.size_big{background-size: contain;width: 200px ;margin: 0 auto;height: 75px;}
    }
    .about{padding: 5px;}
     .profile_props li .prop_content .specialization_tag{margin: 5px 5px;padding: 0 10px;font-size: 14px;height: 35px;}
     .fainance{
       .col-md-4{width: 100%;border: none;}
       .col-md-8{text-align: right;}
       .col-md-4 h4{text-align: center;margin-bottom: 10px;}
       .bank_pic{width: 100%;background-size: contain;margin: 0 auto;background-position: center;background-repeat: no-repeat;margin-bottom: 20px;}
  }
  .neighborhood{
    .box{padding: 10px !important;}
        .col-md-4{width: 100%;display: inline-block;text-align: center;margin: 0 ;font-size: 20px;margin-bottom: 10px;}

  }
  .yazam_text{text-align: center;}
  }
       

}
</style>