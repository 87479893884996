<template>
  <div id="company_index_page">
  <div class="centered" id="company_index_page">
      <h3>אינדקס קורסים</h3>
      <div class="filter">
          <md-field>
      <label>חפש לפי שם קורס </label>
      <i class="fas fa-search"></i>
      <md-input @keyup="refresh_results()" v-model="search"></md-input>
    </md-field>
  
        
      </div>
      <div class="letters">
          <a v-text="letter" v-for="letter in letters" :key="letter" @click="activeLetter = letter; load_results()" :class="(activeLetter == letter ? 'active' : '')"></a>
      </div>
      <div class="companies_wrapper">
        <div class="col-md-4"  v-for="company in companies" :key="company.name">
        <div class="company" >
              <UserLogo size="mid" :user_data="company" :clickable="false"/>
              <div class="name" v-text="company.name"></div>
              <div class="title" v-text="company.feild"></div> 
             <router-link :to="'/course_index/' + company.id">לקורס</router-link>
</div>
        </div>
        <div class="clr"></div>
      </div>
  </div>
  </div>
</template>

<script>

import UserLogo from '@/components/user_logo.vue';

export default {
  
    components: { UserLogo },
    data(){
        return {
            loaded: false,
            own_profile:false,
            companies:[],
            search:"",
            letters: [
                "א", "ב", "ג", "ד","ה" , "ו" , "ז" , "ח" ,"ט" , "י","כ","ל" ,"מ" ,"נ" ,"ס" ,"ע" ,"פ","צ", "ק","ר","ש","ת"
            ],
            activeLetter: ""
        }
    },
  methods: {
      load_results()
      {
          
        var self = this;
        
        // categories 
        this.net_api({ action: 'courseIndexRefresh', data: { letters: this.activeLetter} }, function(data){ 
            
            self.companies = data.data.items;
        

        } ); 

      },
      refresh_results()
      {
          
        var self = this;
        
        // categories 
        this.net_api({ action: 'courseIndexRefresh', data: { letters: this.search} }, function(data){ 
            
            self.companies = data.data.items;
        

        } ); 

      }

  },
  mounted(){

        var self = this;
        
        // categories 
        this.net_api({ action: 'courseIndexRefresh', data: { letters: ""} }, function(data){ 
            
            self.companies = data.data.items;
        

        } ); 
 
  }
}
</script>

<style lang="scss" scoped>
#company_index_page{
  &{background: #fff;min-height: 100vh;padding-top: 20px;}
    h3{font-size: 35px;margin: 30px 0;}
    .filter{
        .md-field label{left: unset;right: 10px;}
        .md-field.md-theme-default:after{display: none;}
        .md-field input[type="text"]{background: #d8d8d8 !important;height: 40px;}
        .md-field{position: relative;}
        .fa-search{position: absolute;left: 20px;top: 25px;}
        .md-field:nth-child(2){width: 35%; display: inline-block;background: #d8d8d8 !important;}
        .md-field:nth-child(3){width: 35%;display: inline-block;background: #d8d8d8 !important;margin: 0 20px;}
    }
    .letters {
      &{margin-bottom: 20px;}
        a { text-align: center;line-height: 50px;display: inline-block;color: #666666;font-size: 23px;height:50px;width: 35px;;
                    &.active { font-weight: bold;color: #000;position: relative; }
                    &.active::after{content: "";position: absolute;width: 35px;height: 2px;background: #448aff;bottom: 0px;right: 0;;}
        }
    }
    .companies_wrapper{
      .company{
        .user_logo{    width: 185px;height: 60px;margin: 0 auto;background-size: contain;box-shadow: none;}
        &{ border: 1px solid #a5a5a5;background: #fff;padding: 30px 23px;text-align: center;margin-bottom: 30px;border-radius: 5px;}
        .name{color: #212731;font-size: 23px;font-weight: 600;margin-top: 16px;min-height: 40px;max-height: 40px;overflow: hidden;}
        .title{    line-height: 35px;min-height: 30px;max-height: 30px;overflow: hidden;}
        a{    color: #30abb7; text-decoration: underline; font-size: 18px; font-weight: 600;}
      }
    }
  .col-md-4{padding-left: 15px;padding-right: 15px;}
}
@media only screen and (max-width: 600px) {
#company_index_page{
  &{padding: 0 10px 0 10px;}
  h3{font-size: 22px;text-align: center;padding-top: 20px;}
  .letters{display: none;}
  .filter{text-align: center;}
  //.col-md-4{width: 50%;display: inline-block;}
}

}
</style>