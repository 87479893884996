<template>
  <div id="company_index_page">
  <div class="centered" id="company_index_page">
      <h1>אינדקס חברות ואנשי מקצוע בענף הנדל"ן</h1>
      <div class="filter">
          <md-field>
      <label>חפש לפי שם משתמש </label>
      <i class="fas fa-search"></i>
      <md-input @keyup="update_filter()" v-model="filter.name"></md-input>
    </md-field>
     <md-field>
          <label for="movie">תחום התמחות</label>
          <md-select v-model="filter.experties" @md-selected="update_filter()">
            <md-option v-for="list in $store.experties" :key="list.id" :value="list.id">{{list.name}}</md-option>
          </md-select>
        </md-field>
        
      </div>
      <div class="letters">
          <a v-text="letter" v-for="letter in letters" :key="letter" @click="filter.name = letter; update_filter()" :class="(filter.name == letter ? 'active' : '')"></a>
      </div>
      <div class="companies_wrapper">
        <div class="col-md-4"  v-for="company in companies" :key="company.name">
        <div class="company" >
              <router-link :to="'/company/' + company.id"><UserLogo size="mid" :user_data="company" :clickable="false"/></router-link>
              <div class="name" v-text="company.name"></div>
              <div class="title" v-text="company.feild_parsed"></div> 
             <router-link :to="'/company/' + company.id">לעמוד החברה</router-link>
</div>
        </div>
        <div class="clr"></div>
      </div>
  </div>
  </div>
</template>

<script>

import UserLogo from '@/components/user_logo.vue';

export default {
  
    components: { UserLogo },
    metaInfo() { return {
    // Children can override the title.
    title:"אינדקס חברות נדל''ן",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      // {name: 'description', content: 'I have things here on my site.'}
    ]
  }},
    data(){
        return {
        
          
          on_loading: false,
            loaded: false,
            own_profile:false,
            companies:[],
            search:"",
            letters: [
                "א", "ב", "ג", "ד","ה" , "ו" , "ז" , "ח" ,"ט" , "י","כ","ל" ,"מ" ,"נ" ,"ס" ,"ע" ,"פ","צ", "ק","ר","ש","ת"
            ],
            filter: {
              order: "logo DESC",
              page: 0,
              name: "",
              experties: (this.$route.query.experties > 0 ? this.$route.query.experties : '')
            },
            
        }
    },
  methods: {
      update_filter(){
        if(this.on_loading)return;
        this.filter.page = 0;
        this.load_results();
      },
      load_results()
      {
        var self = this;

        if (this.filter.page == 0) 
        {
          this.items = [];
        }

        self.on_loading = true;
        
        // categories 
        this.net_api({ action: 'company/results', data: this.filter }, function(data){ 
            
            //self.users = data.data.items;

            if (self.filter.page == 0) self.companies = data.data.items; 
            else self.companies.push(...data.data.items); 

            self.filter.page++;
            self.on_loading = false;
        } ); 

      }

  },
  mounted(){
    var self = this;
    this.load_results();
    
    window.onscroll = () => {
      if  (!this.on_loading && document.documentElement.scrollTop + 400 >= (document.body.scrollHeight - window.innerHeight ))
      {
        self.load_results();
      }
    };
  }
}
</script>

<style lang="scss" scoped>
#company_index_page{
  .col-md-4:nth-child(3n+1){clear: both;}
  &{background: #fff;min-height: 100vh;padding-top: 20px;}
    h1{font-size: 35px;margin: 30px 0;}
    .filter{
        .md-field label{left: unset;right: 10px;}
        .md-field.md-theme-default:after{display: none;}
        .md-field input[type="text"]{background: #d8d8d8 !important;height: 40px;}
        .md-field{position: relative;}
        .fa-search{position: absolute;left: 20px;top: 25px;}
        .md-field:nth-child(2){width: 35%; display: inline-block;background: #d8d8d8 !important;}
        .md-field:nth-child(3){width: 35%;display: inline-block;background: #d8d8d8 !important;margin: 0 20px;}
    }
    .letters {
      &{margin-bottom: 20px;}
        a { text-align: center;line-height: 50px;display: inline-block;color: #666666;font-size: 23px;height:50px;width: 35px;;
                    &.active { font-weight: bold;color: #000;position: relative; }
                    &.active::after{content: "";position: absolute;width: 35px;height: 2px;background: #448aff;bottom: 0px;right: 0;;}
        }
    }
    .companies_wrapper{
      .company{
        .user_logo{    width: 185px;height: 60px;margin: 0 auto;background-size: contain;box-shadow: none;}
        &{ border: 1px solid #a5a5a5;background: #fff;padding: 30px 23px;text-align: center;margin-bottom: 30px;border-radius: 5px;}
        .name{color: #212731;font-size: 23px;font-weight: 600;margin-top: 16px;min-height: 40px;max-height: 40px;overflow: hidden;}
        .title{    line-height: 35px;min-height: 30px;max-height: 30px;overflow: hidden;}
        a{    color: #30abb7; text-decoration: underline; font-size: 18px; font-weight: 600;}
      }
    }
  .col-md-4{padding-left: 15px;padding-right: 15px;}
}
@media only screen and (max-width: 600px) {
#company_index_page{
  &{padding: 0 10px 0 10px;}
  h3{font-size: 22px;text-align: center;padding-top: 20px;}
  .letters{display: none;}
  .filter{text-align: center;}
  //.col-md-4{width: 50%;display: inline-block;}
}

}
</style>