<template>
<div>
    <div class="title_wrapper">
       <h3>ממשקי עבודה</h3>
       <a>אנשי מקצוע נוספים<i class="fas fa-arrow-left"></i></a>
        </div>
        <div class="colleage_wrapper">
            
             <div class="col-md-4"  v-for="colleage in user_interfacses" :key="colleage.id">
                         <!--<router-link :to="'/profile/' + colleage.id">-->
                         <div class="colleage " >
                            <UserLogo size="sml" :user_data="colleage" :clickable="false" />
                           <h4 class="name" v-text="colleage.name"></h4>
                          <p class="job" v-text="colleage.feild_parsed"></p>
               
                        <div class="clr"></div>
                           <!-- <div :key="colleage.is_employee">
                                <div class="follow" @click="add_emplyee(colleage)" v-if="!colleage.is_employee"> הצטרף כעובד <i class="fas fa-eye"></i></div>
                                <div class="follow" @click="remove_emplyee(colleage)" v-if="colleage.is_employee">כבר לא עובד שם<i class="fas fa-eye-slash"></i></div>
                            </div>-->
                                     <router-link :to="'/company/' + colleage.id" class="inter_link">לעמוד החברה</router-link>

                         </div>
                        <!-- </router-link>-->
                   </div>
                   <div class="clr"></div>
        </div>
</div>
</template>

<script>

import UserLogo from '@/components/user_logo.vue';

export default {
        props: ["user_interfacses"],

    components: { UserLogo },
    data(){
        return {
            id: this.$route.params.id,
            user_data: this.$route.params.id,
            loaded: false
        }
    },
  methods: {
        add_emplyee(colleage)
        {
            colleage.is_employee = true;
            this.net_api({ action: 'follow/add_emplyee', data:{  user_id: this.user_data.id , company_id: colleage.id} }, function(){
            });
        },
        remove_emplyee(colleage)
        {
            colleage.is_employee = false;
            this.net_api({ action: 'follow/remove_employee', data:{ user_id: this.user_data.id , company_id: colleage.id} }, function(){
            });
        },
  }, 
}
</script>
<style lang="scss" scoped>
.inter_link{color: rgb(48, 171, 183) ;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 600;margin-bottom: 20px;}
.title_wrapper{
    &{    width: 100%;height: 70px;background: #212731;padding: 0 20px;}
    h3{    display: inline-block; color: #fff; font-size: 35px; font-weight: 500;line-height: 65px}
    a{    float: left; line-height: 60px; color: #f5f5f5 !important; font-size: 20px;}
    .fa-arrow-left{    font-size: 14px; margin: 0 5px;color: #30abb7;}
}
.colleage_wrapper{
    &{margin-right: -15px;margin-left: -15px;}
    .colleage{
        .user_logo{margin: 0 auto;box-shadow: none;width: 150px;background: transparent;height: 56px;background-size: contain;background-position: center;background-repeat: no-repeat;}
        .user_icon{box-shadow: none;margin: 0 auto 20px;width: 100%;}
    &{    background: #fff;margin: 20px 0;    padding: 15px 0;text-align: center;}
    .user_icon.size_mid{margin: 0 auto;;}
    .name{font-size: 23px;margin-top: 17px;color: #30abb7;font-weight: 600;min-height: 20px;max-height: 20px;overflow: hidden;}
    .job{font-size: 20px;color: #212731;margin-bottom: 10px;min-height: 20px;max-height: 20px;overflow: hidden;}
    .follow{width: 100%; height: 45px; background: #d2d7df; line-height: 45px; font-size: 20px; margin-top: 20px;cursor: pointer;}
    }
}
@media only screen and (max-width: 600px) {
    .colleage_wrapper{
        .colleage{margin: 10px 5px;}
        .name{min-height: 40px;font-size: 20px !important;margin-top: 10px !important;width: 88%;margin: 0 auto;}
        .col-md-4{width: 50%;display:inline-block;padding: 0;}
        .colleage .user_icon{height: 160px;}
        .user_logo.size_sml{width: auto;
    background-size: contain;
    height: 50px;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-position: center}
    }
}
</style>