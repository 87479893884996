<template>
  <div>
    <Chat v-if="loaded"
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="true"
      :showFile="false"
      :showEdition="true"
      :showDeletion="true"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="false"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage">
      <!-- <template v-slot:header><UserIcon size="sml" :user_data="user_data" /> {{participants.map(m=>m.name).join(' & ')}}</template> -->
</Chat>
  </div>
</template>


<script>

import Chat from './vue-beautifull-chat/Launcher.vue'; //vue-beautiful-chat/index.js';

//import UserIcon from './user_icon.vue';
 
export default {
    props: ["user_id"],
  components: { Chat },
  data() {
    return {
        user_data: false,
        loaded: false,
      messageList: [
          /*
          { type: 'text', author: `me`, data: { text: `Say yes!` } },
          { type: 'text', author: `user1`, data: { text: `No.` } }
          */
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#00aeba',
          text: '#ffffff'
        },
        launcher: {
          bg: '#1e2732'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  computed:{
       participants(){
           if (this.user_data == false) return [];
           return [{
                id: this.user_id,
                name: this.user_data.display_name,
                imageUrl: this.user_data.pic_parsed
                }];
        }, // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
        titleImageUrl(){ 
            if (this.user_data != false) return this.user_data.pic_parsed; 
            return '';
        }
  },
  methods: {
    /*
    sendMessage (text) { 
      if (text.length > 0)
      {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    },
    */
    onMessageWasSent (message) {
      // called when the user sends a message
      this.pushMessage(message)
        // categories 
        this.net_api({ action: 'chat/send', data: { uid_to: this.user_id, msg: message.data.text} }, function(){ 
            
        } );
    },
    pushMessage(message)
    {
      message.author = (this.user_data.id != message.author ? 'me': message.author);
      // console.log(this.user_data.id)
      // console.log(message)
      this.messageList = [ ...this.messageList, message ]
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
      this.$emit('close');
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      // console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    }
  },
  mounted(){
      var self = this;

      this.net_api({ action: 'user/get_details', data: {user_id: this.user_id} }, function(data){ 
          self.user_data = data.data;
          self.loaded = true;
      })

      // categories 
      this.net_api({ action: 'chat/getMessages_init', data: { msg_id: this.user_id} }, (data) => { 
        if(data.data.unreaded > 0 )
        {
          this.$emit('chat_change');
        }
        for (let i in data.data.msgs)
        {
            let msg = data.data.msgs[i];
            
            self.pushMessage({ author: msg.uid_from, type: 'text', data: { text: msg.msg } });
        }

      } );
  }
}

</script>