<template>
<div id="notifications" v-if="notifications.length > 0">

          <div id="notifications_header" v-if="text">התראות</div>

      <div class="notifications" >
      <div class="notiafy" v-for="item in notifications" :key="item.id" :class="(item.readed == 1 ? 'active' : '')"  >
                                <!-- <div class="editbtn" @click="item.extended = !item.extended " v-if="item.is_deleted !== true"> ...</div> -->

       <router-link :to="item.link" class="item_noti"  v-if="item.is_deleted !== true" >

                       <UserIcon size="sml" :user_data="item.user" :clickable="false" />

           <p  @click="check(item.id);$emit('close_notifications')"><b>{{item.user.display_name}}</b>  {{item.content}}</p>
      

             
       </router-link>
    <transition name="slide" mode="out-in">
                <div class="options" v-if="item.extended == true" :key="item.extended">
                        <a class="dropdown-item" @click="check(item.id);item.extended = false">סמן כנקרא</a>
                        <a class="dropdown-item" @click="delete_notification(item.id);item.extended = false">מחיקה</a>
                </div>
              </transition>
   </div>
   </div>
   </div>
</template>
        
        
<script>
import UserIcon from '@/components/user_icon.vue';

export default {
    props:["text"],
    components: { UserIcon },
    data(){
        return {
            loaded: false,
            content: '',
            notifications: []
        }
    },
    methods:{
        check(item)
        {
            var self = this;
            this.net_api({ action: 'notifications/check', data:{ id:item} }, function(){
             self.get_item_by_id(item).readed = true;
            });
        },
        uncheck(item)
        {
            var self = this;
            this.net_api({ action: 'notifications/check', data:{ id:item} }, function(){
             self.get_item_by_id(item).readed = false;
              
            });
        },
        delete_notification(item)
        {
            var self = this;
            this.net_api({ action: 'notifications/delete', data:{ id:item} }, function(){
                self.get_item_by_id(item).is_deleted = true;


            });
        },
           get_item_by_id(id) {

            for (let i in this.notifications)
            {
                if (this.notifications[i].id == id) return this.notifications[i];
            }

            return {};

        }
        

    },

    mounted(){
        
        var self = this;
        this.net_api({ action: 'notifications/results', data:{ to_id: this.$store.user.id } }, function(data){

            self.notifications = data.data.items;

          
    });
            
    }
}
</script>

<style lang="scss" scoped>
#notifications{background-color: #30abb7;;margin-top: 25px;position: relative;border-radius: 5px;position: relative;}
.notifications{max-height: 500px;overflow: auto;padding: 0px 15px 50px;min-height: 200px; background: #fff;}
.item_noti{position: relative;direction: ltr;}
.item_noti p{  display: inline-block;;font-size: 18px;line-height: 40px;color: #000;}
.notiafy{position: relative;margin-bottom: 20px;}
.user_icon{display: inline-block;vertical-align: middle;margin-left: 10px;}
#notifications_header{padding-right: 20px; font-weight: 500; font-size: 22px;}
.editbtn{position: absolute;
    left: 0px;
    top: -2px;
    font-size: 24px;
    z-index: 9999999;
    cursor: pointer;
    letter-spacing: 3px;}
#notifications::after{
    content: "";
    position: absolute;
    left: 15%;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    clear: both;
}
.options{    position: absolute;background: #fff;color: #000;font-size: 18px;left: 10px;border-radius: 5px;text-align: center;padding: 20px;z-index: 999999999999999999;top: 30px;}
.options a{display: block;}
.active p{color: #ccc !important;}
</style>