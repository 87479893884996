<template>
  <div></div>
</template>

<script>
export default {
  mounted(){
 
    this.net_api({ 'action': 'loginFBID', data: {id: this.$route.params.id}}, function(data){
    
        localStorage.setItem('user_token', data.data);
        document.location = '/'; // document.location.href;

    });
    

  }
}
</script>
