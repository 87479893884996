<template>
  <div id="feed_container">

     <md-dialog :md-active.sync="edit_post" :key="edit_post_item.id" class="post_edit">
                        <md-dialog-title> ערוך את הפוסט <div class="close" @click="edit_post = false">X</div></md-dialog-title>
                            <div class="inputs">
                            <UserIcon size="mid" :user_data="$store.user"/>
                            <h4>{{$store.user.display_name}}</h4>
                            <div class="col-md-12" >
                                <md-field>
                                <md-textarea v-model="edit_post_item.content"></md-textarea >
                            </md-field>
                            </div>
                        </div>
                        <md-dialog-actions>
                            <md-button class="md-primary" @click="edit_post = false;onConfirm_post_edit()">שמירה</md-button>
                        </md-dialog-actions>
                        </md-dialog>

                      

      <!--
      <div class="filter">
                      <h2>מיין לפי:</h2>

        <md-field>
                <label for="movie">מיין לפי</label>
                <md-select v-model="movie" name="movie" id="movie">
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                </md-select>
                </md-field>
                <md-field>
                <label for="movie">מיין לפי</label>
                <md-select v-model="movie" name="movie" id="movie">
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                    <md-option value="fight-club">הכי חדש </md-option>
                </md-select>
                </md-field>
        </div>
        -->  <div class="title_wrapper" v-if="posts.length > 0">
                 <h3> פעילות אחרונה בקהילה</h3>
         </div>
      <div class="posts_list">
        <transition-group name="list" tag="p">
        <div class="post" v-for="item in posts" :key="item.id">
            <template v-if="item.is_deleted !== true">

            <div class="content_area">
                <div class="first_row">
                    <span class="user_name" v-text="item.user_data.display_name"></span>
                 
                    <span class="specialty" v-if="item.user_data.specialty != '' && item.user_data.nadlan_specieal == 1" ><i class="far fa-star"></i> {{item.user_data.specialty}} </span>
                </div>
                 <div class="sec_row">
                <span class="user_title" v-if="!isNull(item.user_data.company_name) && !isNull(item.user_data.job)">{{item.user_data.job}} ב-{{item.user_data.company_name}}</span>
                <span class="user_title" v-if=" item.user_data.job && !item.user_data.company_name">{{item.user_data.job}} </span>
                <span class="post_date" v-text="'פורסם לפני '+timeago(item.date_created) "></span>
                 </div>
                <span class="line"></span>

                <UserIcon size="mid" :user_data="item.user_data" />
                
                <div class="editbtn">
                <DropMenu v-if="item.user_data.id == get_me().id">
                    <template  v-slot:btn>...</template>
                    <template v-slot:menu>

                        <a class="dropdown-item"  @click="edit_post = true; edit_post_item = item">עריכה</a>
                        <a class="dropdown-item" @click="onConfirm_post_delete(item.id)">מחיקה</a>
                    </template>
                </DropMenu>
                </div>
                
                <div class="hashtags">
                    <router-link v-for="tag in item.hashtags" :key="tag" :to="'/hashtag/' + tag" v-text="'#' + tag"></router-link>
                </div>

                <div class="content" :key="item.is_read_more">
                
                  <div :class="(item.content_parse.length >= 400 && item.is_read_more == false ? 'less_text' : ' ')">
                  <span class="content" v-html="item.content_parse"></span>
                </div>  
                <a v-if="!item.is_read_more && item.content_parse && item.content_parse.length >= 400 " @click="item.is_read_more=true" class="read_more">קרא עוד </a>

                    <div class="post_attach_line">
                        <ul>
                            <template v-for="file in item.attachments" >
                            <li :key="file.id" v-if="!file_is_img(file)"><a target="_blank" :href="file.url"><i :class="get_file_class(file.ext)"></i><span class="name" v-text="file.name"></span></a></li>
                            </template>
                        </ul>
                    </div>
                    <div class="gallery">
                        <template v-for="file in item.attachments">
                            <!-- <div v-if="file_is_img(file)" :key="file.id" class="pic" :style="'background-image:url('+ file.url +')'"></div> -->
                            <img v-if="file_is_img(file)" :key="file.id" class="pic" :src="file.url" />
                        </template>
                    </div>
                </div>
            </div>
            <div class="url_area" v-if="item.url != ''">

                <div v-if="item.video_data.video == 'youtube'">
                    <iframe width="100%" height="460" :src="'https://www.youtube.com/embed/' + item.video_data.videoID" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div v-if="item.video_data.video == 'vimeo'">
                    <iframe title="vimeo-player" :src="'https://player.vimeo.com/video/' + item.video_data.videoID" data-clip-info="76979871" frameborder="0" width="100%" height="460" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
                <div v-if="item.video_data.video == 'bamboo'">
                    <iframe :src="'//cdn.bamboo-video.com/api/embed?id=' + item.video_data.videoID + '&uiconf_id=11629215&type=entry&iid=5acb09316986fb539d5007a4'" width="100%" height="360" allowfullscreen webkitallowfullscreen frameborder="0" allow="autoplay,fullscreen"></iframe>
                </div>

                <div v-if="isNull(item.video_data.video)">
                    <a target="_blank" :href="item.url">
                    <div class="pic" :style="'background-image:url('+ item.url_image +')'"></div>
                    <div class="url_content">
                        <h3 v-text="item.url_title"></h3>
                        <h4 v-text="item.url_description"></h4>
                    </div>
                    </a>
                </div>

            </div>
            <div class="social_line">
                <a :key="item.is_like" class="soci_btn" v-if="item.is_like" @click="unlike(item)" ><i  style="color:#de0000" class="fas fa-heart"></i><span v-text="(item.num_likes > 0 ?' '+ item.num_likes : 'לייק')"></span> </a>
                <a :key="item.is_like" class="soci_btn" v-if="!item.is_like" @click="like(item)"><i class="far fa-heart" > </i><span v-text="(item.num_likes > 0 ? '' + item.num_likes : 'לייק')"></span> </a>

                <a class="soci_btn" @click="item.show_comments = !item.show_comments"><i class="far fa-comment-dots"></i> <span v-text="(item.num_comments > 0 ? item.num_comments : 'תגובה')"></span></a>
                   <Share>
                    <template  v-slot:btn> <a class="soci_btn"><i class="fas fa-share-alt"></i> שיתוף</a></template>
                    <template v-slot:share>
                        <a class="dropdown-item" :href="'whatsapp://send?text=https://community.nadlancenter.co.il/post/'+item.id" data-action="share/whatsapp/share"><i class="fab fa-whatsapp"></i></a>
                        <a class="dropdown-item" :href="'https://www.facebook.com/sharer/sharer.php?u=https://community.nadlancenter.co.il/post/'+item.id +''" target="_blank"><i class="fab fa-facebook"></i></a>
                    </template>
                </Share>
            </div>
                
            <Comments v-if="item.num_comments == 0 || item.show_comments === true" :post_id="item.id" :initial_comments="item.comments" :ref="'comments_' + item.id" />
            </template>
        </div>
        </transition-group>
      </div>

  </div>
</template>

<script>

import Comments from './comments.vue';

import UserIcon from './user_icon.vue';
import DropMenu from './dropMenu.vue';
import Share from './share.vue';

export default {
    props: ["feed_key", "hashtag", "user_id", "color", "id" , "check"],
    components: { Comments, UserIcon,DropMenu,Share },
    
  
    data(){
        return {
            posts: [],
            on_loading :false,
            alert_delete :false,
            active_edit:false,
            active_delete:false,
            deleted_post:{},
            get_without_agree:false,
            edit_post:false,
            page:0,
            edit_post_item: {},
        }
    },
    methods:{
        
        
        parse_main_channel(msg)
        {
            var data = JSON.parse(msg.message);
            // console.log(data);

            switch(data.mtype)
            {
                // REALTIME FEED
                case 'new_post':
                {
                    console.log(data)
                    
                    this.posts.unshift(data.data);

                    break;
                }
                 case 'add_like_post':
                {
                    let post = {};
                   post = this.get_item_by_id(data.data.id);
                   post.num_likes++
                    break;
                }
                 case 'remove_like_post':
                {
                    let post = {};
                   post = this.get_item_by_id(data.data.id);
                   post.num_likes--
                    break;
                }
                case 'new_comment': {
                    // alert(data.data.post_id)
                    //  console.log(this.$refs['comments_' + data.data.post_id][0].comments)
                   
                    this.$refs['comments_' + data.data.post_id][0].comments.push(data.data);
                    break;
                }
                case 'remove_comment': {
                    //  console.log(this.$refs['comments_' + data.data.post_id][0].comments)
                   
                    for (let i in this.$refs['comments_' + data.data.post_id][0].comments)
                    {
                        if (this.$refs['comments_' + data.data.post_id][0].comments[i].id == data.data.id)
                        {
                            // alert(this.$refs['comments_' + data.data.post_id][0].comments[i].id)
                             this.$refs['comments_' + data.data.post_id][0].comments.splice(i, 1);
                            break;
                        }
                    }
                    break;
                }
            }
        },
        
        add_post(id)
        {
            var self = this;
            this.net_api({ action: 'feed/results', data:{ feed_key: this.feed_key, id: id, hashtag: this.hashtag, user_id: this.user_id } }, function(data){

                for (let i in data.data.items)
                {
                    data.data.items[i].show_comments = false;
                }

                self.posts.unshift(data.data.items[0]);
            });
        },
   
          onConfirm_post_edit () {
                      var self = this;

               this.net_api({ action: 'edit_post', data: { item_id:this.edit_post_item.id, content: this.edit_post_item.content ,} }, function(){ 

                } );
                   this.$confirm("האם אתה בטוח שאתה רוצה לשנות את הפוסט?", "" , "", { confirmButtonText: 'אישור',cancelButtonText: 'ביטול'}).then(() => {
                    this.net_api({ action: 'edit_post', data: { item_id:this.edit_post_item.id, content: this.edit_post_item.content ,} }, function(){ 
                   self.alert("הפוסט שונה בהצלחה");
                    } );
                    }); 

      },
      onCancel_post_edit () {
        this.active_edit = false
      },
      
      onConfirm_post_delete (delete_id) {
        var self = this;
          this.$confirm("האם אתה בטוח שאתה רוצה למחוק את הפוסט?", "" , "", { confirmButtonText: 'אישור',cancelButtonText: 'ביטול'}).then(() => {
        this.net_api({ action: 'delete_post', data: { item_id:delete_id } }, function(){ 
            //self.posts.splice
            self.get_item_by_id(delete_id).is_deleted = true;
            self.alert("הפוסט נמחק בהצלחה");
        } );
        });
      },
        file_is_img(file)
        {
            return (file.ext == 'jpg' || file.ext == 'jpeg' || file.ext == 'gif' || file.ext == 'png')
        },
        like(item)
        { 
            // item.num_likes = ++item.num_likes;
             item.is_like = true;
            this.net_api({ action: 'likes/add', data:{ item_type: 'post', item_id: item.id } }, function(){
                // item.is_like = true;
            });
        },
        unlike(item)
        {
                        // item.num_likes = --item.num_likes;
 item.is_like = false;
            this.net_api({ action: 'likes/remove', data:{ item_type: 'post', item_id: item.id } }, function(){

                // item.is_like = false;
            });
        },
        get_item_by_id(id) {

            for (let i in this.posts)
            {
                if (this.posts[i].id == id) return this.posts[i];
            }
            return {};
        },
        load_results(){
            var self = this;
                    self.on_loading = true;

this.net_api({ action: 'feed/results', data:{ feed_key: this.feed_key, hashtag: this.hashtag, user_id: this.user_id, id: this.id ,check:this.get_without_agree,page:this.page} }, function(data){

               for (let i in data.data.items)
            { 
                data.data.items[i].is_read_more = false;
                data.data.items[i].show_comments = true;
                data.data.items[i].is_deleted = false;
            }
            if (self.page == 0) self.posts = data.data.items; 
            else self.posts.push(...data.data.items); 

            self.page++;
         
        self.on_loading = false;


            if (self.id > 0 ) self.$emit('postData', data.data.items[0]);
        }); 
        }
        
    },
  
    mounted(){


        this.$pnSubscribe({ channels: ['main'] });
        this.$pnGetMessage('main', this.parse_main_channel);


        if(this.check == true)
        {
            this.get_without_agree = true
        }
    this.load_results();
    
    window.onscroll = () => {
      if  (!this.on_loading && document.documentElement.scrollTop + 400 >= (document.body.scrollHeight - window.innerHeight ))
      {
        this.load_results();
      }
    };

        
    }
}
</script>

<style lang="scss" scoped>

#feed_container {
    .less_text{display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
    .read_more{text-align: right;margin: 10px ;font-weight: bold;display: block;text-decoration: underline;}
.filter{
    h2{    display: inline-block;vertical-align: middle;margin-left: 20px;}
    &{margin-bottom: 20px;}
    .md-field{display: inline-block;width: 30%;margin: 0 20px;text-align: right;direction: rtl;border-bottom: none;}
    .md-field label{text-align: right;left: unset;}
    .md-field:after{display: none;}
}
    .post { 
        & { background: #fff; box-shadow: 0px 10px 20px rgba(0,0,0,0.2); border-radius: 5px; margin-bottom: 30px; transition: all 1s ease; opacity: 1; }

        .editbtn { position: absolute; left: 10px; top: 0px; z-index: 3;}
        
        .content_area { padding: 25px 85px; position: relative;
        .first_row{display: block;}
        .sec_row{display: block;}
            .user_icon { display: block; position: absolute; right:-30px; top: 10px;  }
            .user_name { display: inline-block; font-size: 29px; font-weight: bold; margin-bottom: 10px; color:#212731; }
            .specialty{margin-right: 15px;color: #00aeba;font-size: 20px;}
            .user_title { display: inline-block; font-size: 18px; font-weight: normal; margin-bottom: 10px; color: #7f7f7f; margin-left: 10px ;
                &::after { content: "·"; margin-right: 10px; } 
            }
            .post_date {  color: #7f7f7f; font-size: 18px; font-weight: normal; display: inline-block;}

            .line { display: block; height: 2px; background: #00aeba; margin: 15px 0; width: 45px; clear: both; }
            .content { white-space: pre-wrap; font-size: 20px; color: #000; line-height: 1.4; overflow-x: hidden;}

            .gallery { 
                .pic { /*height: 300px; background: center top no-repeat; background-size: contain;*/ max-width:90%; height: auto;  }
            }   
        }
        .url_area { 
            & { border-radius: 5px; border: 1px solid #666; overflow: hidden; background: #eee; }
            iframe { width: 100%; height: 460px;}
            .pic { width: 100%; height: 300px; background: center center no-repeat; background-size: cover;}
            .url_content { padding: 15px;}
            h3 { font-size: 20px; font-weight: bold; }
            h4 { font-size: 15px; font-weight: normal; }
        }
        .social_line {
                & { padding: 10px 15px;}
            .soci_btn { 
                span{margin-right: 2px;}
                & {  font-size: 18px; columns: #000; display: inline-block; height: 40px; line-height: 40px; padding: 0 15px; border-radius: 40px;}
                &:hover { background: #e9eaec; }
            }
        }
        .comments {
            .add_comment { background: #f5f5f5;}
        }

        .hashtags { 
            & { position: absolute; left: 0px; top: 0px; padding: 25px; }
            a { display: inline-block; line-height: 40px; border-radius: 40px; color: #fff; background: #8495a9; padding: 0 15px; margin-right: 8px;}
        }
    }


.list-enter-from, .list-leave-to {  transform: translateY(30px); }
.list-enter-from { opacity: 0; }

}
@media only screen and (max-width: 600px) {
#feed_container{
    .post .content_area .post_date{margin: 5px 0;}
    .post .hashtags{    position: initial;padding: 0;margin: 10px 0;line-height: 34px;}
    .post{margin-bottom: 25px;}
    .post .hashtags a{line-height: 27px;padding: 0 10px;font-size: 13px;}
    &{padding: 0 0px}
    .post .content_area{padding: 35px 20px;}
     .soci_btn{font-size: 16px !important;padding: 0 10px !important;}
    .post .content_area .user_icon{right: 0px;top: -20px;}
    .post .content_area .user_icon.size_mid{height: 50px;width: 50px;}
    .post .content_area{
        .user_name{font-size: 20px;margin-bottom: 0;}
        .line{margin: 0 0 10px;}
    }
}
}
</style>